import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { setCompletedSteps } from "../../redux/actions/CompletedStepActions";
import { resetDisabledSteps, setDisabledSteps } from "../../redux/actions/DisabledStepActions";
import { PrimaryOrangeButton } from "../../components/Common/InputButton/InputButton";
import { trackPromise } from 'react-promise-tracker'
import { Col, Row, TabContainer, Nav, NavItem, TabPane, TabContent, NavLink } from "react-bootstrap";
import { BOMService } from '../../services/BOMService';
import { PCBService } from '../../services/PCBService';
import { InputSelect, InputTextBox, InputTextArea, GroupButton }
    from "../../components/ControlComponents/Input/index";
import { CommonService } from '../../services/CommonService';
import { useForm } from "react-hook-form";
import { MESSAGE_CONSTANT } from "../../constants/MessageConstant";
import { uniq, sortBy, pluck, map } from 'underscore';
import { OIDCService } from "../../services/OIDCService";
import "./Pcb.css";

const Pcb = ({ onStepCompleted, selectedJobType, salesPersonId, assemblyId, onStepDisabled, disabledSteps, resetDisabledSteps }) => {
    const { register, errors, trigger } = useForm();
    const history = useHistory();

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [pcbQuestionData, setPcbQuestionData] = useState([])
    const [errorsTab, setErrorsTab] = useState([]);
    const [pcbSelectionInfo, setSelectedPCBLine] = useState({ isNextButtonClick: false, isFocusToErrorRow: false, errorRowIndex: 0 });

    useEffect(() => {
        if (!assemblyId) {
            history.push("/");
            return;
        }
        trackPromise(getPCBDetails());
    }, []);

    useEffect(() => {
        checkValidationErrors(true);
    }, [errors]);

    useEffect(() => {
        if (pcbSelectionInfo.isFocusToErrorRow == true && pcbSelectionInfo.isNextButtonClick == true) {
            let errorIndex = getErrorLineItemIndex();
            errorIndex = errorIndex > -1 ? errorIndex : pcbSelectionInfo.errorRowIndex;
            changePCbSelectionInfo(errorIndex, false, false);
        }
    }, [pcbSelectionInfo.isFocusToErrorRow]);

    const checkValidationErrors = (isNextButtonClick) => {
        let keyNames = Object.keys(errors);
        let keyList = keyNames.map((item) => { return item.split("_")[1] });
        var uniqkeyNames = uniq(keyList);
        if (isNextButtonClick && uniqkeyNames.length > 0) {
            changePCbSelectionInfo(pcbSelectionInfo.errorRowIndex, true, true);
        }
        setErrorsTab(uniqkeyNames);
    }

    const handleNextClicked = () => {
        onStepCompleted();
        let nextStepIndex = DATA_CONSTANT.rfqSteps.findIndex(x => x.stepName === DATA_CONSTANT.stepNames.PCB) + 1;
        history.push(DATA_CONSTANT.rfqSteps[nextStepIndex].routeName);
    }

    const handleChange = (event, lineItemIndex, questionIndex) => {
        let nameProperty = event.target.name.split("_")[0];
        let value = event.target.value;
        trigger(event.target.name).then((data) => {
            let pcbQuestion = CommonService.deepShallowCopy(pcbQuestionData);
            let pcbLine = pcbQuestion[lineItemIndex];
            let pcbAnsIndex = pcbLine.PcbQuestions[questionIndex].pcbAnswerFields.findIndex(q => q.fieldName == nameProperty);
            pcbLine.PcbQuestions[questionIndex].pcbAnswerFields[pcbAnsIndex].value = value;
            pcbQuestion[lineItemIndex] = pcbLine;
            setPcbQuestionData(pcbQuestion);
            checkValidationErrors(false);
        });
    }

    const getErrorLineItemIndex = () => {
        return errorsTab.length > 0 ? pcbQuestionData.findIndex((e) => e.lineItemId == errorsTab[0]) : -1
    }

    const startValidation = () => {
        trigger().then((data) => {
            if (data == false) {
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                changePCbSelectionInfo(pcbSelectionInfo.errorRowIndex, true);
                return;
            }

            trackPromise(submitPCBWithPricing());
        });
    };

    const submitPCBWithPricing = async () => {
        let pcbSubmitRequest = { AssemblyId: assemblyId, PCBAnswers: [] };
        map(pcbQuestionData, (item) => {
            let ansObj = {};
            ansObj.LineItemId = item.lineItemId;
            map(item.PcbQuestions, (answer) => {
                let excludeQuestions = answer.pcbAnswerFields.filter(x => x.isExclude == false);
                let fields = pluck(excludeQuestions, 'fieldName');
                map(fields, (field) => {
                    let value = answer.pcbAnswerFields.find(x => x.fieldName === field).value;
                    let defaultValue = answer.pcbAnswerFields.find(x => x.fieldName === field).defaultValue;
                    ansObj[field] = value || defaultValue;
                });

                ansObj['DimensionUOM'] = 'in';
                ansObj['ThicknessUOM'] = 'in';
            })
            pcbSubmitRequest.PCBAnswers.push(ansObj);
        })

        if (pcbSubmitRequest.PCBAnswers.length > 0) {
            await PCBService.submitPCBAnswers(pcbSubmitRequest);
        }
       
        let pcbUpdatePricingRequest = {
            assemblyId: assemblyId,
            lineItemIds: pluck(pcbQuestionData, 'lineItemId'),
            userId: getUserId()
        }

        await PCBService.submitPCBWithPricing(pcbUpdatePricingRequest);
        handleNextClicked();
    }


    const getUserId = () => {
        let user = OIDCService.getUser();
        return user && user.profile ? user.profile.sub : salesPersonId;
    };

    const changePCbSelectionInfo = (index, isNextClicked, isFocusToErrorRow) => {
        setSelectedPCBLine({
            errorRowIndex: index,
            isNextButtonClick: isNextClicked,
            isFocusToErrorRow: isFocusToErrorRow
        });
    }

    const getPCBDetails = async () => {
        let pcbLineItems = await BOMService.getPCBLinesByAssemblyId(assemblyId);
        let pcbQuestions = await PCBService.getPCBQuestions();

        pcbLineItems.forEach((item) => {
            item.PcbQuestions = sortBy(pcbQuestions, 'order')
        })
        setPcbQuestionData(pcbLineItems);
        setIsDataLoaded(true);
    };

    const renderQuestions = (lineitem, lineItemIndex) => {
        return (
            <div className="py-3">{
                lineitem.PcbQuestions.map((item, questionIndex) => {
                    return (
                        <Row key={questionIndex} className='mb-2'>{
                            item.pcbAnswerFields.map((field, index) => {
                                let patternObj = field.pattern ? JSON.parse(field.pattern) : null;
                                switch (field.control) {
                                    case 'TextBox':
                                        return (
                                            <Col key={`answer_${field.id}_${index}`} sm={12} md={4} className="cm-input-container">
                                                <InputTextBox
                                                    key={field.id}
                                                    id={field.id}
                                                    label={field.displayName}
                                                    name={`${field.fieldName}_${lineitem.lineItemId}`}
                                                    value={field.value}
                                                    type={field.dataType}
                                                    isRequired={item.isRequired}
                                                    helpText={field.description}
                                                    min={field.dataType === 'number' ? field.minValue : ''}
                                                    max={field.dataType === 'number' ? field.maxValue : ''}
                                                    postLabel={field.unit}
                                                    ref={register({
                                                        required: item.isRequired === true ? `${field.displayName} ${MESSAGE_CONSTANT.required}` : item.isRequired,
                                                        max: field.maxValue ? {
                                                            value: field.maxValue,
                                                            message: `${field.displayName} should be maximum ${field.maxValue}`,
                                                        } : null,
                                                        min: field.minValue ? {
                                                            value: field.minValue,
                                                            message: `${field.displayName} should be minimum ${field.minValue}`,
                                                        } : null,
                                                        pattern: patternObj ? {
                                                            value: patternObj.Regex ? new RegExp(patternObj.Regex) : null,
                                                            message: patternObj.Message ? patternObj.Message.formatGeneric(field.displayName) : null,
                                                        } : null,
                                                    })}
                                                    errorMessage={errors}
                                                    onChange={(event) => handleChange(event, lineItemIndex, questionIndex)}
                                                />
                                            </Col>)
                                        break;

                                    case 'DropDown':
                                        return (
                                            <Col key={`answer_${field.id}_${index}`} md={4} >
                                                <InputSelect
                                                    key={field.id}
                                                    id={field.id}
                                                    label={field.displayName}
                                                    name={`${field.fieldName}_${lineitem.lineItemId}`}
                                                    isRequired={field.required}
                                                    helpText={field.description}
                                                    list={field.possibleValues?.split("|")}
                                                    initialValue="select"
                                                    onChange={(event) => handleChange(event, lineItemIndex, questionIndex)}
                                                />
                                            </Col>
                                        )
                                        break;

                                    case 'TextArea':
                                        return (
                                            <Col key={`answer_${field.id}_${index}`} sm={12} md={4} className="cm-input-container">
                                                <InputTextArea
                                                    key={field.id}
                                                    id={field.id}
                                                    label={field.displayName}
                                                    name={`${field.fieldName}_${lineitem.lineItemId}`}
                                                    value={field.value}
                                                    type={field.type}
                                                    isRequired={item.isRequired}
                                                    helpText={field.description}
                                                    onChange={(event) => handleChange(event, lineItemIndex, questionIndex)}
                                                    ref={register({
                                                        required: item.isRequired === true ? `${field.displayName} ${MESSAGE_CONSTANT.required}` : false,
                                                    })}
                                                />
                                            </Col>
                                        )
                                        break;

                                    case 'Radio':
                                        return (
                                            <Col key={`answer_${field.id}_${index}`} sm={12} md={item.pcbAnswerFields.length == 1 ? 12 : 4} className="cm-input-container">
                                                <GroupButton
                                                    key={`${field.id}`}
                                                    id={field.id}
                                                    label={field.displayName}
                                                    type='radio'
                                                    name={`${field.fieldName}_${lineitem.lineItemId}`}
                                                    list={JSON.parse(field.possibleValues)}
                                                    listLabel={"displayValue"}
                                                    listValue={"value"}
                                                    value={field.value}
                                                    isRequired={item.isRequired}
                                                    helpText={field.description}
                                                    appendText={field.unit}
                                                    onChange={(selectedvalue, event) => handleChange(event, lineItemIndex, questionIndex)}
                                                    ref={register({
                                                        required: item.isRequired === true ? `${field.displayName} ${MESSAGE_CONSTANT.required}` : false,
                                                    })}
                                                    errorMessage={errors}
                                                ></GroupButton>
                                            </Col>
                                        )
                                        break;
                                }

                            })
                        }
                        </Row>)
                })
            }</div>
        )
    }

    const tabTitleRender = (data, dataKey) => {
        return (
            <NavLink key={dataKey} eventKey={dataKey} onSelect={() => changePCbSelectionInfo(dataKey)}>
                {
                    errorsTab.length > 0
                        && errorsTab.indexOf(data.lineItemId.toString()) > -1 ?
                        <><span>{data.manufacturerPN.replace(' REV ', ' | REV ')}</span> <span className="error-txt">*</span></> :
                        <span>{data.manufacturerPN.replace(' REV ', ' | REV ')}</span>}
            </NavLink>
        )
    }

    const CustomQuoteClicked = () => {
        resetDisabledSteps();
        onStepDisabled();
        history.push(DATA_CONSTANT.routes.customQuote);
    };

    return (
        isDataLoaded &&
        <TabContainer id="pcb-tab" defaultActiveKey="0" activeKey={pcbSelectionInfo.errorRowIndex}>
            {pcbQuestionData.length > 0 &&
                <Row className="cm-v-tab mb-3">
                    <Col lg={8} className="cm-vt-content border border-right-0">
                        <TabContent>
                            {
                                pcbQuestionData.map((lineitem, index) => {
                                    return (
                                        <TabPane key={index.toString()} eventKey={index.toString()}>
                                            {
                                                renderQuestions(lineitem, index)
                                            }
                                        </TabPane>)
                                })
                            }
                        </TabContent>
                    </Col>
                    <Col lg={4} className="cm-vt-list border-left pl-0">
                        <Nav variant="pills" className="flex-column">
                            {
                                pcbQuestionData.map((lineitem, index) => {
                                    return (
                                        <NavItem key={index.toString()} >
                                            {
                                                tabTitleRender(lineitem, index.toString())
                                            }
                                        </NavItem>)
                                })
                            }
                        </Nav>
                    </Col>
                </Row>
            }
            <Row className="mt-5">
                <Col md={{ span: 8 }}>
                    <p className="itar-custom-quote">
                        Not seeing what you're looking for? Click
                        <NavLink className="linktxt" onClick={CustomQuoteClicked}>
                            here
                        </NavLink>
                        for human assistance
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 6, offset: 3 }} className="my-5">
                    <PrimaryOrangeButton name="Next" handleClick={() => startValidation()} ></PrimaryOrangeButton>
                </Col>
            </Row>
        </TabContainer>
    )
};

const mapStateToProps = state => ({
    assemblyId: state.RFQDetails?.rfqDetails?.assemblyId,
    salesPersonId: state.DefaultConfigs?.defaultConfigs?.salesPersonId,
    selectedJobType: state.JobTypes?.selectedJobType
});

const mapDispatchToProps = dispatch => ({
    onStepCompleted: () => {
        dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.PCB));
    },
    onStepDisabled: () => {
        dispatch(setDisabledSteps(DATA_CONSTANT.stepNames.ReviewBOM));
        dispatch(setDisabledSteps(DATA_CONSTANT.stepNames.AssemblyDetail));
        dispatch(setDisabledSteps(DATA_CONSTANT.stepNames.GetQuote));
        dispatch(setDisabledSteps(DATA_CONSTANT.stepNames.Checkout));
        dispatch(setDisabledSteps(DATA_CONSTANT.stepNames.Completed));
    },
    resetDisabledSteps: () => dispatch(resetDisabledSteps()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pcb);
