import React from 'react';
import './RequestedQuantityTitle.css';

const RequestedQuantityTitle = ({ requestedQuantityData, issuedRequestedQuantity }) => {
    return (
        <>
            <span className="tabitem requestedQuantityTitle">
                {requestedQuantityData}
            </span>
            {

                issuedRequestedQuantity !== 0 && <span className="badge badge-light issueTitle" title="Total issue(s) for quantity"><span>{issuedRequestedQuantity}</span>
                </span>
            }
        </>
    )
}
export default RequestedQuantityTitle;