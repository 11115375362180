import { createStore, combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import { reducer as oidcReducer } from "redux-oidc";
import { JobTypes } from './reducers/JobTypes';
import { DefaultConfigs } from './reducers/DefaultConfigs';
import { RFQDetails } from './reducers/RFQDetails';
import { CompletedSteps } from './reducers/CompletedSteps';
import { BomFile } from './reducers/BomFile';
import { BomIds } from './reducers/BomIds';
import { PricingRunningStatus } from './reducers/PricingRunningStatus';
import { CustomQuote } from './reducers/CustomQuote';
import { DisabledSteps } from "./reducers/DisabledSteps";


const reducers = {
  JobTypes: JobTypes,
  DefaultConfigs: DefaultConfigs,
  RFQDetails: RFQDetails,
  CompletedSteps: CompletedSteps,
  BomFile: BomFile,
  oidc: oidcReducer,
  BomIds: BomIds,
  PricingRunningStatus: PricingRunningStatus,
  CustomQuote: CustomQuote,
  DisabledSteps: DisabledSteps,
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === "redux-oidc/SESSION_TERMINATED" || action.type === "redux-oidc/USER_SIGNED_OUT") {
    state = undefined;
  }
    if (action.type == 'redux-oidc/USER_FOUND' && state.oidc.user != undefined &&
        action.payload.profile.EmailConfirmed.toLowerCase() == 'true' && state.oidc.user.profile.EmailConfirmed.toLowerCase() == 'false') {
    state.oidc.user = { ...action.payload };
  }
  return appReducer(state, action)
}

const persistConfig = {
  key: 'root',
  storage: storageSession
};


const persistedReducer = persistReducer(persistConfig, rootReducer)

export const configureStore = () => createStore(persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());