import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Collapse, Button } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./OrderSummaryView.css";
import { OrderService } from "../../services/OrderService";
import { LoadingSpiner } from "../../components/Common/LoadingSpiner";
import { LoadingWrapper } from "../../components/Common/LoadingWrapper";
import { Areas } from "../../constants/Area";
import { trackPromise } from "react-promise-tracker";
import PcbDetails from "../OrderSummaryView/PcbDetails";

const OrderSummaryView = ({ orderSummary, orderdetails, isPromoCodeSectionVisible }) => {
    const loaderElement = useRef(null);

    const [open, setOpen] = useState(false);
    const [isVisibleShippingLabel, setIsVisibleShippingLabel] = useState(false);
    const [lineItems, setLineItems] = useState({});
    const [pcbDetails, setPcbDetails] = useState({});

    const getBomSummary = (requestData) => {
        return OrderService.GetBomSummary(requestData);
    };

    const getPCBDetails = (assemblyId) => {
        return OrderService.GetPcbDetails(assemblyId);
    }

    const [isDataLoaded, setDataLoaed] = useState(false);

    useEffect(() => {
        orderdetails.paymentDetails.shippingCharge === 0 ? setIsVisibleShippingLabel(false) : setIsVisibleShippingLabel(true);
    }, [orderdetails.paymentDetails.shippingCharge])

    useEffect(() => {
        trackPromise(
            (async () => {
                let bomSummaryRequest = {
                    AssemblyId: orderdetails.assemblyId,
                    LineItemId: [],
                    QuantityId: [orderdetails.quantityId],
                    IncludeUnPricedLines: false,
                };

                let bomSummary = await getBomSummary(bomSummaryRequest);
                if (bomSummary) {
                    setLineItems({ ...bomSummary });
                }
                let pcbInfo = await getPCBDetails(orderdetails.assemblyId);

                if (pcbInfo && pcbInfo?.length > 0) {
                    setPcbDetails(pcbInfo);
                }
                setDataLoaed(true);
            })(), Areas.orderSummary)
    }, []);

    const calculateTotal = () => {
        return (
            orderSummary.totalPrice +
            orderdetails.paymentDetails.tax +
            orderdetails.paymentDetails.shippingCharge -
            orderdetails.paymentDetails.discount
        ).toFixed(2);
    };

    return (
        <Col sm={12} md={12} lg={6} xl={6} className="mt-4 mt-md-0">
            <LoadingWrapper loaderRef={loaderElement}>
                <LoadingSpiner
                    area={Areas.orderSummary}
                    parentContainer={loaderElement}
                />
                <div className="order-summary text-grey">
                    {isDataLoaded && <> <h4> Order Summary </h4>
                        <Row className="mt-3">
                            <Col md={8} sm={12} xs={12}>
                                Quantity
            </Col>
                            <Col md={4} sm={12} xs={12} className="text-md-right">
                                {orderSummary.quantity}
                            </Col>
                            <Col md={8} sm={12} xs={12}>
                                Lead Time
            </Col>
                            <Col md={4} sm={12} xs={12} className="text-md-right">
                                {orderSummary.turntime}
                            </Col>
                        </Row>
                        <hr />
                        <Row className="mt-2">
                            <Col md={8} sm={12} xs={12}>
                                Regular Price SubTotal
            </Col>
                            <Col md={4} sm={12} xs={12} className="text-md-right">
                                $ {orderSummary.totalPrice.toFixed(2)}
                            </Col>
                            {isPromoCodeSectionVisible &&
                                <>
                                    <Col md={8} sm={12} xs={12}>
                                        Promo Code
                                    </Col>
                                    <Col md={4} sm={12} xs={12} className="text-md-right text-orange">
                                        - ${orderdetails.paymentDetails.discount}
                                    </Col>
                                </>
                            }
                        </Row>
                        <hr />
                        {isVisibleShippingLabel &&
                            <>
                                <Row className="mt-3">
                                    <Col md={8} sm={12} xs={12}>
                                        Shipping
                                </Col>
                                    <Col md={4} sm={12} xs={12} className="text-md-right">
                                        + ${orderdetails.paymentDetails.shippingCharge}
                                    </Col>
                                </Row>
                                <hr />
                            </>
                        }
                        <Row className="mt-3">
                            <Col md={8} sm={12} xs={12}>
                                Tax
                            </Col>
                            <Col md={4} sm={12} xs={12} className="text-md-right">
                                + ${orderdetails.paymentDetails.tax}
                            </Col>
                        </Row>
                        <hr />
                        <Row className="mt-3">
                            <Col md={6} sm={12} xs={12} className="text-white">
                                Total
            </Col>
                            <Col md={6} sm={12} xs={12} className="text-md-right text-white">
                                $ {calculateTotal()}
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <Collapse in={open}>
                                    <div id="lineItem-collapse-text">
                                        <PcbDetails pcbInfo={pcbDetails}></PcbDetails>
                                        <Row >
                                            <Col md={12} sm={12} xs={12} className="text-white">
                                                <h5>Line Items</h5>
                                            </Col>
                                            <Col md={8} sm={12} xs={12}>
                                                No. of SMT items
                                    </Col>
                                            <Col md={4} sm={12} xs={12} className="text-md-right">
                                                {lineItems.smtPartClass}
                                            </Col>
                                            <Col md={8} sm={12} xs={12}>
                                                No. of TH items
                                    </Col>
                                            <Col md={4} sm={12} xs={12} className="text-md-right">
                                                {lineItems.thPartClass}
                                            </Col>
                                            <Col md={8} sm={12} xs={12}>
                                                Total No. of Items
                                        </Col>
                                            <Col md={4} sm={12} xs={12} className="text-md-right">
                                                {lineItems.totalLines}
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </Col>
                            <Col md={12} sm={12} xs={12} className="text-right pr-0">
                                <Button
                                    variant="link"
                                    className="cm-global-suggestion-text text-white btnViewDetails"
                                    onClick={() => setOpen(!open)}
                                    aria-controls="lineItem-collapse-text"
                                    aria-expanded={open}
                                >
                                    {open ? (
                                        <span>
                                            hide Order details <FaChevronUp />
                                        </span>
                                    ) : (
                                            <span>
                                                View Order details <FaChevronDown />
                                            </span>
                                        )}
                                </Button>
                            </Col>
                        </Row></>}
                </div>
            </LoadingWrapper>
        </Col>
    );
};

export default OrderSummaryView;
