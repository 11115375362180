import { useEffect } from "react";
import { useLocation } from 'react-router-dom';

const usePageViews = () => {
    const location = useLocation();
    useEffect(() => {
        const doc = document;
        const trackPageViewScript = doc.createElement("script");
        trackPageViewScript.innerHTML = `ga('set', 'page', '${location}');
        ga('send', 'pageview');`
        doc.getElementsByTagName("head")[0].appendChild(trackPageViewScript);
    }, [location])

}

export default usePageViews;