import React, { useEffect, useState } from 'react'
import { Modal, Button, Row, Container, Col } from 'react-bootstrap'
import { OrderService } from "../../services/OrderService";
import { OIDCService } from "../../services/OIDCService";
import { trackPromise } from "react-promise-tracker";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { setCompletedSteps } from "../../redux/actions/CompletedStepActions";
import { URL_CONSTANT } from '../../constants/UrlConstant';
import { CommonService } from '../../services/CommonService';
import { clearJobType } from '../../redux/actions/ClearJobType';
import { clearStore } from '../../redux/actions/ClearStoreActions';

const PayTracePaymentModal = ({ show, handleClose, paymentData, onPaymentSuccess, selectedJobType, onQuoteComplete, salesPersonId }) => {

    const [showRetryBtn, setShowRetryBtn] = useState(false);
    const [clientTokens, setClientTokens] = useState({ clientKey: "", accessToken: "", userId: "" });
    const [isLoaded, setLoaded] = useState(false);
    const history = useHistory();
    const buttonHide = { "visibility": 'hidden', "height": 0, "width": 0 };
    const [antiCSRFToken, setAntiCSRFToken] = useState();

    const getClientKey = async () => {
        await OrderService.GetPayTraceClientkey().then((data) => {
            if (data) {
                let user = OIDCService.getUser();
                setClientTokens({
                    clientKey: data.clientKey,
                    accessToken: data.accessToken,
                    userId: user.profile.sub
                });
            }
        })
    }

    const netPayableAmount = () => {
        return ((paymentData.price + paymentData.tax + paymentData.shippingCharge) - paymentData.discount).toFixed(2)
    }

    const onPaymentDone = () => {
        onPaymentSuccess();
        onQuoteComplete(selectedJobType);
        handleClose();
        window.location.href = DATA_CONSTANT.thankYouOrderCompleteURL;
    }

    const onPaymentError = () => {
        setShowRetryBtn(true);
    }

    const onRetry = () => {
        if (showRetryBtn) {
            setShowRetryBtn(false);
        }
        handleClose();
    }

    const modalClose = () => {
        if (showRetryBtn) {
            setShowRetryBtn(false);
        }
        handleClose();
    }

    useEffect(() => {
        setAntiCSRFToken(CommonService.getCookie('X-CSRF-FORM-TOKEN'));
    }, []);

    useEffect(() => {
        if (clientTokens?.clientKey && clientTokens?.accessToken) {
            setLoaded(true);
        }
    }, [clientTokens])

    useEffect(() => {
        if (show == true) {
            trackPromise(getClientKey());
        }
    }, [show]);

    return (
        isLoaded &&
        <Modal show={show} onHide={modalClose} backdrop="static" size="lg" centered>

            <Modal.Header closeButton>
                <Modal.Title>Net payable <span className="text-orange"> $ {netPayableAmount()}</span> </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Container fluid>
                    <Row className="pt-3 pb-5">
                        <Col lg={{ span: 10, offset: 3 }} md={12} sm={12} xs={12}>
                            <div id="loadingDiv"></div>
                            <div id="error" className="mb-2 error-txt"></div>
                            {showRetryBtn &&
                                <Button type="button" id="retryButton" className="btn-primary-orange" onClick={onRetry} >Retry Payment</Button>
                            }
                            <form id='ProtectForm'>
                                <div id='pt_hpf_form'>
                                    <Helmet>
                                        <script>
                                            {`
                                                document.getElementById('loadingDiv').innerText = "Loading...";
                                                document.getElementById('submitButton').style.visibility = "hidden";
                                                PTPayment.setup({
                                                    styles:
                                                  {
                                                   'code': {
                                                    'font_color':'#404041',
                                                    'border_color':'#404041',
                                                    'font_size':'13pt',
                                                    'input_border_width':'1px',
                                                    'input_margin':'5px 0px 5px 20px',
                                                    'input_padding':'3px 5px 0px 5px',
                                                    'label_color':'#404041',
                                                    'label_size':'16px',
                                                    'label_width':'150px',
                                                    'label_margin':'5px 0px 0px 20px',
                                                    'label_padding':'2px 5px 2px 0px',
                                                    'background_color':'white',
                                                    'height':'30px',
                                                    'width':'110px',
                                                    'padding_bottom':'2px'
                                                   },
                                                   'cc': {
                                                    'font_color':'#404041',
                                                    'border_color':'#404041',
                                                    'border_style':'solid',
                                                    'font_size':'13pt',
                                                    'input_border_radius':'3px',
                                                    'input_border_width':'1px',
                                                    'input_font_weight':'400',
                                                    'input_margin':'5px 0px 5px 0px',
                                                    'input_padding':'3px 5px 0px 5px',
                                                    'label_color':'#404041',
                                                    'label_size':'16px',
                                                    'label_width':'150px',
                                                    'label_margin':'5px 0px 0px 0px',
                                                    'label_margin':'5px 0px 0px 0px',
                                                    'label_padding':'0px 5px 0px 0px',
                                                    'background_color':'white',
                                                    'height':'30px',
                                                    'width':'320px',
                                                    'padding_bottom':'0px'
                                                   },
                                                   'exp': {
                                                    'font_color':'#404041',
                                                    'border_color':'#404041',
                                                    'font_size':'12pt',
                                                    'input_border_radius':'3px',
                                                    'input_border_width':'1px',
                                                    'input_margin':'5px 0px 5px 0px',
                                                    'input_padding':'3px 5px 0px 5px',
                                                    'label_color':'#404041',
                                                    'label_size':'16px',
                                                    'label_width':'150px',
                                                    'label_font':'arial, fantasy, serif',
                                                    'label_font_weight':'normal',
                                                    'label_margin':'5px 0px 0px 0px',
                                                    'label_padding':'2px 5px 2px 0px',
                                                    'label_border_color':'#404041',
                                                    'label_border_radius':'0px',
                                                    'label_border_width':'2px',
                                                    'background_color':'white',
                                                    'height':'30px',
                                                    'width':'85px',
                                                    'padding_bottom':'2px',
                                                    'type':'dropdown'
                                                   }
                                                  },
                                                  authorization: {
                                                        "clientKey": "${clientTokens.clientKey}"
                                                  }
                                                }).then(function (instance) {
                                                     document.getElementById('loadingDiv').innerText = "";
                                                     document.getElementById('submitButton').style.visibility = "visible";
                                                   
                                                    document.getElementById("ProtectForm").addEventListener("submit", function (e) {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        PTPayment.validate(function (validationErrors) {
                                                            if (validationErrors.length >= 1) {
                                                                let responseCodeFourHundred = validationErrors.filter(function(item){return item.responseCode=='400'})
                                                                let appendText ="";                                                                
                                                                if(responseCodeFourHundred && responseCodeFourHundred.length > 0)
                                                                {
                                                                    responseCodeFourHundred.forEach(function(item){
                                                                       appendText += "<p>"+item.description +"</p>";
                                                                    })
                                                                }
                                                                else
                                                                {
                                                                    validationErrors.forEach(function(item){
                                                                        appendText += "<p>"+item.description +"</p>";
                                                                    })
                                                                }
                                                                document.getElementById('error').innerHTML = appendText;
                                                            } else {
                                                                instance.process()
                                                                .then((r) => submitPayment(r))
                                                                .catch((err) => handleError(err));
                                                            }
                                                        });
                                                    });
                                                    function handleError(err) {
                                                        console.log("Error: " + JSON.stringify(err));
                                                    }
                                                    function submitPayment(r) {
                                                        document.getElementById('error').innerHTML = "";
                                                        document.getElementById('submitButton').style.visibility = "hidden";
                                                        var data = {
                                                            hpf_token: r.message.hpf_token,
                                                            enc_key: r.message.enc_key,
                                                            accessToken: "${clientTokens.accessToken}",
                                                            orderId:"${paymentData.orderId}",
                                                            userId: "${clientTokens.userId}",
                                                            paymentId:"${paymentData.paymentId}",
                                                            salesPersonId:"${salesPersonId}"
                                                        }
                                                        var opts = {
                                                            headers: {
                                                                'content-type': 'application/json; charset=UTF-8',
                                                                'Authorization': "${DATA_CONSTANT.PortCQAPIToken}",
                                                                'X-XSRF-TOKEN':"${antiCSRFToken}"
                                                            },
                                                            method: 'post',
                                                            body: JSON.stringify(data)
                                                         }

                                                        fetch("${URL_CONSTANT.Checkout.ProtectSale}",opts)
                                                            .then((response)=>{
                                                                return response.json();
                                                            }).then(function(data) {
                                                                if(data.data && data.data.success){
                                                                    let paymentSuccessBtn = document.getElementById('paymentSuccess');
                                                                    paymentSuccessBtn.click();
                                                            }else{
                                                                document.getElementById('ProtectForm').style.display = 'none';
                                                                let errorDiv = document.getElementById('error');
                                                                errorDiv.innerText = data.data.status_message;
                                                                let paymentSuccessBtn = document.getElementById('paymentError');
                                                                paymentError.click();
                                                            }
                                                          });
                                                    }
                                                })
                                            `}
                                        </script>
                                    </Helmet>
                                </div>
                                <Button type="submit" id="submitButton" className="btn-primary-orange">Pay</Button>
                                <div id="paymentSuccess" style={buttonHide} onClick={onPaymentDone}></div>
                                <div id="paymentError" style={buttonHide} onClick={onPaymentError}></div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    )
}
const mapStateToProps = state => ({
    selectedJobType: state.JobTypes?.selectedJobType,
    salesPersonId: state.DefaultConfigs?.defaultConfigs?.salesPersonId
});

const mapDispatchToProps = (dispatch) => ({
    onPaymentSuccess: () => {
        dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.CheckoutPayment));
        dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.Checkout));
        dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.Completed));
    },
    onQuoteComplete: (selectedJobType) => {
        dispatch(clearStore());
        dispatch(clearJobType({ isAssemblyRequired: selectedJobType?.isAssemblyRequired, isBomRequired: selectedJobType?.isBomRequired, isPcbRequired: selectedJobType?.isPcbRequired, isComponentsRequired: selectedJobType?.isComponentsRequired }));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(PayTracePaymentModal);