import React, { useState } from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import './LeftRfqMenu.css';
import { DATA_CONSTANT } from '../../constants/DataConstant';
import { Steps } from 'antd';
import _, { contains } from 'underscore';
import RFQStep from './RFQStep';
import { RequestFormService } from '../../services/RequestFormService';
import { useEffect } from 'react';
import { FaRegCheckCircle, FaRegCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const LeftRfqMenu = ({ completedSteps, selectedJobType, isCustomQuoteRequires }) => {
    const { Step } = Steps;
    const [rfqSteps, setRFQSteps] = useState([]);
    const location = useLocation();
   
    useEffect(() => {
        if (selectedJobType?.cqJobType) {
            getRFQSteps(selectedJobType?.cqJobType);
        }
    }, []);

    useEffect(() => {
        if (isCustomQuoteRequires && rfqSteps.length > 0) {
            let filteredRFQSteps = rfqSteps.filter(r => contains(completedSteps, r.stepName) || r.stepName == DATA_CONSTANT.stepNames.Completed);
            setRFQSteps(filteredRFQSteps);
        }
    }, [isCustomQuoteRequires]);

    const getRFQSteps = async (cqJobType) => {
        await RequestFormService.getRFQSteps(cqJobType).then((data) => {
            // Assign api response to data constant so that can use in all pages
            DATA_CONSTANT.rfqSteps = data;
            let rfqSteps = data;
            if (isCustomQuoteRequires && completedSteps.length > 0) {
                rfqSteps = data.filter(r => contains(completedSteps, r.stepName) || r.stepName == DATA_CONSTANT.stepNames.Completed);
            } 
            setRFQSteps(rfqSteps);
        });
    }

    function CurrentStepForCheckOut() {
        let currentStep = -1;
        if (completedSteps.indexOf(DATA_CONSTANT.stepNames.CheckoutBillingShipping) > -1) {
            currentStep = 0;
        }
        if (completedSteps.indexOf(DATA_CONSTANT.stepNames.CheckoutPayment) > -1) {
            currentStep = 1;
        }
        if (completedSteps.indexOf(DATA_CONSTANT.stepNames.CheckoutPayment) > -1) {
            currentStep = 2;
        }
        return currentStep;
    }

    const redirectToStep = (stepName, route) => {
        
    }

    return (<>
        {
            rfqSteps && rfqSteps.length > 0 &&
            <Nav defaultActiveKey="/bom" className="flex-column">
                {rfqSteps.map((step) => {
                    return (
                        <NavItem key={step.id}>
                            <Nav.Link eventKey={step.stepName}
                                href='#'
                                onClick={() => redirectToStep(step.stepName, step.routeName)}
                                disabled={true}
                                className={(location.pathname === ('/' + (step.stepName).toLowerCase()) ? "cm-active-step" : "")}>
                                {(completedSteps.indexOf(step.stepName) > -1) ? <FaRegCheckCircle className="icon-color" /> : <FaRegCircle />} {step.displayName}
                            </Nav.Link>

                            {step.childSteps.length > 0 &&
                                <div className="cm-checkout-stepper">
                                    <Steps progressDot current={CurrentStepForCheckOut()} direction="vertical">
                                        {step.childSteps.map((child) => {
                                            return (
                                                <Step key={child.id}
                                                    title={child.displayName} />
                                            )
                                        })}
                                    </Steps>
                                </div>
                            }
                        </NavItem>
                    );
                })}
            </Nav>
        }
        
    </>
    )
};

export default LeftRfqMenu;