import React, { useEffect, useState, useCallback } from "react";
import { CheckListBuilderService } from "../../services/CheckListBuilderService";
import InputCheck from "../../components/ControlComponents/Input/InputCheck";
import DropDown from "../../components/Common/Input/InputDropDown";
import InputTextBox from "../../components/ControlComponents/Input/InputTextBox";
import { trackPromise } from "react-promise-tracker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { unique, difference, indexOf } from "underscore";
import { useForm } from "react-hook-form";
import { MESSAGE_CONSTANT } from "./../../constants/MessageConstant";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import moment from "moment";
import { GroupButton } from "../../components/ControlComponents/Input/index";
import "./CheckListBuilder.css";

const CheckListBuilder = (props) => {
    const [checkListBuilderData, SetCheckListBuilderData] = useState([]);

    const [checkListScreenData, SetCheckListScreenData] = useState([]);

    const [isLoaded, SetIsLoaded] = useState(false);

    const [selectCustomErrors, setSelectCustomErrors] = useState([]);

    const { register, errors, trigger } = useForm();

    let componentMounted = true;

    let className = props.ScreenName.toUpperCase().trim() === DATA_CONSTANT.ScreenName.AssemblyDetail ? 'col-lg-4 col-md-4 col-sm-12 cm-form-horizontal' : '';

    useEffect(() => {
        if (componentMounted && props.JobTypeName?.cqJobType) {
            trackPromise(
                CheckListRender(props.JobTypeName.cqJobType, props.ScreenName)
            );
        }
        return () => {
            componentMounted = false;
        }
    }, [props.JobTypeName.cqJobType, props.ScreenName]);

    const CheckListRender = async (jobTypeName, screenName) => {
        await CheckListBuilderService.getCheckListBuilder(jobTypeName, screenName)
            .then((data) => {
                var isChecklistFieldsExists = false;
                if (
                    data.checkListJobTypewise &&
                    data.checkListJobTypewise.checklistFieldList.length > 0
                ) {
                    let CheckListData = data.checkListJobTypewise.checklistFieldList;
                    let CheckListScreenWise = data.checkListScreenwise.filter(x => CheckListData.some(y => y.name === x.checklistFieldName));;
                    let CheckListDataFilterWithScreenWise = [];
                    CheckListScreenWise.map((value, index) => {
                        CheckListData.map((val, ind) => {
                            if (value.checklistFieldName == val.name) {
                                val.CustomQuoteValues = value.customQuoteValues;
                                val.IsCustomQuoteRequires = value.requiresCustomQuote;
                                CheckListDataFilterWithScreenWise.push(val);
                            }
                        });
                    });
                    SetCheckListBuilderData(CheckListDataFilterWithScreenWise);
                    SetCheckListScreenData(CheckListScreenWise);
                    SetIsLoaded(true);
                    props.onChangeCheckListBuilder(CheckListDataFilterWithScreenWise);
                    isChecklistFieldsExists = CheckListScreenWise.some((c) =>
                        CheckListDataFilterWithScreenWise.some(
                            (s) => s.name == c.checklistFieldName
                        )
                    );
                }

                if (props.setIsChecklistFieldsExists) {
                    props.setIsChecklistFieldsExists(isChecklistFieldsExists);
                }
            })
    };

    const onTextBoxChange = (val, name, index, requireValidation) => {
        SetIsLoaded(false);
        if (requireValidation) {
            trigger(name);
        }
        const objCheckListBuilder = [...checkListBuilderData];
        if (objCheckListBuilder[index].fieldType == "Date") {
            val = moment(val.toString()).format("MM/DD/YYYY");
        }
        if (objCheckListBuilder[index].fieldType == "DropDown" && objCheckListBuilder[index].required) {
            if (val.length <= 0) {
                selectCustomErrors.push({
                    name: objCheckListBuilder[index]?.name,
                    error: MESSAGE_CONSTANT.CheckListBuilder.required
                        .toString()
                        .formatGeneric(objCheckListBuilder[index].name),
                });
            }
            //else if (selectCustomErrors.find( (x) => x.name === objCheckListBuilder[index]?.name) && val.length != 0)
            //{
            //    selectCustomErrors.splice(
            //        indexOf(objCheckListBuilder[index]?.name) - 1,
            //        1
            //    );
            //}
            else if (selectCustomErrors.find((x) => x.name === objCheckListBuilder[index]?.name)) {
                let errorIndex = selectCustomErrors.findIndex((x) => x.id == objCheckListBuilder[index]?.id)
                selectCustomErrors.splice(errorIndex, 1);
            }
        }
        objCheckListBuilder[index].defaultValue = val;
        SetCheckListBuilderData(objCheckListBuilder);
        props.onChangeCheckListBuilder(objCheckListBuilder);
        SetIsLoaded(true);
    };
    const onCheckBoxChange = (val, name, index, defaultChecked, requireValidation) => {
        SetIsLoaded(false);
        if (requireValidation) {
            trigger(name);
        }
        let objCheckListBuilder = [...checkListBuilderData];
        let values = [];
        if (objCheckListBuilder[index].defaultValue != null) {
            values =
                objCheckListBuilder[index].defaultValue == null ||
                    objCheckListBuilder[index].defaultValue == ""
                    ? []
                    : typeof objCheckListBuilder[index].defaultValue == "string"
                        ? objCheckListBuilder[index].defaultValue.split(",")
                        : objCheckListBuilder[index].defaultValue;
        }
        values.push(val);
        if (defaultChecked == true) {
            objCheckListBuilder[index].defaultValue = unique(values);
        } else {
            objCheckListBuilder[index].defaultValue = difference(values, [val]);
        }
        SetCheckListBuilderData(objCheckListBuilder);
        props.onChangeCheckListBuilder(checkListBuilderData);
        SetIsLoaded(true);
    };

    const checkValidation = useCallback(() => {
        trigger().then((result) => {
            let isValidForm = false;
            let objCheckListBuilder = [...checkListBuilderData];
            let validationError = [];
            objCheckListBuilder.forEach((element) => {
                if (element.fieldType == "DropDown" && element.required) {
                    element.defaultValue = element.defaultValue === null ? "" : element.defaultValue;
                    if ((element.defaultValue === null || element.defaultValue?.length == 0) && (!validationError.find((x) => x.name === element.name))) {
                        validationError.push({
                            name: element.name,
                            id: element.id,
                            error: MESSAGE_CONSTANT.CheckListBuilder.required
                                .toString()
                                .formatGeneric(element.name),
                        });
                    }
                    if (element.defaultValue?.length > 0 && validationError.find((x) => x.name === element.name)) {
                        validationError.splice(indexOf(element?.name) - 1, 1);
                    }
                }
            });

            if (result && validationError?.length <= 0) {
                isValidForm = true;
            }
            setSelectCustomErrors(validationError);
            props.isValidChildCheck(isValidForm);
        });
    });

    useEffect(() => {
        if (props.validateForm) {
            checkValidation();
        }
    }, [props.validateForm, trigger]);

    return (
        <div>
            {isLoaded &&
                checkListScreenData.map((value, index) => {
                    if (value.screenName?.toLowerCase()?.trim() == props.ScreenName?.toLowerCase()?.trim()) {
                        return (
                            <div key={index} className={className}>
                                {checkListBuilderData.map((val, ind) => {
                                    if (value.checklistFieldName == val.name) {
                                        switch (val.fieldType) {
                                            case "TextBox":
                                                return (
                                                    <React.Fragment key={val.id}>
                                                        <Row>
                                                            <Col className="cm-input-container">
                                                                <InputTextBox
                                                                    id={ind.toString()}
                                                                    label={val.name}
                                                                    name={val.name}
                                                                    value={
                                                                        val.defaultValue == null
                                                                            ? ""
                                                                            : val.defaultValue
                                                                    }
                                                                    isRequired={val.required}
                                                                    helpText={value.description}
                                                                    errorMessage={errors}
                                                                    onChange={(event) =>
                                                                        onTextBoxChange(
                                                                            event.target.value.toString(),
                                                                            event.target.name,
                                                                            event.target.id,
                                                                            true
                                                                        )
                                                                    }
                                                                    ref={register({
                                                                        required: val.required
                                                                            ? MESSAGE_CONSTANT.CheckListBuilder.required
                                                                                .toString()
                                                                                .formatGeneric(val.name)
                                                                            : false,
                                                                        validate: (value) =>
                                                                            value.toString().length > val.maxLength
                                                                                ? MESSAGE_CONSTANT.CheckListBuilder.maxValue
                                                                                    .toString()
                                                                                    .formatGeneric(val.maxLength)
                                                                                : null,
                                                                    })}
                                                                ></InputTextBox>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                );
                                            case "CheckBox":
                                                let checkboxDefaultValue =
                                                    typeof val.defaultValue == "string"
                                                        ? val.defaultValue.split(",")
                                                        : val.defaultValue;
                                                return (
                                                    <React.Fragment key={val.id}>
                                                        <Row>
                                                            <Col className="cm-input-container">
                                                                <InputCheck
                                                                    type="switch"
                                                                    className="cm-switch"
                                                                    id={ind.toString()}
                                                                    label={value.isLabelDisplay ? val.name : ""}
                                                                    name={val.name}
                                                                    value={checkboxDefaultValue}
                                                                    list={val.fieldsValueOptions}
                                                                    listLabel={"optionValue"}
                                                                    listValue={"optionText"}
                                                                    isRequired={val.required}
                                                                    helpText={value.description}
                                                                    errorMessage={errors}
                                                                    selectedValues={checkboxDefaultValue}
                                                                    onChange={(event) =>
                                                                        onCheckBoxChange(
                                                                            event.target.value.toString(),
                                                                            val.name,
                                                                            ind,
                                                                            event.target.checked,
                                                                            true
                                                                        )
                                                                    }
                                                                    ref={register({
                                                                        required: val.required
                                                                            ? MESSAGE_CONSTANT.CheckListBuilder.required
                                                                                .toString()
                                                                                .formatGeneric(val.name)
                                                                            : false,
                                                                    })}
                                                                ></InputCheck>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                );
                                            case "Date":
                                                return (
                                                    <React.Fragment key={val.id}>
                                                        <Row>
                                                            <Col className="cm-input-container">
                                                                <InputTextBox
                                                                    type="date"
                                                                    id={ind.toString()}
                                                                    label={val.name}
                                                                    name={val.name}
                                                                    value={val.defaultValue}
                                                                    isRequired={val.required}
                                                                    helpText={value.description}
                                                                    format={DATA_CONSTANT.patterns.dateFormat}
                                                                    errorMessage={errors}
                                                                    onChange={(event) =>
                                                                        onTextBoxChange(
                                                                            event.target.value,
                                                                            event.target.name,
                                                                            event.target.id,
                                                                            true
                                                                        )
                                                                    }
                                                                    ref={register({
                                                                        required: val.required
                                                                            ? MESSAGE_CONSTANT.CheckListBuilder.required
                                                                                .toString()
                                                                                .formatGeneric(val.name)
                                                                            : false,
                                                                    })}
                                                                ></InputTextBox>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                );
                                            case "DropDown":
                                                return (
                                                    <React.Fragment key={val.id}>
                                                        <Row>
                                                            <Col className="cm-input-container">
                                                                <DropDown
                                                                    label={val.name}
                                                                    isRequired={val.required}
                                                                    value={val.defaultValue}
                                                                    name={val.name}
                                                                    listOfValues={val.fieldsValueOptions}
                                                                    id={ind.toString()}
                                                                    helpText={value.description}
                                                                    onChange={(e) =>
                                                                        onTextBoxChange(e, val.name, ind, false)
                                                                    }
                                                                ></DropDown>
                                                                {selectCustomErrors.length > 0 && (
                                                                    <span className="error-txt">
                                                                        {
                                                                            selectCustomErrors.find(
                                                                                (x) => x.name == val.name
                                                                            )?.error
                                                                        }
                                                                    </span>
                                                                )}
                                                            </Col>
                                                        </Row>

                                                    </React.Fragment>
                                                );
                                            case "Password":
                                                return (
                                                    <React.Fragment key={val.id}>
                                                        <Row>
                                                            <Col className="cm-input-container">
                                                                <InputTextBox
                                                                    type="password"
                                                                    id={ind.toString()}
                                                                    label={val.name}
                                                                    name={val.name}
                                                                    value={
                                                                        val.defaultValue == null
                                                                            ? ""
                                                                            : val.defaultValue
                                                                    }
                                                                    isRequired={val.required}
                                                                    helpText={value.description}
                                                                    errorMessage={errors}
                                                                    onChange={(event) =>
                                                                        onTextBoxChange(
                                                                            event.target.value.toString(),
                                                                            event.target.name,
                                                                            event.target.id,
                                                                            true
                                                                        )
                                                                    }
                                                                    ref={register({
                                                                        required: val.required
                                                                            ? MESSAGE_CONSTANT.CheckListBuilder.required
                                                                                .toString()
                                                                                .formatGeneric(val.name)
                                                                            : false,
                                                                        validate: (value) =>
                                                                            value.toString().length > val.maxLength
                                                                                ? MESSAGE_CONSTANT.CheckListBuilder.maxValue
                                                                                    .toString()
                                                                                    .formatGeneric(val.maxLength)
                                                                                : null,
                                                                    })}
                                                                ></InputTextBox>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                );
                                            case "Email":
                                                return (
                                                    <React.Fragment key={val.id}>
                                                        <Row>
                                                            <Col className="cm-input-container">
                                                                <InputTextBox
                                                                    type="email"
                                                                    id={ind.toString()}
                                                                    label={val.name}
                                                                    name={val.name}
                                                                    value={
                                                                        val.defaultValue == null
                                                                            ? ""
                                                                            : val.defaultValue
                                                                    }
                                                                    isRequired={val.required}
                                                                    helpText={value.description}
                                                                    errorMessage={errors}
                                                                    onChange={(event) =>
                                                                        onTextBoxChange(
                                                                            event.target.value.toString(),
                                                                            event.target.name,
                                                                            event.target.id,
                                                                            true
                                                                        )
                                                                    }
                                                                    ref={register({
                                                                        required: val.required
                                                                            ? MESSAGE_CONSTANT.CheckListBuilder.required
                                                                                .toString()
                                                                                .formatGeneric(val.name)
                                                                            : false,
                                                                        pattern: {
                                                                            value: DATA_CONSTANT.patterns.email,
                                                                            message: MESSAGE_CONSTANT.CheckListBuilder.invalid
                                                                                .toString()
                                                                                .formatGeneric(val.name),
                                                                        },
                                                                    })}
                                                                ></InputTextBox>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                );
                                            case "Number":
                                                return (
                                                    <React.Fragment key={val.id}>
                                                        <Row>
                                                            <Col className="cm-input-container">
                                                                <InputTextBox
                                                                    type="text"
                                                                    id={ind.toString()}
                                                                    label={val.name}
                                                                    name={val.name}
                                                                    value={
                                                                        val.defaultValue == null
                                                                            ? ""
                                                                            : val.defaultValue
                                                                    }
                                                                    isRequired={val.required}
                                                                    helpText={value.description}
                                                                    errorMessage={errors}
                                                                    onChange={(e) =>
                                                                        onTextBoxChange(
                                                                            e.target.value.toString(),
                                                                            e.target.name,
                                                                            e.target.id,
                                                                            true
                                                                        )
                                                                    }
                                                                    ref={register({
                                                                        required: val.required
                                                                            ? MESSAGE_CONSTANT.CheckListBuilder.required
                                                                                .toString()
                                                                                .formatGeneric(val.name)
                                                                            : false,
                                                                        validate: (value) =>
                                                                            value.toString().length > val.maxLength
                                                                                ? MESSAGE_CONSTANT.CheckListBuilder.maxValue
                                                                                    .toString()
                                                                                    .formatGeneric(val.maxLength)
                                                                                : null,
                                                                        pattern: {
                                                                            value: DATA_CONSTANT.patterns.checkListBuilderNumber,
                                                                            message: MESSAGE_CONSTANT.CheckListBuilder.invalid.formatGeneric(
                                                                                val.name
                                                                            ),
                                                                        },
                                                                    })}
                                                                ></InputTextBox>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                );
                                            case "RadioButton":
                                                let defaultValue = val.defaultValue ? val.defaultValue.toString().split(",") : []
                                                return (
                                                    <React.Fragment key={val.id}>
                                                        <Row>
                                                            <Col className="cm-input-container">
                                                                <GroupButton
                                                                    key={ind.toString()}
                                                                    id={ind.toString()}
                                                                    label={val.name}
                                                                    type='radio'
                                                                    name={val.name}
                                                                    list={val.fieldsValueOptions}
                                                                    listLabel={"optionValue"}
                                                                    listValue={"optionText"}
                                                                    value={defaultValue}
                                                                    isRequired={val.required}
                                                                    helpText={value.description}
                                                                    onChange={(selectedvalue, event) => onTextBoxChange(
                                                                        event.target.value.toString(),
                                                                        event.target.name,
                                                                        ind,
                                                                        true
                                                                    )}
                                                                    ref={register({
                                                                        required: val.required
                                                                            ? MESSAGE_CONSTANT.CheckListBuilder.required
                                                                                .toString()
                                                                                .formatGeneric(val.name)
                                                                            : false,
                                                                    })}
                                                                    errorMessage={errors}
                                                                ></GroupButton>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                );
                                        }
                                    }
                                })}
                            </div>
                        );
                    }
                })}
        </div>
    );
};

export default CheckListBuilder;
