import React from "react";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import "./GroupToggle.css";
import InfoToolTip from "../../InfoToolTip";

const GroupToggle = React.forwardRef(
  (
    {
      label,
      name,
      type,
      list, // List to bind drop down list
      listLabel, // Label property from list
      listValue, // Value property from list
      listToolTip, // Tooltip property from list
      value,
      isRequired, // Displays * for required field
      onChange,
      appendText, // append after actual value
      errorMessage,
      helpText,
      helpPlacement,
      className,
      ...props
    },
    ref
  ) => {
    if (
      list.length === 0 ||
      (typeof list[0] == "object" &&
        !listValue &&
        !list[0].hasOwnProperty(listValue))
    ) {
      return <></>;
    }

    const GroupBtnUI = (item, index) => {
      if (typeof item != "string") {
        item[listLabel] = item[listLabel] ? item[listLabel] : item[listValue]; // Sets item value as label when label is not passed
      }
      let btnValue = typeof item === "string" ? item : item[listValue];
      let btnLabel = typeof item === "string" ? item : item[listLabel];
      let btnTitle = typeof item === "string" ? item : item[listToolTip];

      return (
        <ToggleButton
          key={`${item}-${index}`}
          name={name}
          value={btnValue}
          title={btnTitle}
          inputRef={ref}
          className={`mb-0 mb-sm-0 cm-group-toggle`}
          {...props}
        >
          {btnLabel} {appendText}
        </ToggleButton>
      );
    };

    return (
      <>
        {label ? (
          <>
            <Form.Label> {label} </Form.Label>
            {isRequired && <span className="error-txt">*</span>}
            {helpText && helpText.trim() && (
              <InfoToolTip
                className={className}
                helpText={helpText}
                helpPlacement={helpPlacement}
              />
            )}
          </>
        ) : (
          <div className="empty-label-padding"></div>
        )}
        <div className="cm-group-toggle">
          {
            <ToggleButtonGroup
              type={type}
              value={value}
              name={name}
              onChange={onChange}
              className="row mx-0"
            >
              {list &&
                list.length > 0 &&
                list.map((item, index) => {
                  return GroupBtnUI(item, index);
                })}
            </ToggleButtonGroup>
          }
          <div>
            {errorMessage &&
              errorMessage[name] &&
              errorMessage[name].message && (
                <span className="error-txt">
                  {" "}
                  {errorMessage[name].message}{" "}
                </span>
              )}
          </div>
        </div>
      </>
    );
  }
);

GroupToggle.defaultProps = {
  className: "",
  type: "radio",
};

GroupToggle.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["checkbox", "radio"]),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  list: PropTypes.array.isRequired,
  listValue: PropTypes.string,
  listToolTip: PropTypes.string,
  appendText: PropTypes.string,
  helpText: PropTypes.string,
  placement: PropTypes.oneOf(["right", "left", "top", "bottom"])
};

export default GroupToggle;
