export const DynamicLabelKey = {
    Quantity: 'quantity',
    Turntime: 'turntime',
    ShipDate: 'shipDate',
    AssemblyCost: 'assemblyCost',
    AdditionalServices: 'additionalServices',
    Pcb: 'pcb',
    TotalPrice: 'totalPrice',
    GetAQuoteButton: 'GetAQuoteButton',
    BomAnalysisButton: 'BomAnalysisButton'
}