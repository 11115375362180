import React, { useEffect } from 'react';
import { OIDCService } from "../../services/OIDCService";
import { SessionStorageService } from '../../services/SessionStorageService';

const RedirectToLoginPage = () => {
    useEffect(() => {
        let isUserLoggedIn = OIDCService.login();
        if (isUserLoggedIn === true) {
            const postLoginUrl = SessionStorageService.getPostLoginUrl() || '';
            SessionStorageService.removePostLoginUrl();
            window.location.href = postLoginUrl;
        }
    }, []);
    return (
        <></>
    );
};
export default RedirectToLoginPage;