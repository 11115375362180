import React, { useState, useEffect } from "react";
import { PrimaryOrangeButton } from '../../components/Common/InputButton/InputButton';
import { ErrorPage_CONSTANT } from './../../constants/ErrorPageConstants';
import { useHistory } from 'react-router-dom';
import { BiX } from "react-icons/bi";
import './Error.css';

const ErrorPage = (props) => {

    let statusCode = props.location.state == undefined ? 500 : props.location.state;
    const history = useHistory();
    const [errorDetail, seterrorDetails] = useState({ errorTitle: "", errorMessage: "", hasError: false });

    const backToHome = () => {
        history.push('/');
    }
    useEffect(() => {
        getErrorPageDetail(statusCode);
    }, [])

    const getErrorPageDetail = (statusCode) => {
        switch (statusCode) {
            case ErrorPage_CONSTANT.pageNotFound.statusCode:
                seterrorDetails({
                    errorTitle: ErrorPage_CONSTANT.pageNotFound.title,
                    errorMessage: ErrorPage_CONSTANT.pageNotFound.message,
                    hasError: true
                });
                break;
            case ErrorPage_CONSTANT.accessDenied.statusCode:
                seterrorDetails({
                    errorTitle: ErrorPage_CONSTANT.accessDenied.title,
                    errorMessage: ErrorPage_CONSTANT.accessDenied.message,
                    hasError: true
                });
                break;
            default:
                seterrorDetails({
                    errorTitle: ErrorPage_CONSTANT.internalServer.title,
                    errorMessage: ErrorPage_CONSTANT.internalServer.message,
                    hasError: true
                });
                break;
        }
    }

    return (
        <>
            {errorDetail.hasError &&
                <div className="overflow-none">
                    <h1 className="header text-center">Oops! Something went wrong.</h1>
                    <BiX size="5em" color="red" className="cm-remove-icon" />
                    <h1 className="header text-center">{errorDetail.errorTitle}</h1>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-12">
                            <h3 className="text-center content-space">
                                {statusCode == ErrorPage_CONSTANT.internalServer.statusCode ?
                                    (<>
                                        {errorDetail.errorMessage.split('-')[0]}
                                        <a href={`mailto:${errorDetail.errorMessage.split('-')[1]}`}><span className="cm-email">{errorDetail.errorMessage.split('-')[1]}</span></a>
                                        {errorDetail.errorMessage.split('-')[2]}
                                    </>) : (<>{errorDetail.errorMessage}</>)
                                }
                            </h3>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                    {statusCode != ErrorPage_CONSTANT.accessDenied.statusCode &&
                        <div className="text-center content-space">
                            <PrimaryOrangeButton name="Back to Home" handleClick={() => backToHome()} />
                        </div>
                    }
                </div>
            }
        </>
    );
};

export default ErrorPage;
