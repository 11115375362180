import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { PrimaryOrangeButton } from "../../components/Common/InputButton/InputButton";
import LaborQuestionList from "../../components/Labor/LaborQuestionList";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { setCompletedSteps } from "../../redux/actions/CompletedStepActions";
import { LaborService } from "../../services/LaborService";
import { CheckListBuilderService } from "../../services/CheckListBuilderService";
import { CommonService } from "./../../services/CommonService";
import { each } from "underscore";
import { trackPromise } from "react-promise-tracker";

const LaborPage = ({ onStepCompleted, assemblyId, selectedJobType, RFQID }) => {
    const history = useHistory();

    const [validateChildForm, setValidateChildForm] = useState(false);

    const handleNextClicked = () => {
        setValidateChildForm(true);
    };

    const submitCheckListDetail = async (data) => {
        let values = CommonService.deepShallowCopy(data);
        let objForCheckListBuilder = {};
        objForCheckListBuilder.RFQId = RFQID;
        objForCheckListBuilder.assemblyId = assemblyId;
        objForCheckListBuilder.RFQCustomFieldValues = [];
        if (values.length > 0) {
            each(values, function (value) {
                if (
                    (value.fieldType == "CheckBox" || value.fieldType == "DropDown") &&
                    value.defaultValue != null
                ) {
                    if (Array.isArray(value.defaultValue)) {
                        value.defaultValue = value.defaultValue.join(",");
                    }
                } else if (value.defaultValue == null) {
                    value.defaultValue = "";
                }
            });
            values.map((vals, inds) => {
                var objValues = {};
                objValues.FieldTypesName = vals.name;
                objValues.FieldTypeValue = vals.defaultValue;
                objForCheckListBuilder.RFQCustomFieldValues.push(objValues);
            });
            await CheckListBuilderService.submitCheckListBuilder(objForCheckListBuilder)
                .then((data) => {
                    if (data != null) {
                        stepCompleted();
                    }
                })
                .catch(() => {
                    setValidateChildForm(false);
                });
        } else {
            stepCompleted();
        }
    };

    const submitLaborAnswers = async (laborAnswers) => {
        await LaborService.submitLaborAnswers(laborAnswers)
            .then((data) => {
                trackPromise(submitCheckListDetail(laborAnswers.CheckListBuilderData));
            })
            .catch(() => {
                setValidateChildForm(false);
            });
    };

    const stepCompleted = () => {
        onStepCompleted();

        let nextStepIndex = DATA_CONSTANT.rfqSteps.findIndex(x => x.stepName === DATA_CONSTANT.stepNames.Labor) + 1;
        history.push(DATA_CONSTANT.rfqSteps[nextStepIndex].routeName);
    };

    return (
        <Row>
            <Col md={8} sm={12} xs={12} lg={6}>
                <Row className="mb-4">
                    <Col>
                        <h2>Assembly Details</h2>
                    </Col>
                </Row>
                <LaborQuestionList
                    validateForm={validateChildForm}
                    onValidationReset={() => {
                        setValidateChildForm(false);
                    }}
                    submitLaborAnswers={submitLaborAnswers}
                    stepCompleted={stepCompleted}
                />

                <Row className="text-center my-5">
                    <Col md={11} sm={12} xs={12} className="text-center">
                        <PrimaryOrangeButton
                            name="Next"
                            handleClick={() => handleNextClicked()}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const mapStateToProps = (state) => ({
    defaultConfigs: state.DefaultConfigs?.defaultConfigs,
    assemblyId: state.RFQDetails?.rfqDetails?.assemblyId,
    selectedJobType: state.JobTypes.selectedJobType,
    RFQID: state.RFQDetails?.rfqDetails?.quoteId,
});

const mapDispatchToProps = (dispatch) => ({
    onStepCompleted: () => {
        dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.Labor));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LaborPage);
