import { CLEAR_STORE, SET_BOM_FILES } from '../../constants/ActionConstants';

const InitialState = {};
export const BomFile = (state = InitialState, action) => {
    const { type, data } = action;

    switch (type) {
        case SET_BOM_FILES:
            return { ...state, bomFile: data.bomFile };
        case CLEAR_STORE:
            return InitialState;
        default:
            return state;
    }
}