export const bomFormatDescriptionList=
{
    requiredInformation:[
        {
            title:"Required Information (BOM)",
            isTableFormat:false,
            description:[
                {
                    description:"Quantity"
                },
                {
                    description:"Reference Designator"
                },
                {
                    description:"Description Value Package"
                },
                {
                    description:"Manufacturer Part Number"
                }
            ]
        }
    ],
    bestPracticesFormatting:[
        {
            title:"Best Practices Formatting",
            isTableFormat:false,
            description:[
            {
                title:"REFERENCE DESIGNATORS",
                description:"Place all designators with the same part number in one cell"
            },
            {
                title:"DESCRIPTION VALUE PACKAGE",
                description:"Our system prefers this to be combined into one cell"
            },
            {
                title:"ALTERNATE PART NUMBERS",
                description:"Place in additional columns to the right on the same line"
            },
            {
                title:"DO NOT INSTALL (DNI) PARTS",
                description:"Indicate quantity zero and/or add DNI column"
            },
            {
                title:"NOTES",
                description:"Place all notes in an additional column on the same line"
            },
            {
                title:"MERGED CELLS",
                description:"Please refrain from using merged cells"
            }                                   
        ]
    }
    ],
    billOfMaterial:[
    {
        title:"Bill Of Materials",
        isTableFormat:true,
        description:[
        {
            mfgPart:"12065A330JAT2A",
            descriptionvaluepkg:"CAP .33pF 5V 1206",
            referencedescription:"U1, U2",
            qty:2,
            dni:" ",
            notes:""
        },
        {
            mfgPart:"C1206C104K3HACTU",
            descriptionvaluepkg:"CAP CER 0.1UF 25V 10% ULTRASTABLE X8R 1206",
            referencedescription:"C10, C19, C26, C27, C28, C36, C57, C59 ",
            qty:13,
            dni:" ",
            notes:"Must purchase from Digi-Key"
        },
        {
            mfgPart:"VFT2045BP-M3/4W",
            descriptionvaluepkg:"Diode Schottkey 45V 20A ITO-220AC",
            referencedescription:"D1",
            qty:1,
            dni:" ",
            notes:""
        },
        {
            mfgPart:"CDH53-680JC",
            descriptionvaluepkg:"Inductor Power 68µH 1.5 Arms 0.18DCR TSSOP",
            referencedescription:"L1",
            qty:0,
            dni:"DNI",
            notes:""
        },
        {
            mfgPart:"ERJ-1TNF1201U",
            descriptionvaluepkg:"Resistor 12K OHM 1W 1% 2512 SMD",
            referencedescription:"R5, R6",
            qty:2,
            dni:"",
            notes:""
                    
        }
        ]
    }
    ]
}
