import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';

export const DocumentService = {

    downLoadFile: (filename) => {
        return CommonHttpService.DownLoadFile(`${URL_CONSTANT.RequestForm.DownLoadFile}?fileName=${filename}`, filename);
    },
    generatePreSignedUrl: (count) => {
        return CommonHttpService.httpGet(`${URL_CONSTANT.Document.GeneratePreSignedUrl}?documentCount=${count}`);
    },
    uploadFilesToS3: (fileData) => new Promise((resolve, reject) => {

        const requests = fileData.map((obj) => CommonHttpService.httpFilePutToS3(obj.presignedURL, obj.file));

        Promise.all(requests).then((responses) => {
            resolve(responses);
        })
            .catch((errors) => {
                errors.forEach((error) => console.error(error));
                reject(errors);
            });
    }),
    insertDocuments: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Document.InsertDocuments, data);
    },

}