import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { PrimaryOrangeButton } from '../../components/Common/InputButton/InputButton';
import { Table, Row } from 'react-bootstrap';
import { FaTrash, FaRegEdit } from "react-icons/fa";
import { FcPlus } from "react-icons/fc";
import { InputTextBox } from "../../components/ControlComponents/Input/index";
import { MESSAGE_CONSTANT } from "../../constants/MessageConstant";
import { useForm } from "react-hook-form";
import { max, first } from "underscore";
import './AddAlternates.css';
import Swal from 'sweetalert2';

const AddAlternates = ({ lineItemsforAddAlternate, show, handleClose, handleAlternates }) => {

    const [isShow, setShow] = useState(show);

    const { register, errors, trigger, clearErrors } = useForm();
    const [partNumberList, SetPartNumberList] = useState([]);
    const [isLoad, SetIsLoad] = useState(false);

    const modalClose = () => {
        setShow(false);
        handleClose();
    }

    useEffect(() => {
        if (lineItemsforAddAlternate) {
            setInitialPartNumberList();
            SetIsLoad(true);
        }
    }, [lineItemsforAddAlternate])

    let lineItem = lineItemsforAddAlternate.lineItem;
    let initialLineItem = first(lineItemsforAddAlternate.partNumberList);

    const setInitialPartNumberList = () => {
        let partNumberList = lineItemsforAddAlternate.partNumberList;
        let partNumberId = max(partNumberList, function (o) { return o.partNumberId; }).partNumberId;
        partNumberList.length == 1 ? SetPartNumberList([
            ...partNumberList,
            {
                partNumberId: partNumberId + 1,
                lineItem: lineItem,
                manufacturer: '',
                mpn: '',
                description: initialLineItem.description,
                referenceDesignator: initialLineItem.referenceDesignator,
                isExistingLineItem: false,
                isAlternateAlreadyExist: false,
                isAdd: true,
                isEdit: false,
                isDelete: true,
            }]) : SetPartNumberList([...partNumberList]);
    }

    const addAlternate = () => {
        let partNumberId = max(partNumberList, function (o) { return o.partNumberId; }).partNumberId;
        SetPartNumberList([
            ...partNumberList,
            {
                partNumberId: partNumberId + 1,
                lineItem: lineItem,
                manufacturer: '',
                mpn: '',
                description: initialLineItem.description,
                referenceDesignator: initialLineItem.referenceDesignator,
                isAdd: true,
                isDelete: true,
                isEdit: false,
                isAlternateAlreadyExist: false,
                isExistingLineItem: false,
            },
        ]);
    }

    const removeAlternate = (partNumberId, event) => {
        if (partNumberList.length > 1) {
            let updatedPartNumberList = [];
            if (partNumberList.length == 2) {
                let index = partNumberList.findIndex(x => x.partNumberId == partNumberId);
                partNumberList[index]["isEdit"] = false;
                partNumberList[index]["manufacturer"] = '';
                partNumberList[index]["mpn"] = '';
                partNumberList[index]["isExistingLineItem"] = false;
                partNumberList[index]["isAlternateAlreadyExist"] = false;
                SetPartNumberList(partNumberList);
            }
            else {
                updatedPartNumberList = partNumberList.filter(x => x.partNumberId !== partNumberId);
                SetPartNumberList(updatedPartNumberList);
            }
        }
        let name = event.target.name;
        clearErrors([name]);
    }

    const handleChanged = (index, event) => {
        let copiedPartNumberList = [...partNumberList];
        let value = event.target.value;
        let name = event.target.name;
        trigger(name);
        let propertyName = name.split("_")[0];
        copiedPartNumberList[index][propertyName] = value;
        SetPartNumberList(copiedPartNumberList);
    }

    const handleBlured = (index) => {
        let copiedPartNumberList = [...partNumberList];
        let mfgr = copiedPartNumberList[index]["manufacturer"];
        let mpn = copiedPartNumberList[index]["mpn"];

        if (mpn) {
            let isPartNumberExist = copiedPartNumberList.filter(x => x.manufacturer.toLowerCase().trim() == mfgr.toLowerCase().trim() && x.mpn.toLowerCase().trim() == mpn.toLowerCase().trim());
            if (isPartNumberExist.length > 1) {
                Swal.fire({
                    text: MESSAGE_CONSTANT.alternates.duplicate.toString().formatGeneric(mpn),
                    icon: 'error'
                })
                copiedPartNumberList[index]["mpn"] = "";
                SetPartNumberList(copiedPartNumberList);
                return;
            }
        }
    }

    const editAlternate = (index) => {
        let copiedPartNumberList = [...partNumberList];
        copiedPartNumberList[index].isExistingLineItem = false;
        copiedPartNumberList[index].isAlternateAlreadyExist = false;
        SetPartNumberList(copiedPartNumberList);
    }

    const saveAlternates = (partNumberList) => {
        trigger().then((result) => {
            if (result) {
                let maxPartNumberId = max(partNumberList, function (o) { return o.partNumberId; }).partNumberId;
                partNumberList.map((data, index) => {
                    data.isExistingLineItem = !data.isAlternateAlreadyExist ? true : data.isExistingLineItem;
                    data.isEdit = !data.isAlternateAlreadyExist ? true : data.isEdit;
                    data.isAdd = (data.partNumberId == maxPartNumberId && data.isAlternateAlreadyExist && index != 0) ? true : data.isAdd;
                    return data;
                });
                handleAlternates(partNumberList, lineItem);
                handleClose();
            }
        });
    }

    return (
        isLoad && <>
            <Row className="col-lg-12 col-md-12 col-sm-12 mt-1">
                <Modal show={isShow} onHide={modalClose} id="cm-modal">
                    <Modal.Header id="cm-modal-header" closeButton>
                        <Modal.Title className="cm-modal-title">Add Alternate(s)</Modal.Title>
                    </Modal.Header>
                    <Modal.Body id="cm-modal-body">
                        <br></br>
                        <div className="cm-alternate">
                            <Table striped bordered responsive cellPadding="0" cellSpacing="0" id="cm-table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Line</th>
                                        <th>Manufacturer</th>
                                        <th>MPN</th>
                                        <th>Description</th>
                                        <th>Reference Designator</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        partNumberList.map((data, index) =>
                                            <tr key={index}>
                                                <td className="cm-action-width">
                                                    {data.isAdd && <span className="cm-icon"><FcPlus size={18} className="cm-fa-add" onClick={() => addAlternate()} title="Add alternate" /></span>}
                                                    {data.isExistingLineItem && data.isEdit && <span className="cm-icon"><FaRegEdit size={18} className="cm-fa-edit" onClick={() => editAlternate(index)} title="Edit alternate" /></span>}
                                                    {data.isDelete && <span className="cm-icon"><FaTrash size={16} className="cm-fa-trash " onClick={(event) => removeAlternate(data.partNumberId, event)} title="Delete Alternate" /></span>}
                                                </td>
                                                <td name={`lineItem_${index}`}>{data.lineItem}</td>
                                                {data.isExistingLineItem ? (<td className="cm-width">{data.manufacturer}</td>) : (
                                                    <td className="cm_width">
                                                        <InputTextBox
                                                            name={`manufacturer_${index}`}
                                                            type="text"
                                                            className="form-control"
                                                            value={data.manufacturer}
                                                            maxLength="50"
                                                            onChange={(event) => handleChanged(index, event)}
                                                            onBlur={() => handleBlured(index)}
                                                        />
                                                    </td>)
                                                }
                                                {data.isExistingLineItem ? (<td className="cm-width">{data.mpn}</td>) : (<td className="cm_width">
                                                    <InputTextBox
                                                        name={`mpn_${index}`}
                                                        type="text"
                                                        className="form-control"
                                                        value={data.mpn}
                                                        maxLength="50"
                                                        onChange={(event) => handleChanged(index, event)}
                                                        onBlur={() => handleBlured(index)}
                                                        ref={register({
                                                            required: MESSAGE_CONSTANT.validation.required
                                                                .toString()
                                                                .formatGeneric("MPN"),
                                                        })}
                                                        errorMessage={errors}
                                                    />
                                                </td>)}
                                                <td className="cm-fix-width" title={data.description}>{data.description}</td>
                                                <td className="cm-fix-width" title={data.description}>{data.referenceDesignator}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table >
                        </div>
                    </Modal.Body>
                    <Modal.Footer id="cm-modal-footer">
                        <PrimaryOrangeButton
                            name="Save"
                            handleClick={() => saveAlternates(partNumberList)} />
                    </Modal.Footer>
                </Modal>
            </Row>
        </>
    )
}
export default AddAlternates;