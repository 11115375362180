import { CommonHttpService } from "./CommonHttpService";
import { URL_CONSTANT } from '../constants/UrlConstant';

export const OrderService = {

    GetOrder: (assemblyId) => {
        const url = `${URL_CONSTANT.Checkout.GetOrder}?assemblyId=${assemblyId}`;
        return CommonHttpService.httpGet(url);
    },

    SaveOrder: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Checkout.SaveOrder, data);
    },

    GetBomSummary: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Pricing.GetBomSummary, data);
    },

    CreatePaymentIntent:(data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Checkout.CreatePaymentIntent, data);
    },

    GetShippingMethods: () => {
        const url = `${URL_CONSTANT.Checkout.GetShippingMethods}`;
        return CommonHttpService.httpGet(url);
    },

    GetDiscountFromPrmocode: (promoCode) => {
        const url = `${URL_CONSTANT.Checkout.GetDiscountFromPromoCode}?promoCode=${promoCode}`;
        return CommonHttpService.httpGet(url);
    },

    UpdatePaymentDetails: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Checkout.UpdatePaymentDetails, data);
    },

    GetPayTraceClientkey: () => {
        const url = `${URL_CONSTANT.Checkout.GetPayTraceClientkey}`;
        return CommonHttpService.httpGet(url);
    },

    GetPcbDetails: (assemblyId) => {
        const url = `${URL_CONSTANT.PCB.GetSavedPCBAnswer}?assemblyId=${assemblyId}`;
        return CommonHttpService.httpGet(url);
    },

    CalculateTax: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Checkout.CalculateTax, data);
    },
}