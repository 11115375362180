import React from "react";
import './EmailActivationPending.css';
import { FaClock } from "react-icons/fa";
const EmailActivationPendingPage = () => {

    return (
        <div id="content" className="container div-account-created">
            <div className="col-xs-12">
                <div className="text-center"><h1><FaClock color={'black'} /><FaClock /></h1></div>
                <div className="text-center"><h1 className="heading-large">Account Activation Pending!</h1></div>
                <div className="text-center padding-top-75">
                    <h3>Please check your email and activate your account. We have sent you an activation link.</h3>
                </div>
                <div className="text-center Divider"></div>
            </div>
        </div>
    );
};

export default EmailActivationPendingPage;
