import React from 'react';
import { Row, Col, Accordion, Card } from "react-bootstrap";
import FaqToggle from "../FormatBom/FaqToggle";
const PcbDetails = (pcbInfo) => {

    return (
        <>{
            pcbInfo && pcbInfo.pcbInfo && pcbInfo.pcbInfo.length > 0 &&
            <>
                <Row>
                    <Col md={12} sm={12} xs={12} className="text-white">
                        <h5>PCB Details</h5>
                    </Col>
                </Row>
                <>{
                    pcbInfo.pcbInfo.map((pcb, index) => {
                        let props = Object.keys(pcb);
                        return (
                            <Accordion key={index} className="mt-2">
                                <Card>
                                    <Card.Header>
                                        <FaqToggle className="cursor-pointer mt-3" eventKey={index.toString()}>
                                            <h6 className="LeadPara mb-0 mt-1">{pcb["mpn"]}</h6>
                                        </FaqToggle>
                                    </Card.Header>
                                    <Accordion.Collapse key={index} eventKey={index.toString()}>
                                        <Card.Body>
                                            {
                                                props.map((prop, rowIndex) => {
                                                    return (
                                                        <Row key={rowIndex}>
                                                            <Col md={6} sm={12} xs={12}>
                                                                {pcb[prop].displayName}
                                                            </Col>
                                                            <Col md={6} sm={12} xs={12} className="text-md-right">
                                                                {pcb[prop].value} {pcb[prop].unit}
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        )

                    })
                }
                </>
                <hr />
            </>
        }
        </>
    )
}

export default PcbDetails