import { SET_PRICING_RUNNING_STATUS, CLEAR_PRICING_RUNNING_STATUS } from '../../constants/ActionConstants';

const InitialState = {};
export const PricingRunningStatus = (state = InitialState, action) => {
    const { type, data } = action;
    switch (type) {
        case SET_PRICING_RUNNING_STATUS:
            return { ...state, pricingRunningStatus: data.pricingRunningStatus };
        case CLEAR_PRICING_RUNNING_STATUS:
            return {};
        default:
            return state;
    }
}