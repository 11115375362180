import React from "react";
import { BiCart, BiPlusCircle, BiXCircle } from "react-icons/bi";
import "./InputButton.css";
import { Button } from "react-bootstrap";

export const RemoveButton = (props) => {
    return (
        <BiXCircle
            className={"btn-remove " + props.className}
            onClick={props.handleClick}
        />
    );
};

export const AddButton = (props) => {
    return (
        <BiPlusCircle
            className={"btn-add " + props.className}
            onClick={props.handleClick}
        />
    );
};

export const PrimaryOrangeButton = ({ handleClick, ...props }) => {
    return (
        <Button className="btn-primary-orange" onClick={handleClick} {...props}>
            {props.name}
        </Button>
    );
};

export const PrimaryBlueButton = ({ handleClick, ...props }) => {
    return (
        <Button className="btn-primary-blue" onClick={handleClick} {...props}>
            {props.name}
        </Button>
    );
};

export const LinkButton = (props) => {
    return (
        <Button variant="link" onClick={props.handleClick}>
            {props.name}
        </Button>
    );
};
export const PrimaryGreyButton = (props) => {
    let isActive = props.isActive == true ? "isActive" : " ";
    return (
        <Button
            className={"btn-primary-grey " + isActive}
            onClick={props.handleClick}
        >
            {props.name}
        </Button>
    );
};

export const ShoppingCartButton = (props) => {
    return (
        <BiCart
            className="float-right cm-cart-icon"
            key={props.rowKey}
            onClick={props.handleClick}
        />
    );
};
