import React from 'react';
import "./Icon.css"

const Icon = ({ name, displayName }) => {
    if (name.toLowerCase().indexOf('icon-fo') > -1) {
        var cssClassNames = name.split(" ").filter(l => l !== '');
        const insertedIcons = cssClassNames.map((css, index) => (
            <i className={'fontello ' + css} key={displayName + '' + index}>
                {index > 0 && <span className='separator'> +</span>}
            </i>
        ));
        return insertedIcons;
    }
    return <></>
}
export default Icon; 