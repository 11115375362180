import { CommonHttpService } from "./CommonHttpService";
import { URL_CONSTANT } from '../constants/UrlConstant';

export const PricingService = {
    CheckPricingStatus: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.CheckPricingStatus.CheckPricingStatus, data);
    },
    getRequestedQuantity: (assemblyId) => {
        const url = `${URL_CONSTANT.Pricing.GetRequestedQuantity}?assemblyId=${assemblyId}`;
        return CommonHttpService.httpGet(url);
    },
    getPurchaseData: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Pricing.GetPurchaseData, data);
    },
    updateMethodOfSupply: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Pricing.UpdateMethodOfSupply, data);
    },
    upsertMethodOfSupplySelections: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Pricing.UpsertMethodOfSupplySelections, data);
    },
    addAlternateParts: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Pricing.AddAlternateParts, data);
    },
    refreshPricing: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Pricing.RefreshPricing, data);
    }
}