import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';

export const QuotePriceService = {

    markPriceAsSelected: (data) => {
        const url = `${URL_CONSTANT.QuotePrice.MarkPriceAsSelected}`;
        return CommonHttpService.httpPost(url, data);
    },

    getSelectedPrice: (assemblyId) => {
        const url = `${URL_CONSTANT.QuotePrice.GetSelectedPrice}?assemblyId=${assemblyId}`;
        return CommonHttpService.httpGet(url);
    },

}