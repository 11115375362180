import { createUserManager } from 'redux-oidc';
const config = {
    authority: process.env.REACT_APP_IDENTITY_SERVER_URL,
    client_id: process.env.REACT_APP_CLIENTID,
    redirect_uri: `${`${process.env.REACT_APP_CLIENTURL}`}/callback.html`,
    response_type: 'code',
    response_mode: 'query',
    scope: `${process.env.REACT_APP_SCOPE}`,
    silent_redirect_uri: `${`${process.env.REACT_APP_CLIENTURL}`}/silent_renew.html`,
    automaticSilentRenew: true,
    revokeAccessTokenOnSignout: true,
    post_logout_redirect_uri: `${process.env.REACT_APP_CLIENTURL}/`,
    accessTokenExpiringNotificationTime: `${process.env.REACT_APP_TOKEN_EXPIRING_NOTIFICATION_TIME}` // seconds
};
const UserManager = createUserManager(config);
export default UserManager;