import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';

export const ConfigurationService = {

    getByKeys: (configKeys) => {
        return CommonHttpService.httpPost(URL_CONSTANT.Configuration.GetConfigurations, configKeys);
    },
    isBomAnalysisEnabled: () => {
        return CommonHttpService.httpGet(URL_CONSTANT.Configuration.IsBomAnalysisEnabled, null);
    }
}