import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DataGrid from "../../components/Common/AntDataGrid/DataGrid"
import { SummmaryService } from '../../services/SummaryService';
import { groupBy } from 'underscore';
import './GetQuote.css'
import CurrencyFormat from '../../components/Common/CurrencyFormat/CurrencyFormat'
import { DATA_CONSTANT } from '../../constants/DataConstant';
import { setCompletedSteps } from '../../redux/actions/CompletedStepActions';
import { useHistory } from 'react-router-dom';
import { ShoppingCartButton } from '../../components/Common/InputButton/InputButton';
import { trackPromise } from 'react-promise-tracker';
import { QuotePriceService } from '../../services/QuotePriceService';
import { DynamicLabelKey } from '../../constants/DynamicLabelKeyConstant';

let groupByData = [];

const GetQuote = ({ assemblyId, selectedJobType, salesPersonId, onStepCompleted }) => {

    const history = useHistory();

    const [pageSize, setPageSize] = useState(100);
    const [data, setData] = useState({});
    const [dynamicLabels, setDynamicLabels] = useState({});

    useEffect(() => {
        trackPromise(
            getSummaryDetails()
        );
    }, [])

    const getSummaryDetails = async () => {
        let userId = salesPersonId;
        const isPCB = selectedJobType?.isPcbRequired;
        const isAssembly = selectedJobType?.isAssemblyRequired;
        const isChargeSupplierShippingCost = selectedJobType?.isPcbRequired || selectedJobType?.isComponentsRequired;

        await SummmaryService.getSummaryDetails(assemblyId, userId, isPCB, isAssembly, isChargeSupplierShippingCost).then((data) => {
            if (data != null) {
                groupByData = groupBy(data.listSummaryDetails, function (data) { return data.quantityId })
                setData(data.listSummaryDetails)
                setDynamicLabels(data.listDynamicLabel);
            }
        })
    };

    const markPriceAsSelected = async (rowData) => {
        const reqestData = {
            AssemblyId: rowData.assemblyId,
            QuantityId: rowData.quantityId,
            TurntimeId: rowData.turntimeId
        };
        await QuotePriceService.markPriceAsSelected(reqestData).then((data) => {

            onStepCompleted();

            let nextStepIndex = DATA_CONSTANT.rfqSteps.findIndex(x => x.stepName === DATA_CONSTANT.stepNames.GetQuote) + 1;
            history.push(DATA_CONSTANT.rfqSteps[nextStepIndex].routeName);
        })
    };

    const handleShoppingCartClicked = (rowData) => {
        trackPromise(
            markPriceAsSelected(rowData)
        );
    }
    const columns = [
        {
            dataIndex: DynamicLabelKey.Quantity,
            width: 130,
            fixed: 'left',
            sorter: false,
            render: (value, row, index) => {

                let dataLenght = groupByData[row.quantityId].length;
                let dataIndex = data.findIndex(x => x.quantityId === row.quantityId);

                const obj = {
                    children: row.quantity,
                    props: {},
                };
                if (dataLenght > 0 && index == dataIndex) {
                    obj.props.rowSpan = dataLenght;

                } else if (dataLenght > 1 &&
                    ((index >= (dataIndex + 1)) &&
                        (index <= dataIndex + (dataLenght - 1)))) {
                    obj.props.rowSpan = 0;
                }
                return obj;
            }
        },
        {
            dataIndex: DynamicLabelKey.ShipDate,
            width: 130,
            sorter: false,
        },
        {
            dataIndex: DynamicLabelKey.AssemblyCost,
            width: 130,
            sorter: false,
            render: (text) => {
                return <CurrencyFormat value={text} />
            }
        },
        {
            dataIndex: DynamicLabelKey.AdditionalServices,
            width: 130,
            sorter: false,
            render: (text) => {
                return <CurrencyFormat value={text} />
            }
        },
        {
            dataIndex: DynamicLabelKey.Pcb,
            width: 130,
            sorter: false,
            render: (text) => {
                return <CurrencyFormat value={text} />
            }
        },
        {
            dataIndex: DynamicLabelKey.TotalPrice,
            width: 130,
            key: 'TotalPrice',
            sorter: false,
            className: "font-weight-bold",
            render: (text, row, rowKey) => {
                return (
                    <>
                        <CurrencyFormat value={text} />
                        <ShoppingCartButton rowKey={rowKey} handleClick={() => handleShoppingCartClicked(row)} />
                    </>
                )

            }
        },
    ];

    return (
        <div>
            <h2>Here is the quote</h2>
            <br />
            {data && data.length > 0 &&
                <DataGrid
                    rowKey={record => `${record.quantityId}_${record.turntimeId}`} //unique row Id
                    columns={columns}
                    dataSource={data}
                    dynamicLabels={dynamicLabels}
                    pagination={{
                        pageSize: pageSize,
                        hideOnSinglePage: true,
                        onChange: (page, pageSize) => {
                            setPageSize(pageSize);
                        }
                    }}
                    scroll={{ y: 330 }}
                    className="cm-tbl-hover cm-tbl-gq"
                >
                </DataGrid>
            }
        </div >
    )

}

const mapStateToProps = (state) => ({
    assemblyId: state.RFQDetails?.rfqDetails?.assemblyId,
    selectedJobType: state.JobTypes?.selectedJobType,
    salesPersonId: state.DefaultConfigs.defaultConfigs?.salesPersonId
});

const mapDispatchToProps = dispatch => ({
    onStepCompleted: () => {
        dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.GetQuote));
        //dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.Completed));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(GetQuote)
