import React, { useRef } from "react";
import Table from 'antd/es/table';
import Space from 'antd/es/space';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import { SearchOutlined } from '@ant-design/icons';
import DatePicker from 'antd/es/date-picker';
import moment from "moment";
import PropTypes from 'prop-types';
import './DataGrid.css';

const DataGrid = ({ columns, customFilterIcon, pagination, ...otherTableProps }) => {

    let searchInput = useRef();

    //Set Dynamic Column header
    if (otherTableProps.dynamicLabels?.length > 0) {
        columns.map((data) => {
            data.title = otherTableProps.dynamicLabels.find(x => x.key.trim().toLowerCase() === data.dataIndex.trim().toLowerCase())?.label;
        });
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        /* 
         * require when managing server side filtering
             setSearch({
               searchText: [selectedKeys],
               searchedColumn: dataIndex,
             });
         */
    };

    const handleReset = clearFilters => {
        clearFilters();
        //setSearch({ searchText: '' });
    };

    const defaultTimeFormat = 'HH:mm';
    const defaultDateFormat = 'YYYY/MM/DD HH:mm';

    const getCustomColumnSearchProps = (dataIndex, searchProps, customFilterIcon, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className="filter-dropdown-padding">
                {
                    searchProps.dataType === 'input-date' &&
                    <DatePicker
                        showNow={
                            searchProps.showNow ? searchProps.showNow : false
                        }
                        showTime={
                            searchProps.showTime ? searchProps.showTime.format : { format: defaultTimeFormat }
                        }
                        className="filter-dropdown-block"
                        format={
                            searchProps.dateFormat ? searchProps.dateFormat : defaultDateFormat
                        }
                        value={
                            selectedKeys[0] ? moment(selectedKeys[0]) : ""
                        }
                        ref={node => {
                            searchInput = node;
                        }}
                        onChange={
                            (value, stringVal) => setSelectedKeys(stringVal ? [stringVal.toString()] : [])
                        }
                        onPressEnter={
                            () => handleSearch(selectedKeys, confirm, dataIndex)
                        }
                    //   onOk={
                    //       () => handleSearch(selectedKeys, confirm, dataIndex)
                    //   }
                    />}
                {
                    searchProps.dataType !== 'input-date' &&
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        key={`${dataIndex}_searchBox`}
                        placeholder={`Search ${title}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        className="filter-dropdown-block"
                    />
                }
                <Space>
                    <Button
                        key={`${dataIndex}_resetBtn`}
                        onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        key={`${dataIndex}_searchBtn`}
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined className="search-icon" />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                </Space >
            </div >
        ),
        filterIcon: customFilterIcon ? customFilterIcon : filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            return (
                record[dataIndex]
                    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                    : '')
        },
        //onFilterDropdownVisibleChange: visible => {
        //     if (visible) {
        //         setTimeout(() => searchInput.select(), 100);
        //     }
        //},
        render: (text, record) => <span>{text}</span>,
    });

    const filterIcon = customFilterIcon ? customFilterIcon : filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />;

    const dataColumns = columns.map((column, index) => {

        const { sorter, dataIndex, customFilter, ...otherColumnProps } = column;

        const searchProps = customFilter ? getCustomColumnSearchProps(column.dataIndex, column.customFilter, filterIcon, column.title) : {};

        if (sorter) {

            const { compare, ...otherSorterProps } = sorter;

            return {
                dataIndex,
                filterIcon: filterIcon,
                ...searchProps,
                ...otherColumnProps,
                sorter: {
                    compare: (rowA, rowB) => compare(rowA[dataIndex], rowB[dataIndex]),
                    ...otherSorterProps
                }
            };
        }

        return {
            dataIndex,
            filterIcon: filterIcon,
            ...searchProps,
            ...otherColumnProps,
        };
    });


    if (pagination) {
        const customPagination =
        {
            pageSize: pagination.pageSize,
            hideOnSinglePage: pagination.hideOnSinglePage,
            pageSizeOptions: pagination.pageSizeOptions,
            showSizeChanger: pagination.showSizeChanger,
            onChange: pagination.onChange
        }
        return <Table columns={dataColumns} pagination={customPagination} className={`cm-scroll ${otherTableProps.className}`} {...otherTableProps} />;
    }
    else {
        return <Table columns={dataColumns} className={`cm-scroll ${otherTableProps.className}`} {...otherTableProps} />;
    }
};


DataGrid.propTypes = {
    columns: PropTypes.array.isRequired,
    dataSource: PropTypes.array.isRequired,
    customFilterIcon: PropTypes.any,
    scroll: PropTypes.object,
    bordered: PropTypes.bool,
    size: PropTypes.oneOf(["default", "middle", "small"]),
    pagination: PropTypes.object
};

DataGrid.defaultProps = {
    bordered: true,
    scroll: false,
    size: "default"
}

export default DataGrid;
