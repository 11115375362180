import React, { useState, useEffect } from "react";
import '../../components/PricingCompilingScreen/PricingCompilingScreen.css'
import { ProgressBar, Row, Col, } from 'react-bootstrap';
import AnimatedPricingGif from '../../components/PricingCompilingScreen/AnimatedPricingGif.gif';
import { BOMService } from "../../services/BOMService";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { DATA_CONSTANT } from "../../constants/DataConstant";

const ScrubMyBomProcessPage = ({ rfqDetails, selectedJobType }) => {

    const delay = 5000;

    const history = useHistory();

    const [scrubMyBomStatus, setScrubMyBomStatus] = useState({
        isCompleted: false, completedPercentage: 100
    });

    const [customInterval, setCustomInterval] = useState(0);

    useEffect(() => {

        if (!selectedJobType?.isBomRequired) {
            stepCompleted();
            return;
        }

        checkScrubMyBomStatus();

        let interval = setInterval(() => {
            checkScrubMyBomStatus();
        }, delay);

        setCustomInterval(interval);

    }, []);

    useEffect(() => {

        if (scrubMyBomStatus.isCompleted) {
            clearInterval(customInterval);
            stepCompleted();
        }

    }, [scrubMyBomStatus]);

    const checkScrubMyBomStatus = () => {

        BOMService.isScrubMyBomRunning(rfqDetails?.assemblyId)
            .then((isRunning) => {

                setScrubMyBomStatus({
                    isCompleted: !isRunning,
                    completedPercentage: 100
                });
            });
    };

    const stepCompleted = () => {
        let nextStepIndex = DATA_CONSTANT.rfqSteps.findIndex(x => x.stepName === DATA_CONSTANT.stepNames.AssemblyDetail) + 1;
        history.push(DATA_CONSTANT.rfqSteps[nextStepIndex].routeName);
    };

    return (
        <>
            <Row>
                <Col md={8} sm={6} xs={6}>
                    <h2>BOM Scrubbing...</h2>
                    <p>This only takes a few seconds. Hang in there while we scrub your BOM!</p>
                </Col>
                <Col md={4} sm={6} xs={6}>
                    <img src={AnimatedPricingGif} className="cm-animatedgif" />
                </Col>
            </Row>
            <Row>
                <Col md={12} sm={12} xs={12}>
                    {
                        <ProgressBar now={scrubMyBomStatus.completedPercentage} striped animated />
                    }
                </Col>
            </Row>
            <Row>
                <Col md={12} sm={12} className="cm-marketing-content">
                    <p className="cm-marketing-text m-3">
                        Did you know … Advanced Assembly was the first quick-turn, prototype PCB assembly provider?
                        Trust us with your design. We have the experience to assemble it right and on-time, every time.
                    </p>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = state => ({
    rfqDetails: state.RFQDetails?.rfqDetails,
    selectedJobType: state.JobTypes?.selectedJobType
});

export default connect(mapStateToProps, null)(ScrubMyBomProcessPage);
