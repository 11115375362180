import { CLEAR_STORE, SET_DEFAULT_CONFIGS } from '../../constants/ActionConstants';

const InitialState = {};

export const DefaultConfigs = (state = InitialState, action) => {
    const { type, data } = action;

    switch (type) {
        case SET_DEFAULT_CONFIGS:
            return { ...state, defaultConfigs: data.defaultConfigs };
        case CLEAR_STORE:
            return InitialState;
        default:
            return state;
    }
}