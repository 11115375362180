import React, { useEffect, useState, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { MESSAGE_CONSTANT } from "../../constants/MessageConstant";
import { LaborService } from "../../services/LaborService";
import CheckListBuilder from "../../components/Common/CheckListBuilder";
import { each } from "underscore";
import { CommonService } from "./../../services/CommonService";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import InputTextBox from "../ControlComponents/Input/InputTextBox";

const LaborQuestionList = (props) => {
    const history = useHistory();

    const { register, errors, trigger, reset } = useForm();

    const [laborQuestions, setLaborQuestions] = useState([]);

    const [laborDrivers, setLaborDrivers] = useState([]);

    const [uniqueLaborQuestions, setUniqueLaborQuestions] = useState([]);

    const [isLaborQuestionsLoaded, setIsLaborQuestionsLoaded] = useState(false);

    const [checkListBuilderDatas, setCheckListBuilderDatas] = useState([]);

    const [isLoaded, setIsLoaded] = useState(false);

    const [isChecklistFieldsExists, setIsChecklistFieldsExists] = useState(true);

    const [isOnlyReadonlyQuestions, setIsOnlyReadonlyQuestions] = useState(false);

    const [isValidChild, setIsValidChild] = useState(false);

    const [totalPlacementValidation, setTotalPlacementValidation] = useState("");

    const getLaborQuestions = async (refAssemblyId, assemblyId) => {
        await LaborService.getLaborQuestions(refAssemblyId, assemblyId)
            .then((data) => {
                if (data) {
                    setLaborQuestions(data.laborDetails);
                    setLaborDrivers(data.laborDrivers);
                    setUniqueLaborQuestions(
                        data.laborDetails.filter(
                            (ele, ind) => ind === data.laborDetails.findIndex((elem) => elem.driverName.trim().toUpperCase() === ele.driverName.trim().toUpperCase())
                        )
                    );
                    setIsLoaded(true);
                }

                setIsLaborQuestionsLoaded(true);
            })
    };

    useEffect(() => {
        if (!props.defaultConfigs || !props.assemblyId) {
            history.push(DATA_CONSTANT.routes.rfq);
            return;
        }
        if (props.defaultConfigs.laborAssemblyId != null) {
            trackPromise(getLaborQuestions(props.defaultConfigs.laborAssemblyId, props.assemblyId));
        }
    }, [props.defaultConfigs?.laborAssemblyId, isLoaded]);

    useEffect(() => {
        if (!isChecklistFieldsExists) {
            if (uniqueLaborQuestions.length < 1) {
                props.stepCompleted();
                return;
            }

            let isLaborQuestionsExists = uniqueLaborQuestions.some(
                (l) => l.isDriverCountReadOnly === false
            );
            // check only readonly questions exists
            if (!isLaborQuestionsExists && uniqueLaborQuestions.length > 0) {
                setIsOnlyReadonlyQuestions(true);
            }
        }
    }, [isChecklistFieldsExists]);

    const isValidChildCheck = useCallback((result) => {
        if (result) {
            setIsValidChild(result);
        } else {
            checkValidation();
        }
        props.onValidationReset()
    });

    useEffect(() => {
        checkValidation();
    }, [trigger, props.validateForm]);

    useEffect(() => {
        if (totalPlacementValidation) {
            trigger(totalPlacementValidation)
        }
    }, [laborQuestions])

    const checkValidation = useCallback(() => {
        trigger().then(function (result) {
            if (result && isValidChild) {
                let laborDetails = {
                    LaborAssemblyId: props.defaultConfigs.laborAssemblyId,
                    AssemblyId: props.assemblyId,
                    SalesPersonId: props.defaultConfigs.salesPersonId,
                    LaborQuestions: [...laborQuestions],
                    CheckListBuilderData: [...checkListBuilderDatas],
                    BomId: props?.bomId,
                };
                trackPromise(props.submitLaborAnswers(laborDetails));
            } else {
                setIsValidChild(false);
            }
        });
    });

    const handleAnswerChanged = (index, event) => {

        let SMTDriverCount = 0;
        let THDriverCount = 0;
        let BGADriverCount = 0;

        setTotalPlacementValidation("");
        trigger(event.target.name);

        const questionList = [...uniqueLaborQuestions];
        questionList[index].answer = event.target.value;
        const laborQuestionList = [...laborQuestions];

        let isTotalPlacementExist = laborQuestionList.some(x => x.driverName.trim().toUpperCase() === laborDrivers.TotalPlacementDriverName)

        laborQuestionList.map((val, ind) => {
            if (val.driverName == questionList[index].driverName) {
                val.answer = event.target.value;
            }
            if (isTotalPlacementExist) {
                if (val.driverName.trim().toUpperCase() === laborDrivers.SMTPlacementDriverName) {
                    SMTDriverCount = (val.answer != "") ? (!isNaN(parseFloat(val.answer)) ? parseFloat(val.answer) : 0) : 0;
                }
                else if (val.driverName.trim().toUpperCase() === laborDrivers.THPlacementDriverName) {
                    THDriverCount = (val.answer != "") ? (!isNaN(parseFloat(val.answer)) ? parseFloat(val.answer) : 0) : 0;
                }
                else if (val.driverName.trim().toUpperCase() === laborDrivers.BGAPlacementDriverName) {
                    BGADriverCount = (val.answer != "") ? (!isNaN(parseFloat(val.answer)) ? parseFloat(val.answer) : 0) : 0;
                }
            }
        });

        if (isTotalPlacementExist) {
            let changedDriverName = questionList[index].driverName.trim().toUpperCase();
            if (changedDriverName === laborDrivers.SMTPlacementDriverName || changedDriverName === laborDrivers.THPlacementDriverName || changedDriverName === laborDrivers.BGAPlacementDriverName) {
                let totalPlacement = calculateTotalPlacement(SMTDriverCount, THDriverCount, BGADriverCount);
                let totalPlacementDrivers = laborQuestionList.filter(x => x.driverName.trim().toUpperCase() == laborDrivers.TotalPlacementDriverName);
                totalPlacementDrivers.map((driver) => {
                    driver.answer = totalPlacement.toString();
                });
                //laborQuestionList.filter(x => x.driverName.trim().toUpperCase() == laborDrivers.TotalPlacementDriverName)[0].answer = totalPlacement.toString();

                let noOfTotalPlacementIndex = laborQuestionList.findIndex(x => x.driverName.trim().toUpperCase() === laborDrivers.TotalPlacementDriverName)
                let noOfTotalPlacementName = "question" + noOfTotalPlacementIndex;
                setTotalPlacementValidation(noOfTotalPlacementName);
            }
        }
        setLaborQuestions(laborQuestionList);
        setUniqueLaborQuestions(questionList);

    };

    const calculateTotalPlacement = (SMTDriverCount, THDriverCount, BGADriverCount) => {
        return parseFloat(SMTDriverCount + THDriverCount + BGADriverCount).toFixed(2);
    }

    const onChangeCheckListBuilderData = (val) => {
        let values = CommonService.deepShallowCopy(val);
        if (values.length > 0) {
            each(values, function (value) {
                if (
                    (value.fieldType == "CheckBox" || value.fieldType == "DropDown") &&
                    value.defaultValue != null
                ) {
                    if (Array.isArray(value.defaultValue)) {
                        value.defaultValue = value.defaultValue.join(",");
                    }
                } else if (value.defaultValue == null) {
                    value.defaultValue = "";
                }
            });
            setCheckListBuilderDatas(values);
        }
    };

    return (
        <>
            {uniqueLaborQuestions.map(
                (objQuestion, questionIndex) =>
                    !objQuestion.isDriverCountReadOnly && (
                        <div key={questionIndex} className="cm-input-container">
                            <Row>
                                <Col md={11} sm={12} xs={12} lg={11}>
                                    <InputTextBox
                                        label={objQuestion.driverName}
                                        name={"question" + questionIndex}
                                        value={objQuestion.answer}
                                        isRequired={true}
                                        disabled={objQuestion.isReadOnly}
                                        helpText={objQuestion.description}
                                        errorMessage={errors}
                                        onChange={(event) =>
                                            handleAnswerChanged(questionIndex, event)
                                        }
                                        ref={
                                            register({
                                                required: MESSAGE_CONSTANT.labor.laborQuestion.required,
                                                pattern: {
                                                    value: DATA_CONSTANT.patterns.laborQuestion,
                                                    message: MESSAGE_CONSTANT.labor.laborQuestion.invalid,
                                                },
                                            })}
                                    ></InputTextBox>
                                </Col>
                            </Row>
                        </div>
                    )
            )}
            {isLaborQuestionsLoaded && isOnlyReadonlyQuestions && (
                <div className="mt-3">
                    <Row>
                        <Col md={11} sm={12} xs={12}>
                            <h5>{MESSAGE_CONSTANT.labor.onlyReadonlyQuestions}</h5>
                        </Col>
                    </Row>
                </div>
            )}
            {isLoaded && (
                <Row>
                    <Col md={11} sm={12} xs={12}>
                        <CheckListBuilder
                            JobTypeName={props.selectedJobType}
                            ScreenName="Labor"
                            onChangeCheckListBuilder={onChangeCheckListBuilderData}
                            setIsChecklistFieldsExists={setIsChecklistFieldsExists}
                            isValidChildCheck={isValidChildCheck}
                            validateForm={props.validateForm}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    selectedJobType: state.JobTypes?.selectedJobType,
    defaultConfigs: state.DefaultConfigs?.defaultConfigs,
    assemblyId: state.RFQDetails?.rfqDetails?.assemblyId,
    bomId: state.BomIds?.BomId,
});

export default connect(mapStateToProps, null)(LaborQuestionList);
