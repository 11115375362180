import React from "react";
import UserManager from "../../constants/UserManager";

const SilentRenewalCallback = () => {
    UserManager.signinSilentCallback()
        .then(function (o) { })
    return (
        <div></div>
    );
};

export default SilentRenewalCallback;
