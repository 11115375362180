import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';

export const RequestFormService = {

    getDefaultConfigurations: (jobType) => {
        const url = `${URL_CONSTANT.RequestForm.GetDefaultConfigurations}?jobType=${jobType}`;
        return CommonHttpService.httpGet(url);
    },

    CreateRFQ: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.RequestForm.CreateRFQ, data);
    },

    GetCustomerContactByEmail: (email) => {
        const url = `${URL_CONSTANT.RequestForm.GetCustomerContactByEmail}?email=${email}`;
        return CommonHttpService.httpGet(url);
    },

    UpdateAssemblyFieldValue: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.RequestForm.UpdateAssemblyFieldValue, data);
    },
    getRFQSteps: (jobTypeName) => {
        const url = `${URL_CONSTANT.RequestForm.GetRFQSteps}`;
        const params = { jobType: jobTypeName };
        return CommonHttpService.httpGet(url, params);
    }
}