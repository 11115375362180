import { CommonHttpService } from "./CommonHttpService";
import { URL_CONSTANT } from '../constants/UrlConstant';

export const PCBService = {

    getPCBQuestions: () => {
        return CommonHttpService.httpGet(URL_CONSTANT.PCB.GetPcbQuestions);
    },
    submitPCBAnswers: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.PCB.SubmitPCBAnswers, data);
    },
    submitPCBWithPricing: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.PCB.SubmitPCBWithPricing, data);
    }
}