import React from "react";
import PropTypes from "prop-types";
import { FormLabel, FormControl, InputGroup } from "react-bootstrap";
import InfoToolTip from "../InfoToolTip";
import moment from "moment";

const InputTextBox = React.forwardRef(
  (
    {
      id,
      name,
      value,
      onChange,
      isRequired, // Displays * for required field
      errorMessage,
      type,
      label,
      postLabel,
      helpText, // Displays information tooltip
      helpPlacement, // Placement for tooltip
      className, //Classname for tooltip
      ...props
    },
    ref
  ) => {
    return (
      <>
        {label && label.trim() && (
          <>
            <FormLabel> {label} </FormLabel>
            {isRequired && <span className="error-txt">*</span>}
            {helpText && helpText.trim() && (
              <InfoToolTip
                className={className}
                helpText={helpText}
                helpPlacement={helpPlacement}
              />
            )}
          </>
        )}
         <InputGroup>
             <FormControl
                    id={id}
                    name={name}
                    value={
                        type == "date"
                            ? moment(value, "MM/DD/YYYY").format("YYYY-MM-DD")
                            : value
                    }
                    type={type}
                    onChange={onChange}
                    ref={ref}
                    {...props}
                />
            {postLabel &&
                 <InputGroup.Append>
                     <InputGroup.Text id={id}>{postLabel}</InputGroup.Text>
                 </InputGroup.Append>
                }
         </InputGroup>
        
        {errorMessage && errorMessage[name] && errorMessage[name].message && (
          <span className="error-txt">{errorMessage[name].message}</span>
        )}
      </>
    );
  }
);

InputTextBox.defaultProps = {
  type: "text",
  className: "",
  value:
    PropTypes.type == "date" ? moment().format("MM/DD/YYYY") : PropTypes.value,
};

InputTextBox.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "number", "password", "email", "date"]),
  value: PropTypes.any,
  onChange: PropTypes.func,
  helpText: PropTypes.string,
  placement: PropTypes.oneOf(["right", "left", "top", "bottom"]),
  className: PropTypes.string,
};

export default InputTextBox;
