import {
  SET_DISABLED_STEPS,
  RESET_DISABLED_STEPS,
} from "../../constants/ActionConstants";

export const setDisabledSteps = (stepName) => ({
  type: SET_DISABLED_STEPS,
  data: { stepName: stepName },
});

export const resetDisabledSteps = () => ({
  type: RESET_DISABLED_STEPS,
  data: {},
});
