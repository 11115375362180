import React from "react";
import { connect } from "react-redux";
import { Nav } from 'react-bootstrap';
import { FaRegCheckCircle, FaRegCircle } from "react-icons/fa";
import _ from "underscore";

const RFQStep = ({ stepName, stepTitle, stepKey, disabledSteps, completedSteps }) => {
  const iconColor = "#1693fa";
  const DisableStep = (stepname) => {
    if (_.contains(disabledSteps, stepname))
    {
      return "DisableLink";
    } else return "";
  };

  return (
    <Nav.Link eventKey={stepKey} className={DisableStep(stepName)} disabled>
      {completedSteps.indexOf(stepName) > -1 ? (
        <FaRegCheckCircle color={iconColor} />
      ) : (
        <FaRegCircle />
      )}
      {` ${stepTitle}`}
    </Nav.Link>
  );
};

const mapStateToProps = (state) => ({
  completedSteps: state.CompletedSteps ? state.CompletedSteps : [],
  disabledSteps: state.DisabledSteps ? state.DisabledSteps : [],
});
export default connect(mapStateToProps)(RFQStep);
