import React, { useEffect} from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { DATA_CONSTANT } from '../constants/DataConstant';
import { OIDCService } from '../services/OIDCService';

const PrivateRoutes = ({
    component: Component,
    ...props
}) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (props.title) {
            document.title = `${props.title} | Advanced Assembly`;
        }
    });

    let isUserLoggedIn = false;
    const checkUserLoggedIn = () => {
        if (location.pathname.indexOf('callback') < 0) {
            isUserLoggedIn = OIDCService.login();
            if (isUserLoggedIn) {
                let hasAccessRights = OIDCService.hasUserAccessRights();
                if (hasAccessRights === false) {
                    history.push(DATA_CONSTANT.routes.error, 401);
                }
            }
        }
    }

    checkUserLoggedIn();

    return (
        isUserLoggedIn && <Route  {...props} render={(props) => (<Component {...props} />)} />
    )
}

export default PrivateRoutes;
