import React from 'react';
import { bomFormatDescriptionList } from './FormatBomDescriptionList';
import { Accordion } from 'react-bootstrap';
import Faq from './Faq';

const FormatBOM = () => {
    return (
        <Accordion>
            {
                Object.values(bomFormatDescriptionList).map((data, index) =>
                    data.map((data) =>
                        <Faq key={index}
                            eventKey={index.toString()}
                            title={data.title}
                            isTableFormat={data.isTableFormat}
                            description={data.description}
                        >
                        </Faq>
                    )
                )
            }
        </Accordion>
    )
}

export default FormatBOM;