import React from 'react';
import './RequestedQuantity.css';
import { Tabs, Tab } from 'react-bootstrap';
import RequestedQuantityTitle from './RequestedQuantityTitle';

const RequestedQuantity = ({ requestedQuantityData, handleRequestedQuantitychanged }) => {

    const getDatabyRequestedQuantity = (requestedQuantity) => {
        handleRequestedQuantitychanged(requestedQuantity);
    }

    return (
        <>
            <Tabs className="mt-2 mb-2 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {
                    requestedQuantityData.map((data, index) => {
                        return (
                            <Tab className="tabitem"
                                eventKey={data.quantityId}
                                title={<RequestedQuantityTitle requestedQuantityData={data.requestedQuantity} issuedRequestedQuantity={data.issue} />}
                                key={index}
                                onEnter={() => getDatabyRequestedQuantity(data.quantityId)}>
                                <div className="lineitem"></div>
                            </Tab>
                        )
                    })
                }
            </Tabs>
        </>
    )
}

export default RequestedQuantity;