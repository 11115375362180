import React, { useState, useEffect } from 'react';
import JobType from './JobType';
import { JobTypeService } from '../../services/JobTypeService';
import { Row, Col } from 'react-bootstrap';
import { chunk } from 'underscore';
import { trackPromise } from 'react-promise-tracker';
import { connect } from 'react-redux';
import { selectJobType } from '../../redux/actions/JobTypeActions';

const JobTypeList = ({ previousSelectedJobType, onJobTypeSelected }) => {

    const [jobTypes, setJobTypes] = useState([]);
    const [jobTypeSelection, setJobTypeSelection] = useState([]);
    const [selectedJobType, setSelectedJobType] = useState({ cqJobType: "", jobType: [], isvalid: false });
    const [selectedJobTypeDescription, setSelectedJobTypeDescription] = useState({ title: "", description: "" });

    const [isJobTypesloaded, setJobTypesloaded] = useState(false);

    let chunkSize = 3;
    const getJobTypes = async () => {
        await JobTypeService.getJobTypeList().then((data) => {
            if (data) {
                if (data.jobTypes && data.jobTypes.length > 0) {
                    let jobTypeDataByChunk = chunk(data.jobTypes, chunkSize);
                    setJobTypes(jobTypeDataByChunk);
                }
                if (data.jobTypeSelection && data.jobTypeSelection.length > 0) {
                    setJobTypeSelection(data.jobTypeSelection);
                }
                setJobTypesloaded(true);
            }
        })
    }

    useEffect(() => {
        trackPromise(
            getJobTypes(),
        );
    }, []
    );

    useEffect(() => {
        if (previousSelectedJobType && previousSelectedJobType.jobType && previousSelectedJobType.jobType.length > 0) {
            setSelectedJobType(previousSelectedJobType);
            getCQJobType(previousSelectedJobType.jobType);
        }
    }, [isJobTypesloaded]
    );

    const handleJobTypeSelected = jobTypeId => {

        let selectedJobTypeList = selectedJobType.jobType;
        if (selectedJobTypeList && selectedJobTypeList.indexOf(jobTypeId) > -1) {
            selectedJobTypeList.splice(selectedJobType.jobType.indexOf(jobTypeId), 1)
        } else {
            selectedJobTypeList.push(jobTypeId);
        }
        let cqJobType = getCQJobType(selectedJobTypeList);
        let cqJobTypeName = "";
        let jobTypeIsValid = false;
        let isBomRequired = false;
        let isPcbRequired = false;
        let isAssemblyRequired = false;
        let isComponentsRequired = false;

        if (cqJobType) {
            cqJobTypeName = cqJobType.quoteCQJobTypeName;
            jobTypeIsValid = cqJobType.isValid;
            isBomRequired = cqJobType.isBomRequired;
            isPcbRequired = cqJobType.isPcbRequired;
            isAssemblyRequired = cqJobType.isAssemblyRequired;
            isComponentsRequired = cqJobType.isComponentsRequired;
        }

        setSelectedJobType({ cqJobType: cqJobTypeName, jobType: [...selectedJobTypeList], isvalid: jobTypeIsValid, isBomRequired: isBomRequired, isPcbRequired: isPcbRequired, isAssemblyRequired: isAssemblyRequired, isComponentsRequired: isComponentsRequired });
        onJobTypeSelected({ cqJobType: cqJobTypeName, jobType: [...selectedJobTypeList], isvalid: jobTypeIsValid, isBomRequired: isBomRequired, isPcbRequired: isPcbRequired, isAssemblyRequired: isAssemblyRequired, isComponentsRequired: isComponentsRequired });
    };

    const getCQJobType = selectedJobType => {
        let jobTypeIds = selectedJobType.sort().toString();
        let cqJobType = jobTypeSelection.find(x => x.jobTypes === jobTypeIds);
        if (cqJobType) {
            setSelectedJobTypeDescription({ title: cqJobType.title, description: cqJobType.jobTypeDescription })
        } else {
            setSelectedJobTypeDescription({ title: "", description: "" })
        }
        return cqJobType;
    }

    return (
        isJobTypesloaded && <div>

            {
                jobTypes.map((jobTypeChunk, index) =>
                    <Row key={index} >
                        {
                            jobTypeChunk.map((jobType, index) => {
                                const jobTypeSpan = (12 / jobTypeChunk.length);
                                return (
                                    <Col key={index} md={jobTypeSpan} lg={jobTypeSpan} sm={12} xs={12}>
                                        <JobType
                                            type={jobType}
                                            key={jobType.index}
                                            onJobTypeSelected={() => handleJobTypeSelected(jobType.id)}
                                            isSelected={selectedJobType.jobType.indexOf(jobType.id) > -1} />
                                    </Col>)
                            })
                        }
                    </Row>
                )
            }

            {selectedJobTypeDescription &&
                <>
                    <Row>
                        <Col className="pt-4 p-0">
                            <h3>{selectedJobTypeDescription.title}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="job-type-description pt-2 p-0">
                            {selectedJobTypeDescription.description}
                        </Col>
                    </Row>
                </>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    previousSelectedJobType: state.JobTypes.selectedJobType
});

const mapDispatchToProps = dispatch => ({
    onJobTypeSelected: jobType => dispatch(selectJobType(jobType))
});

export default connect(mapStateToProps, mapDispatchToProps)(JobTypeList);
