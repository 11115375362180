import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import BeatLoader from "react-spinners/BeatLoader";

export const LoadingSpiner = (props) => {
  const { promiseInProgress } = usePromiseTracker({ area: props.area });
  const parentContainer = props.area ? props.parentContainer?.current : null;
  let loaderClass = null;
  if (promiseInProgress) {
    if (parentContainer) {
      loaderClass = "componentwise-loader";
    } else {
      loaderClass = "full-body-loader";
      document.body.classList.add("overlay-box");
    }
  } else {
    if (parentContainer) {
      parentContainer.classList.remove("cm-backdrop");
    } else {
      document.body.classList.remove("overlay-box");
    }
  }

  return (
    promiseInProgress && (
      <>
        <div
          className={parentContainer ? "cm-backdrop" : "cm-backdrop-full-body"}
        ></div>
        <div className={`${loaderClass} p-0 beat-loader`}>
          <BeatLoader
            size={20}
            margin={10}
            color={"#FFFFFF"}
            loading={promiseInProgress}
          />
          <div className="loader-text">Loading...</div>
        </div>
      </>
    )
  );
};
