import { OIDCService } from './OIDCService';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { SessionStorageService } from './SessionStorageService';

export const SignalrProvider = {
    initializeConnection: () => {
        const signalRBaseUrl = `${process.env.REACT_APP_SIGNALRHUB}/signalR`

        const newConnection = new HubConnectionBuilder()
            .withUrl(signalRBaseUrl)
            .withAutomaticReconnect().build();

        newConnection.start()
            .then(result => {
                newConnection.on('SendMessage', processSignalRMessage);
            }).catch();
    },
}

const processSignalRMessage = (msg) => {
    msg = JSON.parse(msg);
    if (msg.hub == "userEmailConfirmed" && msg.userId) {
        let user = OIDCService.getUser();
        if (user != undefined && user.profile.sub == msg.userId && window.location.pathname.includes('emailActivationPending')) {
            user.profile.EmailConfirmed = 'True';
            OIDCService.setUser(user);
            let postLoginRoute = SessionStorageService.getPostLoginUrl();
            SessionStorageService.removePostLoginUrl();
            postLoginRoute = postLoginRoute ? postLoginRoute : window.location.origin + '/';
            window.location.href =  postLoginRoute;
        }
    } else if (msg.hub == "notifyUserDetailChanged" && msg.userId) {
        let user = OIDCService.getUser();
        if (user != undefined && user.profile.sub == msg.userId && msg.isForceLogout == true) {
            OIDCService.logout();
        }
    }
}