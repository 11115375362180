import React from 'react'; 
import { Row, Col } from 'react-bootstrap';
import { FaRegCheckCircle } from 'react-icons/fa';

export default function MarketingContent() {
 
        const iconColor = "#1693fa";
        return (
            <Row className="marketing-section">
                <Row>
                    <Col className="header">
                        Advanced Assembly was the first company to offer quick-turn, prototype and
                        low-volume PCB assembly. It's all we've done every day for the past 16 years,
                        assembling more than 45,000 unique designs and counting.
                    </Col>
                </Row>
                <Row className="content">
                    <Col md={12} sm={12} xs={12}>
                        <FaRegCheckCircle color={iconColor} />
                        <span className="ml-2">
                            We assemble PCBs fast - really fast. Even while you wait.
                        </span>
                    </Col>
                    <Col md={12} sm={12} xs={12} className="mt-2">
                        <FaRegCheckCircle color={iconColor} />
                        <span className="ml-2">
                            We accept all quantities (1 board is really ok, so is 100+).
                        </span>
                    </Col>
                    <Col md={12} sm={12} xs={12} className="mt-2">
                        <FaRegCheckCircle color={iconColor} />
                        <span className="ml-2">
                            We are AS9100D certified. Quality is our top priority.
                        </span>
                    </Col>
                    <Col md={12} sm={12} xs={12} className="mt-2">
                        <FaRegCheckCircle color={iconColor} />
                        <span className="ml-2">
                            We care about bringing your designs to life as fast as you need them!
                        </span>
                    </Col>
                </Row>
            </Row>
        )
    }