import { DATA_CONSTANT } from '../constants/DataConstant';

export const CommonService = {
  getFileExtension: (fileName) => {
    let extension = "";
    if (fileName) {
      extension = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
    }
    return extension;
  },

  isValidBOMFile: (fileName) => {
    let fileExtension = "";
    let validExtension = DATA_CONSTANT.validExtensionForBomFile;
    if (fileName) {
      fileExtension = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
    }
    if (validExtension.indexOf(fileExtension) > -1) {
      return true;
    }
    return false;
  },

  isValidGerberFile: (fileName) => {
    let fileExtension = "";
    let validExtension = DATA_CONSTANT.validExtensionForGerberFile;
    if (fileName) {
      fileExtension = fileName
        .substr(fileName.lastIndexOf(".") + 1)
        .toLowerCase();
    }
    if (validExtension.indexOf(fileExtension) > -1) {
      return true;
    }
    return false;
  },

  createBomFileFromBlobUrl: async (url, fileName) => {
    const fetchResult = await fetch(url);
    const result = await fetchResult.blob();

    if (fetchResult.ok) {
        let types = DATA_CONSTANT.bomFileType
      let extension = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
      let metadata = {
        type: types.find((x) => x.extension === extension).type
      };

      let file = new File([result], fileName, metadata);
      return file;
    }

    const responseError = {
      type: 'Error',
      message: result.message || 'Something went wrong',
      data: result.data || '',
      code: result.code || '',
    };

    const error = new Error();
    error.info = responseError;

    return (error);
  },

  deepShallowCopy: (data) => {
    return (JSON.parse(JSON.stringify(data)));
  },

  addWorkDays: (startDate, days) => {
    if (isNaN(days)) {
      console.log("Value provided for \"days\" was not a number");
      return
    }

    if (!(startDate instanceof Date)) {
      console.log("Value provided for \"startDate\" was not a Date object");
      return
    }

    // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
    var dow = startDate.getDay();
    var daysToAdd = parseInt(days);

    // If the current day is Sunday add one day
      if (dow == 0)
          daysToAdd++;

    // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
    if (dow + daysToAdd >= 6) {
      //Subtract days in current working week from work days
      var remainingWorkDays = daysToAdd - (5 - dow);
      //Add current working week's weekend
      daysToAdd += 2;
      if (remainingWorkDays > 5) {
        //Add two days for each working week by calculating how many weeks are included
        daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
        //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
          if (remainingWorkDays % 5 == 0)
              daysToAdd -= 2;
      }
    }

    startDate.setDate(startDate.getDate() + daysToAdd);
    return startDate;
  },

  getBusinessDatesCount: (startDate, endDate) => {
    var count = 0;
    var curDate = startDate;
    while (curDate <= endDate) {
      var dayOfWeek = curDate.getDay();
        if (!((dayOfWeek == 6) || (dayOfWeek == 0)))
            count++;
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
    },
    getCookie: (cname)=> {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    getDynamicLabelDetail: (key) => {
        return DATA_CONSTANT.dynamicLabels.find(x => x.key == key);
    },
    getDynamicLabel: (key) => {
        return DATA_CONSTANT.dynamicLabels.find(x => x.key == key)?.label;
    },
}