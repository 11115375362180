import { SELECT_JOBTYPE, CLEAR_JOBTYPES } from '../../constants/ActionConstants';

const InitialState = {};
export const JobTypes = (state = InitialState, action) => {
    const { type, data } = action;

    switch (type) {
        case SELECT_JOBTYPE:
            return { ...state, selectedJobType: data.jobType};
        case CLEAR_JOBTYPES:
            return {
                ...state, selectedJobType:data.jobType
            };
        default:
            return state;
    }
}