import React, { useContext } from "react";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import AccordionContext from "react-bootstrap/AccordionContext";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import "./Faq.css";

function FaqToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div onClick={() => decoratedOnClick()}>
      <Row>
              <Col md={10}>
                  {children}
         
        </Col>
        <Col md={2} className="text-right icon-orange">
          {isCurrentEventKey ? <FaChevronUp /> : <FaChevronDown />}
        </Col>
      </Row>
    </div>
  );
}

export default FaqToggle;
