export const DATA_CONSTANT = {
    allowedFileTypes: [
        "image/*",
        "application/pdf",
        ".csv",
        "application/msword",
        "application/json",
        "text/plain",
        ".zip",
        ".rar",
        ".7zip",
        ".xlsx",
        ".docx",
        ".doc",
        ".gbr",
        ".xls",
    ],
    maxFileSize: 10485760 /*Bytes- 10 MB*/,

    patterns: {
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        // name: /^[a-zA-Z0-9\[\]\\/{};,!@#$%^&*~':?) (+=._-]+$/,
        name: "^[\\p{L}\\p{N}\\p{Sc}\\p{So}\\p{M}\\p{Zs}\\p{P}^~+=]+$",
        date: /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/,
        dateFormat: "MM/DD/YYYY",
        //Number starting from 1 to 9 and allow up to 99999999
        quantity: /^[1-9][0-9]{0,7}$/,
        //Number starting from 1 to 9 and allow up to 999
        totalTurnTime: /^[1-9][0-9]{0,2}$/,
        laborQuestion: /^[0-9]{1,4}(\.[0-9]{1,2})?$/,
        AccountNumberValidation_FedEx: /(^[0-9]{9})$/,
        AccountNumberValidation_UPS: /(^[a-zA-Z0-9]{6})$/,
        checkListBuilderNumber: /^[0-9]*$/,
    },

    stepNames: {
        RFQ: "RFQ",
        BOM: "BOM",
        Labor: "Labor",
        PCB: "PCB",
        ReviewBOM: "ReviewBOM",
        Completed: "Completed",
        isComponentPricingDone: "IsComponentPricingDone",
        AssemblyDetail: "AssemblyDetail",
        GetQuote: "GetQuote",
        Checkout: "Checkout",
        CheckoutBillingShipping: "CheckoutBillingShipping",
        CheckoutPayment: "CheckoutPayment",
        CheckoutVerify: "CheckoutVerify",
    },

    guidelinesFiles: {
        BOM_format_guidelines: "BOM_Best_Practices.pdf",
        BOM_template: "AAPCB_BOM_Template.xlsx",
    },

    validExtensionForBomFile: ["xlsx", "xls", "csv"],

    validExtensionForGerberFile: ["xlsx", "xls", "csv", "doc", "jpg", "docx", "png", "bmp", "txt", "zip"],

    bomFileType: [
        { extension: "xls", type: "application/vnd.ms-excel" },
        {
            extension: "xlsx",
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        { extension: "csv", type: "text/csv" },
    ],

    FileType: {
        BOM: "BOM",
        Gerber: "Gerber",
    },

    timeUOMs: [
        { key: 'Day(s)', value: 'Days' },
        { key: 'Week(s)', value: 'Weeks' },
    ],

    ConfigurationKey: {
        HubSpotAPPId: 'HubSpotAppId',
        GoogleAnalyticsTagId: 'GoogleAnalyticsTagId',
        PCBPartClass: 'PCBPartClass',
        StripePublishableKey: 'StripePublishableKey',
        MaxNoOfUploadDocuments: 'MaxNoOfUploadDocuments',
        QuoteCQClientCode: 'QuoteCQClientCode',
        PortCQUrl: 'PortCQUrl'
    },

    OrangeColor: '#FF6400',
    BlueColor: '#007bff',
    PortCQAPIToken: 'Basic d80c83ac1fd54284a3f5c54a18e60ff8',

    CountriesForTaxCalculation: ['US', 'CANADA', 'UNITED STATES', 'USA', 'UNITED STATES OF AMERICA', 'CA'],

    ShippingMethod: {
        CustomerShippingAccount: 'Customer Shipping Account'
    },
    routes: {
        error: "/error",
        rfq: "/rfq",
        jobType: "/jobtype",
        emailActivationPending: "/emailActivationPending",
        scrubMyBom: "/scrubmybom",
        customQuote:"/customquote"
    },
    missingPcbHeaderText: "It looks like the BOM is missing the PCB. That's ok. we'll help you fix that now. Please enter the details below for any PCB(s) required for this assembly.",
    missingPcbConfirmButtonText: "It is a PCB",
    missingPcbCancelButtonText: "I need to modify the entered part number",
    missingPcbHelpText: "Quantity provided by the BOM. Please enter a new part number if you want to change the quantity (this will create a new line on the BOM).",

    thankYouQuoteURL: "https://go.aapcb.com/ty_quoting",
    thankYouOrderCompleteURL: "https://go.aapcb.com/ty_order",

    ScreenName: {
        AssemblyDetail:"ASSEMBLYDETAIL"
    },

    AssemblyFields: {
        ExternalUserId: 'ExternalUserId'
    },

    ReviewBOM: {
        RerunPricing: "Rerun Pricing"
    },
    rfqSteps: [],
    dynamicLabels: []
};