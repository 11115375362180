import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import CheckListBuilder from "../../components/Common/CheckListBuilder";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { Row, Col } from "react-bootstrap";
import { setCompletedSteps } from "../../redux/actions/CompletedStepActions";
import { connect } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { PrimaryOrangeButton } from "../../components/Common/InputButton/InputButton";
import { CheckListBuilderService } from "../../services/CheckListBuilderService";
import { CommonService } from "./../../services/CommonService";
import { setIsCustomQuoteRequires } from "../../redux/actions/SetCustomQuoteRequire";


const AssemblyDetail = (props) => {
    const history = useHistory();

    const [checkListBuilderData, setCheckListBuilderData] = useState({});
    const [isChecklistFieldsExists, setIsChecklistFieldsExists] = useState(true);
    const [validateChildForm, setValidateChildForm] = useState(false);
    const [isValidChild, setIsValidChild] = useState(false);

    useEffect(() => {
        if (!props?.RFQID) {
            history.push("/");
            return;
        }

        if (!isChecklistFieldsExists) {
            stepCompleted(false);
        }
    }, [isChecklistFieldsExists]);

    const onChangeCheckListBuilderDataUpdate = (val) => {
        setCheckListBuilderData(val);
    };

    const stepCompleted = (isAnyFieldRequiresCustomQuotes) => {
        props.onStepCompleted();

        if (isAnyFieldRequiresCustomQuotes) {
            history.push(DATA_CONSTANT.routes.customQuote);
        } else {
            history.push(DATA_CONSTANT.routes.scrubMyBom);
        }
    };

    const isValidChildCheck = useCallback((result) => {
        if (result) {
            setIsValidChild(result);
        }
        setValidateChildForm(false);
    });

    useEffect(() => {
        trackPromise(submitAssemblyDetails());
    }, [isValidChild]);

    const submitAssemblyDetails = async () => {
        if (isValidChild) {
            let values = CommonService.deepShallowCopy(checkListBuilderData);
            let objForCheckListBuilder = {};
            objForCheckListBuilder.RFQId = props.RFQID;
            objForCheckListBuilder.RFQCustomFieldValues = [];
            if (values.length > 0) {
                values.map((vals, inds) => {
                    var objValues = {};
                    if (
                        (vals.fieldType == "CheckBox" || vals.fieldType == "DropDown") &&
                        vals.defaultValue != null
                    ) {
                        if (Array.isArray(vals.defaultValue)) {
                            vals.defaultValue = vals.defaultValue.join(",");
                        }
                    } else if (vals.defaultValue == null) {
                        vals.defaultValue = "";
                    }
                    objValues.FieldTypesName = vals.name;
                    objValues.FieldTypeValue = vals.defaultValue;
                    objForCheckListBuilder.RFQCustomFieldValues.push(objValues);
                });

                let isAnyFieldRequiresCustomQuotes = values.some(x => x.IsCustomQuoteRequires == true && x.defaultValue && x.CustomQuoteValues && x.CustomQuoteValues.trim().toLowerCase().split("|").includes(x.defaultValue.trim().toLowerCase()));

                await CheckListBuilderService.submitCheckListBuilder(objForCheckListBuilder)
                    .then((data) => {
                        if (data != null) {
                            stepCompleted(isAnyFieldRequiresCustomQuotes);
                            props.onSetCustomQuoteRequires(isAnyFieldRequiresCustomQuotes);
                        }
                    })
                    .catch(() => {
                        setIsValidChild(false);
                    });
            }
        }
    };

    return (
        <Row>
            <Col md={12} lg={12} sm={12} xs={12}>
                <Row className="mb-4">
                    <Col>
                        <h2>Optional Services</h2>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg={12} md={12} sm={12} xs={12} className="pl-0">
                        <CheckListBuilder
                            JobTypeName={props.selectedJobType}
                            ScreenName="AssemblyDetail"
                            onChangeCheckListBuilder={onChangeCheckListBuilderDataUpdate}
                            setIsChecklistFieldsExists={setIsChecklistFieldsExists}
                            isValidChildCheck={isValidChildCheck}
                            validateForm={validateChildForm}
                        />
                    </Col>
                </Row>
                <Row className="text-center my-5">
                    <Col md={11} sm={12} xs={12} className="text-center">
                        <PrimaryOrangeButton
                            name="Next"
                            handleClick={() => setValidateChildForm(true)}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const mapStateToProps = (state) => ({
    selectedJobType: state.JobTypes?.selectedJobType,
    defaultConfigs: state.DefaultConfigs?.defaultConfigs,
    assemblyId: state.RFQDetails?.rfqDetails?.assemblyId,
    RFQID: state.RFQDetails?.rfqDetails?.quoteId,
    bomId: state.BomIds?.BomId,
});

const mapDispatchToProps = (dispatch) => ({
    onStepCompleted: () => {
        dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.AssemblyDetail));
    },
    onSetCustomQuoteRequires: (IsCustomQuoteRequires) => {
        dispatch(setIsCustomQuoteRequires(IsCustomQuoteRequires))
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(AssemblyDetail);
