const ROOT_URL = process.env.REACT_APP_API_URL;
export const URL_CONSTANT = {
    JobType: {
        GetJobTypes: ROOT_URL + "/JobType/GetJobTypes",
        GetBOMAnalysisJobTypes: ROOT_URL + "/JobType/GetBOMAnalysisJobTypes"
    },
    RequestForm: {
        GetDefaultConfigurations: ROOT_URL + "/RFQ/GetDefaultConfigurations",
        CreateRFQ: ROOT_URL + "/RFQ/Create",
        DownLoadFile: ROOT_URL + "/RFQ/DownloadFile",
        GetCustomerContactByEmail: ROOT_URL + "/RFQ/GetCustomerContactByEmail",
        UpdateAssemblyFieldValue: ROOT_URL + "/RFQ/UpdateAssemblyFieldValue",
        GetRFQSteps: ROOT_URL + "/RFQ/GetRFQSteps"
    },
    Labor: {
        GetLaborQuestions: ROOT_URL + "/Labor/GetLaborQuestions",
        SubmitLaborAnswers: ROOT_URL + "/Labor/SubmitLaborAnswers"
    },
    Configuration: {
        GetConfigurations: ROOT_URL + "/Configuration/GetConfigurations",
        IsBomAnalysisEnabled: ROOT_URL + "/Configuration/IsBomAnalysisEnabled"
    },
    BOM: {
        CreateBOM: ROOT_URL + "/BOM/Create",
        GetPCBLinesByAssemblyId: ROOT_URL + "/BOM/GetPCBLinesByAssemblyId",
        IsScrubMyBomRunning: ROOT_URL + "/BOM/IsScrubMyBomRunning"
    },
    CustomImportWidgetCSSUrl: `${window.location.origin}${process.env.PUBLIC_URL}/CustomImportWidget.css`,
    CheckListBuilderUrl: ROOT_URL + "/CheckListBuilder/GetCheckListScreen",
    CheckPricingStatus: {
        CheckPricingStatus: ROOT_URL + "/Pricing/CheckPricingStatus"
    },
    Pricing:
    {
        GetRequestedQuantity: ROOT_URL + "/Pricing/GetRequestedQuantity",
        GetPurchaseData: ROOT_URL + "/Pricing/GetPurchaseData",
        UpdateMethodOfSupply: ROOT_URL + "/Pricing/UpdateMethodOfSupply",
        GetBomSummary: ROOT_URL + "/Pricing/GetBomSummary",
        UpsertMethodOfSupplySelections: ROOT_URL + "/Pricing/UpsertMethodOfSupplySelections",
        AddAlternateParts: ROOT_URL + "/Pricing/AddAlternateParts",
        RefreshPricing: ROOT_URL + "/Pricing/RefreshPricing",
    },
    SubmitCheckListBuilder: ROOT_URL + "/RFQ/UpsertRFQChecklist",
    GetSummaryDetails: ROOT_URL + "/Summary/Get",
    QuotePrice: {
        MarkPriceAsSelected: ROOT_URL + "/QuotePrice/MarkPriceAsSelected",
        GetSelectedPrice: ROOT_URL + "/QuotePrice/GetSelectedPrice"
    },
    Checkout: {
        GetOrder: ROOT_URL + "/Order/GetOrder",
        SaveOrder: ROOT_URL + "/Order/SaveOrder",
        CreatePaymentIntent: ROOT_URL + "/Payment/CreatePaymentIntent",
        UpdatePaymentDetails: ROOT_URL + "/Payment/UpdatePaymentDetails",
        GetShippingMethods: ROOT_URL + "/ShippingMethod/Get",
        GetDiscountFromPromoCode: ROOT_URL + "/Order/GetDiscountFromPromoCode",
        GetPayTraceClientkey: ROOT_URL + "/PayTracePayment/GetPayTraceClientKey",
        ProtectSale: ROOT_URL + "/PayTracePayment/ProtectSale",
        CalculateTax: ROOT_URL + "/Order/CalculateTax",
    },
    PCB: {
        GetPcbQuestions: ROOT_URL + "/PCB/GetPCBQuestions",
        SubmitPCBAnswers: ROOT_URL + "/PCB/SubmitPCBAnswers",
        SubmitPCBWithPricing: ROOT_URL + "/PCB/SubmitPCBWithPricing",
        GetSavedPCBAnswer: ROOT_URL + "/PCB/GetSavedPCBAnswers",
    },
    CustomQuote: {
        GetAllUploadedDocument: ROOT_URL + "/CustomQuote/GetAllUploadedDocument",
        SubmitCustomQuote: ROOT_URL + "/CustomQuote/SubmitCustomQuote",
    },
    Document: {
        GeneratePreSignedUrl: ROOT_URL + "/Document/GeneratePreSignedUrl",
        InsertDocuments: ROOT_URL + "/Document/InsertDocuments"
    },
    GetCostedBOMReport: ROOT_URL + "/Report/GetCostedBOMReport",    
    DynamicLabel: {
        GetDynamicLabels: ROOT_URL + "/DynamicLabels/GetDynamicLabels"
    }
};