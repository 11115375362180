import React, {
    useCallback,
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { Container, Button, Row, Col } from "react-bootstrap";
import { RemoveButton } from "./InputButton/InputButton";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { MESSAGE_CONSTANT } from "../../constants/MessageConstant";

const DocumentUploader = forwardRef((props, ref) => {
    const [acceptedFiles, setAcceptedFiles] = useState([]);

    const [rejectedFiles, setRejectedFiles] = useState([]);

    const [isTooManyFiles, setTooManyFiles] = useState(false);

    const maxFiles = props.maxNumberOfFileToUpload;

    const onDrop = useCallback(
        (newAcceptedFiles, newRejectedFiles) => {
            let uniqueAcceptedFiles = [
                ...new Set(newAcceptedFiles.map((obj) => obj.name)),
            ].map((name) => {
                return newAcceptedFiles.find((obj) => obj.name === name);
            });

            let uniqueRejectedFiles = [
                ...new Set(newRejectedFiles.map((obj) => obj.file.name)),
            ].map((name) => {
                return newRejectedFiles.find((obj) => obj.file.name === name);
            });

            let totalFiles =
                uniqueAcceptedFiles.length +
                uniqueRejectedFiles.length +
                acceptedFiles.length;
            if (totalFiles > maxFiles) {
                setTooManyFiles(true);
            } else {
                setTooManyFiles(false);

                let mergeFiles = [...acceptedFiles, ...uniqueAcceptedFiles];

                let uniqueMergeFiles = [
                    ...new Set(mergeFiles.map((obj) => obj.name)),
                ].map((name) => {
                    return mergeFiles.find((obj) => obj.name === name);
                });

                setAcceptedFiles([...uniqueMergeFiles]);
                let mergeRejectedFiles = [...rejectedFiles, ...uniqueRejectedFiles];
                let uniqueMergeRejectedFiles = [
                    ...new Set(mergeRejectedFiles.map((obj) => obj.file.name)),
                ].map((name) => {
                    return mergeRejectedFiles.find((obj) => obj.file.name === name);
                });

                setRejectedFiles(uniqueMergeRejectedFiles);
            }
        },
        [acceptedFiles, rejectedFiles]
    );

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: props.alowedFileTypes,
        onDrop,
        maxFiles: maxFiles,
        maxSize: DATA_CONSTANT.maxFileSize,
        minSize: 1
    });

    useEffect(() => {
        props.handleAcceptedFiles(acceptedFiles);
        if (props.displayFiles === false) {
            props.handleRejectedFiles(rejectedFiles);
            props.handleTooManyFilesError(isTooManyFiles);
        }
    }, [acceptedFiles, rejectedFiles, isTooManyFiles]);

    const removeFile = (name) => {
        setTooManyFiles(false);
        let acceptedFilesIndex = acceptedFiles.findIndex((e) => e.name === name);
        acceptedFiles.splice(acceptedFilesIndex, 1);
        setAcceptedFiles([...acceptedFiles]);
    };

    const removeAllUnsupportedFiles = () => {
        setRejectedFiles([]);
    };

    useImperativeHandle(ref, () => ({
        handleRemoveFile(name) {
            removeFile(name);
        },
        handleRemoveAllFile() {
            removeAllUnsupportedFiles();
        },
    }));
    const acceptedFilesView = acceptedFiles.map((file, index) => (
        <Row key={index}>
            <Col md={10} lg={10} sm={12} xs={12} className="file-name-text">
                {file.path} - {file.size} bytes
            </Col>
            <Col md={2} lg={2} sm={12} xs={12} className="mt-2">
                <RemoveButton handleClick={() => removeFile(file.name)} />
            </Col>
        </Row>
    ));

    const rejectedFilesView = rejectedFiles.map(({ file, errors }, index) => (
        <Row key={index}>
            <Col md={12} lg={12} sm={12} xs={12} className="file-name-text">
                <li key={file.path}>
                    {file.path} - {file.size} bytes
                    <ul>
                        {errors.map((e) => (
                            <li key={e.code}>{e.message}</li>
                        ))}
                    </ul>
                </li>
            </Col>
        </Row>
    ));

    return (
        <Container fluid>
            <Row>
                <Col className="px-0">
                    <div {...getRootProps({ className: "dropzone file-uploder" })}>
                        <div className="margin-35">
                            <input {...getInputProps({})} />
                            <div className="header-txt">
                                <Row>
                                    <Col> Drag to Import</Col>
                                </Row>
                                <Row>
                                    <Col> or</Col>
                                </Row>
                            </div>
                            <Button type="button" onClick={open} className="btn-open">
                                choose file
                            </Button>
                            <div className="mt-3">(You can drop here maximum {maxFiles} files)</div>

                            <div className="mt-3">Please note: files will be uploaded to an ITAR-compliant server.</div>

                        </div>
                    </div>
                </Col>
            </Row>
            {props.displayFiles === true && (
                <Row className="mt-3 ml-md-3 ml-lg-0">
                    <Col>
                        <Row>
                            <Col>
                                {acceptedFilesView.length > 0 && (
                                    <Row>
                                        <Col md={5}>
                                            <h4>Attached files</h4>
                                        </Col>
                                    </Row>
                                )}
                                {acceptedFilesView}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {rejectedFilesView.length > 0 && !isTooManyFiles && (
                                    <>
                                        <Row className="pt-5">
                                            <Col md={10}>
                                                <h4>Rejected files</h4>
                                            </Col>
                                            <Col md={2}>
                                                <RemoveButton
                                                    handleClick={() => removeAllUnsupportedFiles()}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>{rejectedFilesView}</Row>
                                    </>
                                )}

                                {isTooManyFiles && (
                                    <h5 className="error-txt mt-3">{`${MESSAGE_CONSTANT.tooManyFiles} ${maxFiles}`}</h5>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </Container>
    );
});

DocumentUploader.defaultProps = {
    displayFiles: false,
};

DocumentUploader.propTypes = {
    displayFiles: PropTypes.bool,
    alowedFileTypes: PropTypes.array,
};

export default DocumentUploader;
