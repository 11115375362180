import { SET_CUSTOMQUOTE_REQUIRES, CLEAR_CUSTOMQUOTE_REQUIRES } from '../../constants/ActionConstants';

export const setIsCustomQuoteRequires = (val) => ({
    type: SET_CUSTOMQUOTE_REQUIRES,
    data: { IsCustomQuoteRequires: val }
});

export const clearIsCustomQuoteRequires = () => ({
    type: CLEAR_CUSTOMQUOTE_REQUIRES,
    data: {}
});