import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';

export const CustomQuoteService = {
    GetAllUploadedDocument: (assemblyId, userId) => {
        const url = `${URL_CONSTANT.CustomQuote.GetAllUploadedDocument}`;
        const params = { assemblyId: assemblyId, userId: userId };
        return CommonHttpService.httpGet(url, params);
    },
    SubmitCustomQuote: (data) => {
        const url = `${URL_CONSTANT.CustomQuote.SubmitCustomQuote}`;
        return CommonHttpService.httpFilePost(url, data);
    }
}