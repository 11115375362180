import React from 'react';
import { Route } from 'react-router-dom';
import { useEffect } from 'react';

const PublicRoutes = ({
    component: Component,
    ...props
}) => {

    useEffect(() => {
        if (props.title) {
            document.title = `${props.title} | Advanced Assembly`;
        }
    });

    if (props.statusCode != undefined) {
        props.location.state = props.statusCode;
    }
    return (
        <Route {...props} render={(props) => (<Component {...props} />)} />
    )
}
export default PublicRoutes;
