import React from "react";
import { Select } from "antd";
import { FormLabel } from "react-bootstrap";
import "./InputControl.css";
import InfoToolTip from "../../ControlComponents/InfoToolTip";

const DropDown = (props) => {
    const { Option } = Select;
    return (
        <>
            {props.label && props.label.trim() && (
                <>
                    <FormLabel> {props.label} </FormLabel>
                    {props.isRequired && <span className="error-txt">*</span>}
                    {props.helpText && props.helpText.trim() && (
                        <InfoToolTip
                            className={props.className}
                            helpText={props.helpText}
                            helpPlacement={props.helpPlacement}
                        />
                    )}
                </>
            )}
            <Select
                name={props.name}
                mode="multiple"
                allowClear
                className="MultiSelect-DropDown-AntDesign"
                placeholder="Please select"
                defaultValue={props.value === "" || props.value === null ? [] : props.value}
                onChange={props.onChange}
            >
                {props.listOfValues.length > 0 &&
                    props.listOfValues.map((value, index) => {
                        return (
                            <Option key={index} value={value?.optionValue}>
                                {value.optionText}
                            </Option>
                        );
                    })}
            </Select>
        </>
    );
};

export default DropDown;
