import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from 'react';
import { URL_CONSTANT } from "../../constants/UrlConstant";
import { trackPromise } from "react-promise-tracker";
import { BOMService } from "../../services/BOMService";
import './ImportBOM.css';
import { ConfigurationService } from "../../services/ConfigurationService";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { UploadDocumentService } from "../../services/UploadDocumentService";
import MissingPCB from "../../components/MissignPCB/MissingPCB";

const ImportBOMModal = ({ show, backdrop, keyboard, data, selectedJobTypeInfo, onHide, needToAddManualPCBLine }) => {

    let pcbPartClass = "";
    let quoteCQClientCode = "";
    const zIndexClass = 'z-index-1030';
    const [activeClass, setActiveClass] = useState('');
    const appId = process.env.REACT_APP_IMPORT_WIDGET_APP_ID;
    const [bomFile, setBomFile] = useState({});
    const [widgetData, setWidgetData] = useState({ bomData: [], missingPcbShow: needToAddManualPCBLine, partClass: '' });

    useEffect(() => {
        trackPromise(getConfigurations());

        return () => {
            if (window.removeEventListener) {
                window.removeEventListener('message', afterImportFromWidget, false);
            }
        }

    }, []);

    const openImportWidget = () => {
        let extraDataInJson = {
            AssemblyId: data.assemblyId,
            ClientCode: quoteCQClientCode,
            UserId: data.userId,
            UserEmail: data.salesPersonEmail
        };

        if (window.addEventListener) {
            window.addEventListener('message', afterImportFromWidget, false);
        } else {
            window.attachEvent('onmessage', afterImportFromWidget);
        }

        let widgetDataForImport = {
            appID: appId,
            opentype: 'iframe',
            file: data.file,
            extraDataInJson: JSON.stringify(extraDataInJson),
            customCSS: URL_CONSTANT.CustomImportWidgetCSSUrl
        }
        window.CQWidgetNew('open', widgetDataForImport);
    }

    const afterImportFromWidget = (obj) => {
        if (obj.data && obj.data.importSuccessful && obj.data.data) {

            let extraData = JSON.parse(obj.data.extraDataInJson);
            if (extraData.AssemblyId !== data.assemblyId) {
                return;
            }

            let isAnyPcbLine = obj.data.data.some(bomObj => bomObj.IsPCB === true);
            if (selectedJobTypeInfo.isPcbRequired && !isAnyPcbLine) {
                setBomFile(obj.data.file);
                setWidgetData({ bomData: obj.data.data, missingPcbShow: true, partClass: pcbPartClass })
            } else {
                if (selectedJobTypeInfo.isPcbRequired) {
                    obj.data.data.map((lineItem, index) => {
                        if (lineItem.IsPCB) {
                            lineItem.PartClass = pcbPartClass;
                        }
                    })
                }
                createBOMRequest(obj.data.data, obj.data.file);
            }
        }
    }

    const importFromMissingPCB = (pcbData, pcbPartClass) => {
        let pcbNewLineItems = [];
        let pcbLineNo = 0;

        pcbData.map((pcb) => {
            let lineItems = widgetData.bomData.filter(x => x.MfgrsPartNo?.toLowerCase().trim() === pcb.partNumber?.toLowerCase().trim());
            if (lineItems && lineItems.length > 0) {
                lineItems.map((bomLine) => {
                    bomLine.IsPCB = true;
                    bomLine.PartClass = pcbPartClass;
                    bomLine.Description = pcb.description || bomLine.Description
                })
            }
            else {
                pcbLineNo++;
                let lineItemObj = {
                    Description: pcb.description
                    , Designator: "PCB"
                    , IsInstall: true
                    , IsPCB: true
                    , LineItem: `PCB${pcbLineNo}`
                    , MPNNotes: ""
                    , Mfgr: "PCB"
                    , MfgrsPartNo: pcb.partNumber
                    , PNRoHS: ""
                    , Qty: pcb.qty
                    , Revision: ""
                    , Specs: ""
                    , PartClass: pcbPartClass
                    , IsPurchase: true
                }
                pcbNewLineItems.push(lineItemObj);
            }
        })

        let coppiedBomItems = [...widgetData.bomData, ...pcbNewLineItems];

        createBOMRequest(coppiedBomItems, bomFile);
    }

    const createBOMRequest = (bomData, file) => {
        var createBOMData = {
            AssemblyId: data.assemblyId,
            BOMLineItems: bomData,
            UserId: data.userId,
            BomFileNameToDelete: data.selectedBomFileName,
            IsUpdatePricingRequired: selectedJobTypeInfo.isComponentsRequired,
            IsPCB: selectedJobTypeInfo?.isPcbRequired,
            IsBomAnalysis: selectedJobTypeInfo?.isBomAnalysis,
            IsBomRequired: selectedJobTypeInfo?.isBomRequired
        }
        trackPromise(
            createBOM(createBOMData, file)
        );
    }

    const createBOM = async (bomData, file) => {
        setActiveClass(zIndexClass);

        //if (selectedJobTypeInfo.isPcbRequired) {
        //    let isComponentsRequired = selectedJobTypeInfo.isComponentsRequired;
        //    bomData.BOMLineItems.map((lineItem, index) => {
        //        if (lineItem.IsPCB) {
        //            lineItem.PartClass = pcbPartClass;
        //        } else if (!isComponentsRequired) {
        //            lineItem.IsPurchase = false;
        //        }
        //    });
        //}

        await BOMService.createBOM(bomData).then((bomId) => {
            if (window.removeEventListener) {
                window.removeEventListener('message', afterImportFromWidget, false);
            }

            trackPromise(
                uploadDocuments(bomId, file)
            );
        })
    }

    const uploadDocuments = async (bomId, file) => {

        if (file && file.name) {
            file.filetype = DATA_CONSTANT.FileType.BOM;
            await UploadDocumentService.uploadDocuments(data.assemblyId, [file]);
        }

        onHide(bomId);
        setActiveClass('');
    }

    const getConfigurations = async () => {

        let configKeys = [DATA_CONSTANT.ConfigurationKey.PCBPartClass, DATA_CONSTANT.ConfigurationKey.QuoteCQClientCode];
        await ConfigurationService.getByKeys(configKeys).then((data) => {
            if (data && (data.length > 0)) {
                pcbPartClass = data.find(x => x.key === DATA_CONSTANT.ConfigurationKey.PCBPartClass).value
                quoteCQClientCode = data.find(x => x.key === DATA_CONSTANT.ConfigurationKey.QuoteCQClientCode).value
            }

            if (show) {
                openImportWidget();
            } else {
                setWidgetData({ ...widgetData, partClass: pcbPartClass });
            }
        });
    }

    return (
        <>
            {show === true && !widgetData.missingPcbShow &&
                <Modal {...{ show, backdrop, keyboard, data, onHide }} className={`modal-container custom-map-modal ${activeClass}`}>
                    <Modal.Body>
                        <div id="widgetReplaceId"></div>
                    </Modal.Body>
                </Modal>
            }
            {
                widgetData.missingPcbShow === true &&
                <MissingPCB bomData={widgetData} needToAddManualPCBLine={needToAddManualPCBLine} handleBomSubmit={(data, partClass) => importFromMissingPCB(data, partClass)}></MissingPCB>
            }
        </>
    );
}
export default ImportBOMModal