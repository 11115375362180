export const SELECT_JOBTYPE = "SELECT_JOBTYPE";

export const SET_DEFAULT_CONFIGS = "SET_DEFAULT_CONFIGS";

export const SET_RFQ_DETAILS = "SET_RFQ_DETAILS";

export const CLEAR_STORE = "CLEAR_STORE";

export const SET_COMPLETED_STEPS = "SET_COMPLETED_STEPS";

export const RESET_COMPLETED_STEPS = "RESET_COMPLETED_STEPS";

export const SET_BOM_FILES = "SET_BOM_FILES";

export const CLEAR_JOBTYPES = "CLEAR_JOBTYPES";

export const BOMID = "BOMID";

export const CLEAR_BOM = "CLEAR_BOM";

export const SET_PRICING_RUNNING_STATUS = "SET_PRICING_STATUS";

export const CLEAR_PRICING_RUNNING_STATUS = "CLEAR_PRICING_STATUS";

export const SET_CUSTOMQUOTE_REQUIRES = "SET_CUSTOMQUOTE_REQUIRES";

export const CLEAR_CUSTOMQUOTE_REQUIRES = "CLEAR_CUSTOMQUOTE_REQUIRES";

export const SET_GERBER_FILES = "SET_GERBER_FILES";

export const SET_DISABLED_STEPS = "SET_DISABLED_STEPS";

export const RESET_DISABLED_STEPS = "RESET_DISABLED_STEPS";

export const SET_EXTERNAL_USERID = "SET_EXTERNAL_USERID";

