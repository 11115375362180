import { BOMID,CLEAR_BOM } from '../../constants/ActionConstants';

export const setBom = (val) => ({
    type: BOMID,
    data: { BomId: val }
});

export const clearBom = () => ({
    type: CLEAR_BOM,
    data: {}
});