import React from 'react';
import './Header.css';
import TopNavMenu from '../Navigation/TopNavMenu';
import { Container, Row } from 'react-bootstrap';

const HeaderWithMenu = () => (
    <Container fluid>
        <Row>
            <header className="w-100">
                <TopNavMenu />
            </header>
        </Row>
    </Container>
);

export default HeaderWithMenu