import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import PricingCompilingScreen from '../../components/PricingCompilingScreen/PricingCompilingScreen';
import ReviewBOM from '../../components/ReviewBOM/ReviewBOM';
import { setCompletedSteps } from "../../redux/actions/CompletedStepActions";
import { DATA_CONSTANT } from '../../constants/DataConstant';
import { PricingService } from '../../services/PricingService';
import { setIsCustomQuoteRequires } from "../../redux/actions/SetCustomQuoteRequire";

const ReviewBOMPage = ({ completedSteps,
    selectedJobType,
    salesPersonId,
    rfqDetails,
    onStepCompleted,
    PricingRunningStatus,
    onSetCustomQuoteRequires
}) => {

    let isPricingDone = false;
    let isSubmitData = false;

    let assemblyId = rfqDetails?.assemblyId;
    let rfqId = rfqDetails?.quoteId;

    const history = useHistory();
    const [isComponentPricingDone, setIsComponentPricingDone] = useState(false);
    const [pricingStatus, setPricingStatus] = useState({ isCompleted: isComponentPricingDone, isPricingCompleted: false, isPricingApiCalled: false, pricingCompletedPercentage: 0, remainingAutoSelectCriteriaToRun: '' });
    const [customInterval, setCustomInterval] = useState(0);
    const [isInitCall, setInitCall] = useState(true);
    let isIntialPricingInterval = PricingRunningStatus == undefined ? true : !PricingRunningStatus.intialPricingInterval;
    const delay = 30000;
    const delayForAutoSelect = 15000;

    useEffect(() => {
        if (isComponentPricingDone) {
            return
        }
        if (!selectedJobType?.isComponentsRequired && !selectedJobType?.isPcbRequired) {
            history.push('/');
            return;
        }
        if (pricingStatus.isPricingCompleted && pricingStatus.isCompleted) {
            isPricingDone = true;
            setIsComponentPricingDone(true);
            stepCompleted(isPricingDone, isSubmitData);
            clearInterval(customInterval);
            return;
        }
        if (PricingRunningStatus?.isUpdatePricingCompleted && pricingStatus.isPricingApiCalled === false) {
            let status = { ...pricingStatus };
            status.isPricingApiCalled = true;
            setPricingStatus(status);
            clearInterval(customInterval);
            let interval = setInterval(() => {
                checkPricingStatus(true);
            }, delayForAutoSelect);
            setCustomInterval(interval);
        } else {
            if (isIntialPricingInterval) {
                if (isInitCall) {
                    setInitCall(false);
                    checkPricingStatus(false);
                    let interval = setInterval(() => {
                        checkPricingStatus(false);
                    }, delay);
                    setCustomInterval(interval);
                }
            }
        }
    }, [pricingStatus]);

    const checkPricingStatus = async (IsUpdatePricingCompleted) => {
        let requestObj = {
            AssemblyId: assemblyId
        };
        await PricingService.CheckPricingStatus(requestObj)
            .then((data) => {
                if (data) {

                    setPricingStatus({
                        isCompleted: data.isCompleted,
                        isPricingCompleted: data.isUpdatePricingCompleted,
                        isPricingApiCalled: false,
                        pricingCompletedPercentage: data.isUpdatePricingCompleted == true ? (data.isCompleted ? 100 : 99) : data.completedPercentage,
                        remainingAutoSelectCriteriaToRun: data.remainingAutoSelectCriteriaToRun
                    });
                }

            })
    };

    const stepCompleted = (isPricingDone, isSubmitData) => {
        onStepCompleted(isPricingDone, isSubmitData);
    }

    const checkDataIsSubmited = (isSubmitData, isCustomQuoteTrigger) => {
        if (isSubmitData) {
            isSubmitData = true;
            stepCompleted(isPricingDone, isSubmitData);
        }
        if (isCustomQuoteTrigger) {
            onSetCustomQuoteRequires(isCustomQuoteTrigger);
            history.push(DATA_CONSTANT.routes.customQuote);
        }
        else {
            let nextStepIndex = DATA_CONSTANT.rfqSteps.findIndex(x => x.stepName === DATA_CONSTANT.stepNames.ReviewBOM) + 1;
            history.push(DATA_CONSTANT.rfqSteps[nextStepIndex].routeName);
        }
    }

    const handleRerunPrice = (rerunPricing) => {
        if (rerunPricing) {
            setIsComponentPricingDone(false);
            setInitCall(true);
            setPricingStatus({
                isCompleted: false,
                isPricingCompleted: false,
                isPricingApiCalled: false,
                pricingCompletedPercentage: 0,
                remainingAutoSelectCriteriaToRun: ''
            });
        }
    }
    return (
        <>
            {
                !isComponentPricingDone && <PricingCompilingScreen pricingStatus={pricingStatus} />
            }
            {
                isComponentPricingDone && <ReviewBOM assemblyId={assemblyId} rfqId={rfqId} submitDataHandler={checkDataIsSubmited} salesPersonId={salesPersonId} completedSteps={completedSteps} handleRerunPrice={handleRerunPrice} selectedJobType={selectedJobType} />
            }
        </>
    )
}
const mapStateToProps = state => ({
    selectedJobType: state.JobTypes.selectedJobType,
    salesPersonId: state.DefaultConfigs.defaultConfigs?.salesPersonId,
    completedSteps: state.CompletedSteps ? state.CompletedSteps : [],
    PricingRunningStatus: state.PricingRunningStatus?.pricingRunningStatus,
    rfqDetails: state.RFQDetails?.rfqDetails
});

const mapDispatchToProps = dispatch => ({
    onStepCompleted: (isPricingDone, isSubmitData) => {
        if (isPricingDone) {
            dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.isComponentPricingDone));
        }
        if (isSubmitData) {
            dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.ReviewBOM));
        }
    },
    onSetCustomQuoteRequires: (isCustomQuoteRequires) => {
        dispatch(setIsCustomQuoteRequires(isCustomQuoteRequires))
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(ReviewBOMPage);