import { configureStore } from '../redux/store';
import UserManager from '../constants/UserManager';
import { loadUser } from "redux-oidc";
import { SessionStorageService } from './SessionStorageService';
import { history } from '../History';
import { DATA_CONSTANT } from '../constants/DataConstant';

const PortCQTenantId = process.env.REACT_APP_TENANTID;
let store = configureStore();

export const OIDCService = {
    login: () => {
        if (!SessionStorageService.getPostLoginUrl()) {
            SessionStorageService.setPostLoginUrl(window.location.href);
        }
        const user = store.getState().oidc.user;
        if (!user || user.expired) {
            let userState = {
                'state': { uri: JSON.stringify(window.location.href) }
            };
            UserManager.signinRedirect(userState);
            return false;
        }
        return true;
    },
    logout: () => {
        const user = store.getState().oidc.user;
        UserManager.removeUser();
        UserManager.signoutRedirect({ 'id_token_hint': user.id_token }).catch((error) => {
            this.handleError(error);
            history.push(DATA_CONSTANT.routes.error, 500);
        });
    },
    loadUser: async () => new Promise((resolve, reject) => {
        loadUser(store, UserManager).then(function (user) {
            resolve(user);
        }).catch(function (err) { resolve(err); history.push(DATA_CONSTANT.routes.error, 500); });
    }),
    getUser: () => {
        return store.getState().oidc.user;
    },
    setUser: (user) => {
        store.dispatch({
            type: 'redux-oidc/USER_FOUND',
            payload: user,
        });
        let oidcSessionUrl = "oidc.user:" + process.env.REACT_APP_IDENTITY_SERVER_URL + ":" + process.env.REACT_APP_CLIENTID;
        SessionStorageService.removeSessionValue(oidcSessionUrl);
        SessionStorageService.setSessionValue(oidcSessionUrl, JSON.stringify(user));
    },
    getUserId: () => {
        let user = store.getState().oidc.user;
        let userId = (user && user.profile) ? user.profile.sub : "";
        return userId;
    },
    hasUserAccessRights: () => {

        const user = store.getState().oidc.user;
        if (user.profile.IsSuperAdmin && user.profile.IsSuperAdmin.toLowerCase() == 'true') {
            return true;
        }

        let allowedTenantIds = user.profile.AllowedTenantIds;
        if (allowedTenantIds) {
            if ((!!allowedTenantIds == false ||
                (!!allowedTenantIds == true && !allowedTenantIds.split(',').some(o => o == PortCQTenantId))) &&
                user.profile.IsSuperAdmin != 'True') {
                return false;
            }
            return true;
        }

        if (user.profile.HasAccessToApplication != null && user.profile.HasAccessToApplication != undefined) {
            return user.profile.HasAccessToApplication.toLowerCase() === 'true';
        }
        return false;
    },
    isEmailConfirmed: () => {
        const user = store.getState().oidc.user;
        if (user != undefined && user.profile.EmailConfirmed?.toLowerCase() == 'false') {
            return false;
        }
        return true;
    },
}
UserManager.events.addUserSignedOut(() => {
    OIDCService.logout();
});
UserManager.events.addUserLoaded(() => { });

UserManager.events.addSilentRenewError(function (e) {
    if (e.message == 'Unauthorized') {
        history.push(DATA_CONSTANT.routes.error, 401);
    }
});