import React from 'react';
import { Switch, Router, Route } from 'react-router-dom';
import { RouteConstant } from './constants/RouteList';
import RfqProcessLayout from './layouts/RfqProcessLayout';
import PrivateRoutes from '../src/Routes/PrivateRoute';
import PublicRoutes from '../src/Routes/PublicRoute';
import ErrorPage from './pages/Error/Error';
import { history } from './History';

const Routes = () => {

    const noLayoutRoutes = RouteConstant.routeList.filter((route) => (route.layout == null));
    const rfqProcessLayoutRoutes = RouteConstant.routeList.filter((route) => (route.layout == RfqProcessLayout));

    return (
        <Switch>
            {
                noLayoutRoutes.map((route) => {
                    if (route.loginRequired) {
                        return <PrivateRoutes exact={route.exact} path={route.path} component={route.component} title={route.title} key={`route_${route.name}`} />
                    } else {
                        return <PublicRoutes exact={route.exact} path={route.path} component={route.component} title={route.title} key={`route_${route.name}`} />
                    }
                })
            }

            <Route exact path={rfqProcessLayoutRoutes.map((route) => (route.path))}>
                <RfqProcessLayout>
                    <Switch>
                        {
                            rfqProcessLayoutRoutes.map((route) => {
                                if (route.loginRequired) {
                                    return <PrivateRoutes exact={route.exact} path={route.path} component={route.component} title={route.title} key={`route_${route.name}`} />
                                } else {
                                    return <PublicRoutes exact={route.exact} path={route.path} component={route.component} title={route.title} key={`route_${route.name}`} />
                                }
                            })
                        }
                    </Switch>
                </RfqProcessLayout>
            </Route>

            <PublicRoutes component={ErrorPage} statusCode={404} />
        </Switch>
    );
}
export default Routes;