import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';

export const BOMService = {

    createBOM: (data) => {
        return CommonHttpService.httpPost(URL_CONSTANT.BOM.CreateBOM, data);
    },

    getPCBLinesByAssemblyId: (assemblyId) => {
        const url = `${URL_CONSTANT.BOM.GetPCBLinesByAssemblyId}?assemblyId=${assemblyId}`;
        return CommonHttpService.httpGet(url);
    },
    isScrubMyBomRunning: (assemblyId) => {
        const url = `${URL_CONSTANT.BOM.IsScrubMyBomRunning}?assemblyId=${assemblyId}`;
        return CommonHttpService.httpGet(url);
    }
}