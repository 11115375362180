import React from "react";
import './PricingCompilingScreen.css'
import { ProgressBar, Row, Col, } from 'react-bootstrap';
import AnimatedPricingGif from './AnimatedPricingGif.gif';

const PricingCompilingScreen = ({ pricingStatus }) => {
    return (
        <>
            <Row>
                <Col md={8} sm={6} xs={6}>
                    <h2>Checking component availability...</h2>
                    <p>This only takes a few minutes. Hang in there while we check availability!</p>
                </Col>
                <Col md={4} sm={6} xs={6}>
                    <img src={AnimatedPricingGif} className="cm-animatedgif" />
                </Col>
            </Row>
            <Row>
                <Col md={12} sm={12} xs={12}>
                    {
                        !pricingStatus.isCompleted && (
                            <ProgressBar now={pricingStatus.pricingCompletedPercentage == 0 ? 1 : pricingStatus.pricingCompletedPercentage} label={`${pricingStatus.pricingCompletedPercentage == 0 ? 1 : pricingStatus.pricingCompletedPercentage}%`} striped animated />
                        )
                    }
                </Col>
            </Row>
            <Row>
                <Col md={12} sm={12} className="cm-marketing-content">
                    <p className="cm-marketing-text m-3">
                        Did you know … Advanced Assembly was the first quick-turn, prototype PCB assembly provider?
                        Trust us with your design. We have the experience to assemble it right and on-time, every time.
                    </p>
                </Col>
            </Row>
        </>
    );
};

export default PricingCompilingScreen;
