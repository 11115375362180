import { CLEAR_STORE, SET_RFQ_DETAILS, SET_EXTERNAL_USERID } from '../../constants/ActionConstants';

const InitialState = {};
export const RFQDetails = (state = InitialState, action) => {
    const { type, data } = action;

    switch (type) {
        case SET_RFQ_DETAILS:
            return { ...state, rfqDetails: data.rfqDetails };
        case SET_EXTERNAL_USERID:
            return { ...state, externalUserId: data.externalUserId };
        case CLEAR_STORE:
            return InitialState;
        default:
            return state;
    }
}