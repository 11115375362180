import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Home.css';
import HeaderWithMenu from '../../components/Header/HeaderWithMenu';
import { Container, Row, Col } from 'react-bootstrap';
import JobTypeList from '../../components/JobType/JobTypeList';
import { PrimaryOrangeButton } from '../../components/Common/InputButton/InputButton';
import { connect } from 'react-redux';
import { resetCompletedSteps } from '../../redux/actions/CompletedStepActions';
import { clearBom } from '../../redux/actions/SetBomIdAction';
import { ClearPricingRunningStatus } from '../../redux/actions/PricingRunnigStatusActions';
import { clearIsCustomQuoteRequires } from "../../redux/actions/SetCustomQuoteRequire";
import { DATA_CONSTANT } from '../../constants/DataConstant';
import MarketingContent from '../../components/MarketingContent/MarketingContent';
import { trackPromise } from 'react-promise-tracker';
import { selectJobType } from '../../redux/actions/JobTypeActions';
import { JobTypeService } from '../../services/JobTypeService';
import { setRFQDetails } from '../../redux/actions/RFQDetailActions';

const Home = ({ onSelectJobType, selectedJobType, setJobType }) => {
    const history = useHistory();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

        trackPromise(isBomAnalysisEnabled());
    }, []);

    const isBomAnalysisEnabled = async () => {
        //let isBomAnalysis = await ConfigurationService.isBomAnalysisEnabled();
        let isBomAnalysis = process.env.REACT_APP_BOM_ANALYSIS_ENABLED;
        if (isBomAnalysis && isBomAnalysis == 'true') {

            onSelectJobType();
            let jobTypeData = await JobTypeService.getBOMAnalysisJobTypeList();
            if (jobTypeData && jobTypeData.jobTypes && jobTypeData.jobTypeSelection) {

                let jobTypeSelection = jobTypeData.jobTypeSelection[0];
                let jobType = jobTypeData.jobTypes[0];

                setJobType({
                    cqJobType: jobTypeSelection.quoteCQJobTypeName,
                    isvalid: jobTypeSelection.isValid,
                    isBomRequired: jobTypeSelection.isBomRequired,
                    isPcbRequired: jobTypeSelection.isPcbRequired,
                    isAssemblyRequired: jobTypeSelection.isAssemblyRequired,
                    isComponentsRequired: jobTypeSelection.isComponentsRequired,
                    isBomAnalysis: jobType.isBomAnalysis
                });

                history.push(DATA_CONSTANT.routes.rfq);
            }

        } else {
            setIsLoaded(true);
        }

    }

    const handleNextClicked = () => {
        onSelectJobType();
        history.push(DATA_CONSTANT.routes.rfq);
    }

    return (
        isLoaded && <div className="body-wrapper">
            <HeaderWithMenu />
            <div id="main-wrapper">
                <Container fluid>
                    <Row>
                        <Col lg={{ span: 8, offset: 2 }} md={12} sm={12} xs={12}>
                            <Row>
                                <h1>Start Your Quote</h1>
                            </Row>

                            <Row className="pb-3 mar-top-20">
                                <h3>Select items to be quoted.</h3>
                            </Row>

                            <div className="job-type-dynamic-section">
                                <JobTypeList />
                                {
                                    selectedJobType?.isvalid && (
                                        <div className="text-center mt-5 ">
                                            <PrimaryOrangeButton name="Next" handleClick={() => handleNextClicked()} />
                                        </div>
                                    )}
                            </div>
                            <MarketingContent />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    selectedJobType: state.JobTypes?.selectedJobType
});

const mapDispatchToProps = dispatch => ({
    onSelectJobType: () => {
        dispatch(resetCompletedSteps());
        dispatch(clearBom());
        dispatch(ClearPricingRunningStatus());
        dispatch(clearIsCustomQuoteRequires({ IsCustomQuoteRequires: false }));
        dispatch(setRFQDetails({}));
    },
    setJobType: jobType => dispatch(selectJobType(jobType))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home)