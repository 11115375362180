import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import Logo from '../Logo/Logo';
import { OIDCService } from '../../services/OIDCService';
import Swal from 'sweetalert2';
import { DATA_CONSTANT } from '../../constants/DataConstant';
import { useHistory } from "react-router-dom";
import { SessionStorageService } from "../../services/SessionStorageService";

const TopNavMenu = () => {
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const history = useHistory();

    useEffect(() => {
        checkUser();
    }, []);

    const userSignIn = () => {
        SessionStorageService.removePostLoginUrl();
        OIDCService.login();
    }

    const userSignOut = () => {
        OIDCService.logout();
    }

    const checkUser = async () => {
        let user =  OIDCService.getUser();
        if (user != null) {
            let hasUserAccessRight = OIDCService.hasUserAccessRights();
            let hasEmailConfirmed = OIDCService.isEmailConfirmed();
            if (hasEmailConfirmed == false && !window.location.pathname.includes('emailActivationPending')) {
                let postLoginRoute = SessionStorageService.getPostLoginUrl();
                if (!postLoginRoute) {
                    SessionStorageService.setPostLoginUrl(window.location.href);
                }
                history.push(DATA_CONSTANT.routes.emailActivationPending);
            }
            if (hasUserAccessRight) {
                setIsUserLoggedIn(true);
            }
            else {
                history.push(DATA_CONSTANT.routes.error, 401);
            }

        }
    }

    const handleShow = () => {
        Swal.fire({
            text: "Are you sure want to Logout?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: DATA_CONSTANT.OrangeColor,
            cancelButtonColor: DATA_CONSTANT.BlueColor,
            confirmButtonText: 'Logout',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                userSignOut();
            }
        })
    };


    return (
        <>
            <Navbar collapseOnSelect expand="lg" variant="dark">
                <Logo />
                <Navbar.Toggle aria-controls="topnavbar" />
                <Navbar.Collapse id="topnavbar" className="flex-row-reverse">
                    <Nav className="pt-3 cm-main-nav">
                        <Nav.Item>
                            <Nav.Link href="https://aapcb.com/about/" target="_blank">About</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="https://aapcb.com/quick-turn-pcb/" target="_blank">Services</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="https://aapcb.com/our-capabilities/" target="_blank" >Capabilities</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="https://aapcb.com/ebooks/" target="_blank">Knowledge Center</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="https://portal.aapcb.com/" target="_blank" >Instant Pricing</Nav.Link>
                        </Nav.Item>
                        {!isUserLoggedIn && <Nav.Item>
                            <Nav.Link href="#" onClick={userSignIn}>Login</Nav.Link>
                        </Nav.Item>
                        }
                        {
                            isUserLoggedIn &&
                            <Nav.Item>
                                <Nav.Link href="#" onClick={handleShow}>Logout</Nav.Link>
                            </Nav.Item>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>)
}

export default TopNavMenu;