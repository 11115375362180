import React from 'react';
import './BOMSummary.css';
import { Row } from 'react-bootstrap';

const BOMSummary = (props) => {
    return (
        <>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <Row>
                    <h2>Here is your BOM</h2>
                </Row>
            </div>
            <div className="col-lg-12 col-md-12  col-sm-12 col-xs-12 reviewbomrecord">
                <Row>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 bomSummayDetailBlock">
                        <span className="bomSummaryItems">{props.bomSummaryData.smtPartClass}</span>
                        <br />
                        <span className="bomSummaryItemDescription">Number of SMT</span>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 bomSummayDetailBlock">
                        <span className="bomSummaryItems">{props.bomSummaryData.thPartClass}</span>
                        <br />
                        <span className="bomSummaryItemDescription">Number of TH</span>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 bomSummayDetailBlock">
                        <span className="bomSummaryItems">{props.bomSummaryData.totalLines}</span>
                        <br />
                        <span className="bomSummaryItemDescription">Total number of lines</span>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 bomSummayDetailBlock">
                        <span className="bomSummaryItems totalIssue">{props.bomSummaryData.totalIssues}</span>
                        <br />
                        <span className="bomSummaryItemDescription">Number of issues</span>
                    </div>
                </Row>
            </div>
        </>
    )
}

export default BOMSummary;