import React from "react";
import PropTypes from "prop-types";
import { FormLabel, FormCheck } from "react-bootstrap";
import InfoToolTip from "../InfoToolTip";
const InputCheck = React.forwardRef(
    (
        {
            id,
            name,
            value,
            type,
            onChange,
            isRequired, // Displays * for required field
            list, // List to bind drop down list
            listLabel, // Label property from list
            listValue, // Value property from list
            label,
            errorMessage,
            helpText, // Displays information tooltip
            helpPlacement, // Placement for tooltip
            className, //Classname for tooltip
            selectedValues, //Array for selected values
            checked,
            ...props
        },
        ref
    ) => {
        if (list
            && (list.length == 0
                || (typeof list[0] == "object" && !listValue && !list[0].hasOwnProperty(listValue))
                )
            ) {
            return <></>;
        }

        return (
            <>
                {label && label.trim() && (
                    <>
                        <FormLabel> {label} </FormLabel>
                        {isRequired && <span className="error-txt">*</span>}
                        {helpText && helpText.trim() && (
                            <InfoToolTip
                                // className={className}
                                helpText={helpText}
                                helpPlacement={helpPlacement}
                            />
                        )}
                        <br />
                    </>
                )}

                {list &&
                    list.length > 0 &&
                    list.map &&
                    list.map((item, index) => {
                        {
                            listLabel =
                                listLabel && list.hasOwnProperty(listLabel)
                                    ? listLabel
                                    : listValue;
                        }
                        return (
                            <React.Fragment
                                key={typeof item == "string" ? item : item[listValue]}
                            >
                                <FormCheck
                                    className={className}
                                    type={type}
                                    key={typeof item === "string" ? item : item[listValue]}
                                    id={typeof item === "string" ? item : item[listValue]}
                                    name={name}
                                    value={typeof item == "string" ? item : item[listValue]}
                                    onChange={onChange}
                                    label={<span>{typeof item == "string" ? item : item[listLabel]}</span>}
                                    checked={typeof item === "string" ?
                                        selectedValues?.indexOf(item) > -1 ? true : false :
                                        selectedValues?.indexOf(item[listValue]) > -1 ? true : false}
                                    ref={ref}
                                    {...props}
                                />
                                {type == "switch" && <br />}
                            </React.Fragment>
                        );
                    })}

                {!list && 
                    <React.Fragment>
                        <FormCheck
                            className={className}
                            type={type}
                            id={id}
                            label={value}
                            name={name}
                            value={value}
                            checked={checked}
                            onChange={onChange}
                            ref={ref}
                            {...props}
                        />
                        {type == "switch" && <br />}
                    </React.Fragment>
                }

                {errorMessage && errorMessage[name] && errorMessage[name].message && (
                    <span className="error-txt">{errorMessage[name].message}</span>
                )}
            </>
        );
    }
);

InputCheck.defaultProps = {
    type: "checkbox",
    required: false,
    className: "",
    selectedValues: []
};

InputCheck.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["radio", "checkbox", "switch"]),
    value: PropTypes.any,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    list: PropTypes.array,
    listLabel: PropTypes.string,
    listValue: PropTypes.string,
    helpText: PropTypes.string,
    placement: PropTypes.oneOf(["right", "left", "top", "bottom"]),
    className: PropTypes.string,
    selectedValues: PropTypes.array,
};

export default InputCheck;
