import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import { AddButton, RemoveButton } from "../Common/InputButton/InputButton";
import { useForm } from "react-hook-form";
import { MESSAGE_CONSTANT } from "../../constants/MessageConstant";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { max } from 'underscore';
import { InputDate } from "../../components/ControlComponents/Input/index";
import { CommonService } from '../../services/CommonService';
import moment from "moment";

const QuantityList = (props) => {
    const timeUOMUnit = "Day(s)";
    const leadDays = 3;
   
    const getInitialTurnTimeValue = () => {
        let startDate = moment(new Date());
        let selectedDate = moment(CommonService.addWorkDays(new Date(), leadDays));
        let days = selectedDate.diff(startDate, 'days') + 1;
        return days;
    }
    const { register, errors, trigger, reset } = useForm();
    const [boardQuantities, setBoardQuantities] = useState([
        {
            custQtyId: 0, quantity: "",
            turntimeUom: {
                timeUomValue: getInitialTurnTimeValue(),
                timeUom: timeUOMUnit,
                timeUomDate: CommonService.addWorkDays(new Date(), 4)
            }
        }
    ]);

    useEffect(() => {
        if (props.startValidation) {
            trigger().then(function (result) {
                let isValid = result;
                if (isValid) {
                    props.handleQuantityData(boardQuantities);
                }
            });

            if (props.onValidationReset) props.onValidationReset();
        }
    }, [props.startValidation, props.onValidationReset, trigger]);

    const addQuantity = () => {
        if (boardQuantities.length < 3) {
            let maxQtyId = max(boardQuantities, function (o) { return o.custQtyId; }).custQtyId;
            setBoardQuantities([
                ...boardQuantities,
                {
                    custQtyId: maxQtyId + 1,
                    quantity: "",
                    turntimeUom: { timeUomValue: getInitialTurnTimeValue(), timeUom: timeUOMUnit, timeUomDate: CommonService.addWorkDays(new Date(), 4) },
                },
            ]);
        }
    };

    const removeQuantity = (custQtyId) => {
        let updatedQuantities = boardQuantities.filter(
            (qty) => qty.custQtyId !== custQtyId
        );
        if (updatedQuantities.length === 0) {
            updatedQuantities = [
                {
                    custQtyId: 0,
                    quantity: "",
                    turntimeUom: { timeUomValue: getInitialTurnTimeValue(), timeUom: timeUOMUnit, timeUomDate: CommonService.addWorkDays(new Date(), 3) },
                },
            ];
        }
        reset();
        setBoardQuantities(updatedQuantities);
    };

    const handleQtyChanged = (index, event) => {
        trigger(event.target.name);
        const qty = event.target.value;
        const copiedBoardQuantities = [...boardQuantities];
        copiedBoardQuantities[index].quantity = qty;
        setBoardQuantities(copiedBoardQuantities);
    };

    const handleTurnChanged = (date, name) => {
        let startDate = moment(new Date());
        let endDate = moment(date);
        let totalDays = endDate.diff(startDate, 'days') + 1;
        let copiedBoardQuantities = [...boardQuantities];
        let propertyName = name.split("_")[0];
        let qtyIndex = name.split("_")[1];
        let copiedTurnTime = copiedBoardQuantities[qtyIndex].turntimeUom;
        copiedTurnTime[propertyName] = totalDays;
        copiedTurnTime['timeUomDate'] = date;
        copiedBoardQuantities[qtyIndex].turntimeUom = copiedTurnTime;
        setBoardQuantities(copiedBoardQuantities);
    };


    return (
        <div>
            {boardQuantities.map((qty, qtyIndex) => (
                <Container fluid key={qtyIndex} className="quantity-wrapper">
                    <Row>
                        <Col lg={6} className="mb-2">
                            <Row className="pr-0">
                                <Col className="pb-0">
                                    <label>Quantity</label>
                                    <span className="error-txt">*</span>
                                </Col>
                            </Row>
                            <Row className="pr-0">
                                <Col md={10}>
                                    <FormControl
                                        name={`Qty${qty.custQtyId}`}
                                        type="text"
                                        className="form-control"
                                        value={qty.quantity}
                                        onChange={(event) => handleQtyChanged(qtyIndex, event)}
                                        ref={register({
                                            required: MESSAGE_CONSTANT.quantity.required,
                                            pattern: {
                                                value: DATA_CONSTANT.patterns.quantity,
                                                message: MESSAGE_CONSTANT.quantity.invalid,
                                            },
                                        })}
                                    />
                                    <span className="error-txt">
                                        {errors[`Qty${qty.custQtyId}`] &&
                                            errors[`Qty${qty.custQtyId}`].message}
                                    </span>
                                </Col>
                                <Col md={2} className="p-md-0 mt-1">
                                    <AddButton handleClick={() => addQuantity(qtyIndex)} />
                                    <RemoveButton
                                        className="mar-left-10"
                                        handleClick={() => removeQuantity(qty.custQtyId)}
                                    />
                                </Col>
                            </Row>
                            <Row className="pt-1">
                                <Col>
                                    <span className="cm-global-suggestion-text">
                                        Click the green "+" button to add additional quantities.
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        {!props.isBomAnalysis && (
                            <Col lg={6}>
                                <div key={`requestedDate_${qtyIndex}`}>
                                    <InputDate
                                        label="How Fast Do You Need Them? Pick a Ship Date"
                                        isRequired
                                        name={"timeUomValue_" + qtyIndex}
                                        value={qty.turntimeUom.timeUomDate}
                                        selected={CommonService.addWorkDays(new Date(), 4)}
                                        onChange={handleTurnChanged}
                                        startDate={CommonService.addWorkDays(new Date(), 4)}
                                        disableStartDate={new Date()}
                                        disableEndDate={CommonService.addWorkDays(new Date(), 3)}
                                        errorMessage={"Invalid date"}
                                        dayClassName={(date) =>
                                            [0, 6].indexOf(date.getDay()) > -1 ||
                                                moment(date).isBefore(moment())
                                                ? "disabled-date"
                                                : undefined
                                        }
                                    ></InputDate>
                                </div>
                                <div className="ship-date-suggestion-txt">
                                    Need a faster turn-time? No problem. Send files to
                                    <a href="mailto:sales@aapcb.com" className="suggestion-linktxt">sales@aapcb.com.</a>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Container>
            ))}
        </div>
    );
};

export default QuantityList;