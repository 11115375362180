import { CLEAR_CUSTOMQUOTE_REQUIRES,SET_CUSTOMQUOTE_REQUIRES } from '../../constants/ActionConstants';

const InitialState = { IsCustomQuoteRequires: false };

export const CustomQuote = (state = InitialState, action) => {
    const { type, data } = action;
    switch (type) {
        case SET_CUSTOMQUOTE_REQUIRES:
            return { ...state, IsCustomQuoteRequires: data.IsCustomQuoteRequires };
        case CLEAR_CUSTOMQUOTE_REQUIRES:
            return InitialState;
        default:
            return state;
    }
}