import { CommonHttpService } from "./CommonHttpService";
import { URL_CONSTANT } from '../constants/UrlConstant';

export const JobTypeService = {

    getJobTypeList: () => {
        const url = URL_CONSTANT.JobType.GetJobTypes;
        return CommonHttpService.httpGet(url);
    },

     getBOMAnalysisJobTypeList: () => {
         const url = URL_CONSTANT.JobType.GetBOMAnalysisJobTypes;
        return CommonHttpService.httpGet(url);
    }
}