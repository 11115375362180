import React, { useState, useEffect, useRef } from "react";
import "./RFQ.css";
import { Container, Row, Col, FormControl, Button } from "react-bootstrap";
import QuantityList from "../../components/Quantities/QuantityList";
import DocumentUploader from "../../components/Common/DocumentUploader";
import {
    PrimaryOrangeButton,
    RemoveButton,
} from "../../components/Common/InputButton/InputButton";
import { connect } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { RequestFormService } from "../../services/RequestFormService";
import { setDefaultConfigs } from "../../redux/actions/DefaultConfigActions";
import { useHistory } from "react-router-dom";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { setRFQDetails } from "../../redux/actions/RFQDetailActions";
import { setBomFile } from "../../redux/actions/SetBomFIleAction";
import { useForm } from "react-hook-form";
import { MESSAGE_CONSTANT } from "../../constants/MessageConstant";
import { setCompletedSteps } from "../../redux/actions/CompletedStepActions";
import { DocumentService } from "../../services/DocumentService";
import { CommonService } from "../../services/CommonService";
import { ConfigurationService } from "../../services/ConfigurationService";
import FormatBom from "../../components/FormatBom/FormatBom";
import ImportBOMModal from "../BOM/ImportBOMModal";
import { setBom } from "../../redux/actions/SetBomIdAction";
import XRegExp from 'xregexp/xregexp-all';
import CheckListBuilder from '../../components/Common/CheckListBuilder';
import _ from "underscore";
import { OIDCService } from '../../services/OIDCService';
import { UploadDocumentService } from "../../services/UploadDocumentService";
import { setExternalUserId } from "../../redux/actions/RFQDetailActions";
import { selectJobType } from "../../redux/actions/JobTypeActions";

const UploadBomPage = ({
    selectedJobType,
    onDefaultConfigsLoad,
    onRFQCreated,
    onBOMFileSelected,
    onSetExternalUserId,
    selectedBomFile,
    onBOMCreated,
    completedSteps,
    assemblyId,
    salesPersonId,
}) => {
    const history = useHistory();

    const isUploadBomRequired = selectedJobType?.isBomRequired;

    const isGerberFileRequired = selectedJobType?.isPcbRequired;

    const isFileTypeDropDownVisible = isUploadBomRequired || isGerberFileRequired;

    const needToAddManualPCBLine = selectedJobType?.isPcbRequired && !selectedJobType?.isBomRequired;

    const allowedFileTypes = DATA_CONSTANT.allowedFileTypes;

    const [validateChild, setValidateChild] = useState(false);

    const [acceptedFiles, setAcceptedFiles] = useState([]);

    const [rejectedFiles, setRejectedFiles] = useState([]);

    const [defaultConfig, setDefaultConfig] = useState({});

    const [isLoaded, setIsLoaded] = useState(false);

    const [contactInfo, setContactInfo] = useState({
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phone: "",
        state: "",
    });

    const { register, errors, trigger } = useForm();

    const childRef = useRef();

    const [bomOrGerberFile, setBomOrGerberFile] = useState([{ filename: "", type: "" }]);

    const [bomFileValidation, setBomFileValidation] = useState({
        message: "",
        isDisplayBOM: false,
    });

    const [gerberFileValidation, setGerberFileValidation] = useState({
        message: "",
        isDisplayGerber: false,
    });

    const [isSubmitted, setSubmitted] = useState(false);

    const [isTooManyFilesError, setTooManyFileError] = useState(false);

    const [isRFQSubmitted, setIsRFQSubmitted] = useState(false);

    const [bomDataInfo, setBomDataInfo] = useState({
        assemblyId: "",
        file: undefined,
        userId: "",
        salesPersonEmail: ""
    });

    const [checkListBuilderDataToSubmit, setCheckListBuilderDataToSubmit] = useState([]);

    const [checkListBuilderData, setCheckListBuilderData] = useState({ partSubstitutionsAllowed: false, partTraceabilityRequired: false });
    const [isTypeChange, setIsTypeChange] = useState(false);
    const [maxNumberOfFileToUpload, setMaxNumberOfFileToUpload] = useState(0);

    const [isDisableContactInfo, setIsDisableContactInfo] = useState({
        firstName: false,
        lastName: false,
        company: false,
        email: false,
        phone: false,
        state: false,
    });

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    useEffect(() => {
        if (!selectedJobType?.cqJobType) {
            history.push("/");
            return;
        }
        getmaxNumberOfFileToUpload();
        trackPromise(getDefaultConfigurations(selectedJobType.cqJobType));
    }, []);

    useEffect(() => {
        if (isSubmitted) {
            checkBomFileValidation();
        }
    }, [isTypeChange, isSubmitted]);

    useEffect(() => {
        if (defaultConfig.customerId) {
            let user = OIDCService.getUser();
            if (user != null) {
                let userId = user.profile ? user.profile.sub : null;
                onSetExternalUserId(userId);
                let loggedInUserEmail = user.profile.Email;
                trackPromise(getCustomerContactByEmail(loggedInUserEmail))
            }
        }
    }, [defaultConfig.customerId])

    useEffect(() => {
        if (isUserLoggedIn) {
            setIsDisableContactInfo({
                firstName: contactInfo.firstName ? true : false,
                lastName: contactInfo.lastName ? true : false,
                email: contactInfo.email ? true : false,
                company: contactInfo.company ? true : false,
                phone: contactInfo.phone ? true : false,
                state: contactInfo.state ? true : false
            });
        }
    }, [isUserLoggedIn])

    const getCustomerContactByEmail = async (loggedInUserEmail) => {
        await RequestFormService.GetCustomerContactByEmail(loggedInUserEmail).then((data) => {
            let customerDetail = {};
            if (data.length > 0) {
                let customerId = defaultConfig.customerId;
                customerDetail = data.find(x => x.customerId === customerId); //Get customer contact info by customerId
                customerDetail = !customerDetail ? data[0] : customerDetail;
                if (customerDetail) {
                    setContactInfo({
                        firstName: customerDetail.firstName,
                        lastName: customerDetail.lastName,
                        email: customerDetail.email,
                        company: customerDetail.companyName,
                        phone: customerDetail.phone,
                        state: customerDetail.state
                    });
                }
            }
            else {
                let user = OIDCService.getUser();
                if (user != null) {
                    setContactInfo({
                        firstName: user.profile.FirstName,
                        lastName: user.profile.LastName,
                        email: user.profile.Email
                    });
                }
            }
            setIsLoaded(true);
            setIsUserLoggedIn(true);
        });
    }

    const getUserId = () => {
        let userId = salesPersonId;
        return userId;
    };

    const getLoggedInUserId = () => {
        let userId = null;
        let user = OIDCService.getUser();
        if (user != null) {
            // If user logged in then user id is sub
            userId = user.profile ? user.profile.sub : null;
        }
        return userId;
    };

    const getDefaultConfigurations = async (jobType) => {
        let jobTypeName = encodeURIComponent(jobType);
        await RequestFormService.getDefaultConfigurations(jobTypeName).then(
            (data) => {
                if (data) {
                    onDefaultConfigsLoad(data);
                    setDefaultConfig(data);
                    setIsLoaded(true);
                    if (isUploadBomRequired || isGerberFileRequired) {
                        checkIsBOMUploaded(data);
                    }
                }
            }
        );
    };

    const selectAcceptedFiles = (acceptedFiles) => {
        setAcceptedFiles([...acceptedFiles]);
    };

    const selectRejectedFiles = (rejectedFiles) => {
        setRejectedFiles([...rejectedFiles]);
    };

    const selectQuantityData = (data) => {
        trigger().then(function (result) {
            let isValid = result;
            if (isValid) {
                uploadFiles(data);
            }
        });
    };

    const checkBomFileValidation = () => {
        const IsBomSelected = _.filter(bomOrGerberFile, function (item) {
            return item.type === DATA_CONSTANT.FileType.BOM;
        });
        const IsGerberSelected = _.filter(bomOrGerberFile, function (item) {
            return item.type === DATA_CONSTANT.FileType.Gerber;
        });
        if (isUploadBomRequired && IsBomSelected.length < 1) {
            setBomFileValidation({
                message: MESSAGE_CONSTANT.bomFile.required,
                isDisplayBOM: true,
            });
        } else {
            setBomFileValidation({ message: "", isDisplayBOM: false });
        }
        if (isGerberFileRequired && IsGerberSelected.length < 1) {
            setGerberFileValidation({
                message: MESSAGE_CONSTANT.gerberFile.required,
                isDisplayGerber: true,
            });
        } else {
            setGerberFileValidation({ message: "", isDisplayGerber: false });
        }
    };

    const StartChildValidation = () => {
        setTooManyFileError(false);
        setValidateChild(true);
        setSubmitted(true);
        trigger();
        checkBomFileValidation();
    };

    const handleContactInfoChanged = (event, isValidate) => {
        if (isValidate) {
            trigger(event.target.name);
        }
        const value = event.target.value;
        const name = event.target.name;
        setContactInfo({
            ...contactInfo,
            [name]: value,
        });
    };

    const checkIsBOMUploaded = (defaultConfiguration) => {
        let isRFQCompleted = completedSteps.some(
            (o) => o == DATA_CONSTANT.stepNames.RFQ
        );
        let isBOMCompleted = completedSteps.some(
            (o) => o == DATA_CONSTANT.stepNames.BOM
        );
        if (isRFQCompleted) {
            if (isBOMCompleted) {
                redirectToNextScreen();
            }
            if (!isBOMCompleted) {
                let userId = getUserId();
                setBomDataInfo({
                    assemblyId: assemblyId,
                    file: undefined,
                    selectedBomFileName: selectedBomFile?.name,
                    userId: userId,
                    salesPersonEmail: defaultConfiguration.salesPersonEmail
                });
                setIsRFQSubmitted(true);
            }
        }
    };

    const uploadFiles = async (data) => {
        let quantityData = data;
        //component jobType selected but BOM file not uploaded by user then return
        if (bomFileValidation.isDisplayBOM) {
            return;
        } else if (gerberFileValidation.isDisplayGerber) {
            return;
        }

        //Add checklistbuilder object
        let objForCheckListBuilder = {};
        objForCheckListBuilder.RFQId = 0;
        objForCheckListBuilder.RFQCustomFieldValues = [];

        checkListBuilderDataToSubmit.map((vals, inds) => {
            var objValues = {};
            if ((vals.fieldType == "CheckBox" || vals.fieldType == "DropDown") && vals.defaultValue != null) {
                if (Array.isArray(vals.defaultValue)) {
                    vals.defaultValue = vals.defaultValue.join(",");
                }
            } else if (vals.defaultValue == null) {
                vals.defaultValue = "";
            }
            objValues.FieldTypesName = vals.name;
            objValues.FieldTypeValue = vals.defaultValue;
            objForCheckListBuilder.RFQCustomFieldValues.push(objValues);
        });

        let requestForm = {
            Active: true,
            CustomerName: defaultConfig.customerCompanyName,
            JobTypeName: selectedJobType.cqJobType,
            OrderTypeName: defaultConfig.orderTypeName,
            RFQSubmittedByName: defaultConfig.salesPersonEmail,
            CustomerId: defaultConfig.customerId,
            UserId: getUserId(),
            IsPCBSelected: selectedJobType?.isPcbRequired,
            IsBomAnalysis: selectedJobType?.isBomAnalysis,
            TimeZoneOffset: defaultConfig.timeZoneOffset,
            PortCQCustomer: {
                CustomerFirstName: contactInfo.firstName,
                CustomerLastName: contactInfo.lastName,
                CompanyName: contactInfo.company,
                Email: contactInfo.email,
                PhoneNumber: contactInfo.phone,
                State: contactInfo.state,
            },
            upsertRFQChecklistRequest: {
                RFQId: 0,
                RFQCustomFieldValues: objForCheckListBuilder.RFQCustomFieldValues
            },
            UserCompanyDetailName: defaultConfig.userCompanyName,
        };
        requestForm.Assemblies = [];
        requestForm.AssemblyDocuments = [];
        let mappingObj = [];
        let mappingAssemblyDocuments = [];

        quantityData.map(({ quantity, turntimeUom }) => {
            let Quantities = {};
            Quantities.RequestQuantity = quantity;
            Quantities.TurnTimes = [];
            let turntimes = {};
            turntimes.TurnAroundTime = turntimeUom.timeUomValue;
            turntimes.TimeUoMId = defaultConfig.timeUOMId;
            turntimes.TimeUoMName = turntimeUom.timeUom;
            turntimes.TurntimeReferenceId = defaultConfig.turntimeReferenceId;
            turntimes.TurntimeReferenceName = defaultConfig.turntimeReferenceName;
            turntimes.TimeUomDate = turntimeUom.timeUomDate;
            turntimes.RequestQuantity = quantity;
            Quantities.TurnTimes.push(turntimes);
            mappingObj.push(Quantities);
        });

        bomOrGerberFile.map((file) => {
            let assemblyDocuments = {};
            assemblyDocuments.DocumentName = file.filename;
            assemblyDocuments.DocumentType = file.type;
            mappingAssemblyDocuments.push(assemblyDocuments);
        })

        requestForm.partSubstitutionsAllowed = checkListBuilderData.partSubstitutionsAllowed;
        requestForm.partTraceabilityRequired = checkListBuilderData.partTraceabilityRequired;
        requestForm.Assemblies.push({ Quantities: mappingObj, IsPCBAssembly: selectedJobType?.isPcbRequired, AssemblyDocuments: mappingAssemblyDocuments, ExternalUserId: getLoggedInUserId() });

        trackPromise(
            RequestFormService.CreateRFQ(requestForm).then((result) => {
                onRFQCreated({
                    ...result,
                    ["customerInfo"]: {
                        ...contactInfo,
                        ["name"]: `${contactInfo.firstName} ${contactInfo.lastName}`,
                    },
                });
                if (acceptedFiles.length > 0) {
                    trackPromise(uploadDocuments(result.assemblyId, acceptedFiles, result));
                }
                else {
                    afterRFQCreated(result);
                }
            })
        );
    };
    const afterRFQCreated = (result) => {

        if (isUploadBomRequired || needToAddManualPCBLine) {
            let userId = getUserId();
            setBomDataInfo({
                assemblyId: result.assemblyId,
                file: acceptedFiles.find((x) => x.name === selectedBomFile?.name),
                selectedBomFileName: selectedBomFile?.name,
                userId: userId,
                salesPersonEmail: defaultConfig.salesPersonEmail
            });
            setIsRFQSubmitted(true);
        } else {
            let nextStepIndex = DATA_CONSTANT.rfqSteps.findIndex(x => x.stepName === DATA_CONSTANT.stepNames.RFQ) + 1;
            history.push(DATA_CONSTANT.rfqSteps[nextStepIndex].routeName);
        }
    };

    const fileDownload = (filename) => {
        DocumentService.downLoadFile(filename);
    };

    const handleFileTypeChanged = (filename, event) => {
        const fileList1 = [...bomOrGerberFile];
        let fileList = [];
        bomOrGerberFile.forEach((item) => {
            if (item.type !== event.target.value && item.filename !== filename && item.filename && item.type) {
                fileList.push(item);
            }
        });
        let bom_File = acceptedFiles.find((x) => x.name === filename);
        let bomBlobUrl = window.URL.createObjectURL(bom_File);
        if (
            CommonService.isValidBOMFile(filename) &&
            event.target.value === DATA_CONSTANT.FileType.BOM
        ) {
            onBOMFileSelected({ src: bomBlobUrl, name: filename });
            fileList.push({ filename: filename, type: DATA_CONSTANT.FileType.BOM });
            setBomOrGerberFile(fileList);
        } else if (
            CommonService.isValidGerberFile(filename) &&
            event.target.value === DATA_CONSTANT.FileType.Gerber
        ) {
            fileList.push({
                filename: filename,
                type: DATA_CONSTANT.FileType.Gerber,
            });
            setBomOrGerberFile(fileList);
        } else {
            let copyFiles = _.filter(fileList1, (x) => {
                return x.filename !== filename;
            });
            setBomOrGerberFile(copyFiles);
        }
        setIsTypeChange(!isTypeChange);
    };

    const tooManyFilesError = (isTooManyFilesError) => {
        setTooManyFileError(isTooManyFilesError);
    };

    const revokeFileObject = (name) => {
        if (selectedBomFile && selectedBomFile.name === name) {
            window.URL.revokeObjectURL(selectedBomFile.src);
            onBOMFileSelected({});
        }
        let remainingFiles = _.filter(bomOrGerberFile, function (item) {
            return item.filename !== name;
        });
        setBomOrGerberFile(remainingFiles);
    };

    const redirectToNextScreen = (bomId) => {
        if (bomId != undefined) {
            onBOMCreated(bomId);
        }
        let currentStep = isUploadBomRequired ? DATA_CONSTANT.stepNames.BOM : DATA_CONSTANT.stepNames.RFQ;
        let nextStepIndex = DATA_CONSTANT.rfqSteps.findIndex(x => x.stepName === currentStep) + 1;
        history.push(DATA_CONSTANT.rfqSteps[nextStepIndex].routeName);
    };

    const getFileType = (file) => {
        if (file) {
            const curfile = _.find(bomOrGerberFile, function (item) {
                return item.filename === file.name;
            });
            if (curfile) return curfile.type;
        }
        return "Select";

        // });
        //   if (file) {
        //     if (bomFile.filename === file.name) {
        //       return bomFile.type;
        //     } else if (gerberFile.filename === file.name) {
        //       return gerberFile.type;
        //     }
        //   }
        //   return "Select";
    };

    const onChangeCheckListBuilderData = (val) => {
        let values = CommonService.deepShallowCopy(val);
        setCheckListBuilderDataToSubmit(values);
        if (values.length > 0) {
            var checkListValues = [...values].shift()?.defaultValue;
            let checkListData = {};
            checkListData.partSubstitutionsAllowed = checkListValues.indexOf("Part Substitutions Allowed") >= 0 ? true : false;
            checkListData.partTraceabilityRequired = checkListValues.indexOf("Part Traceability Required") >= 0 ? true : false;
            setCheckListBuilderData(checkListData);
        }
    }

    const getmaxNumberOfFileToUpload = async () => {
        let maxNumberOfFileTo =
            DATA_CONSTANT.ConfigurationKey.MaxNoOfUploadDocuments;
        let configKeys = [maxNumberOfFileTo];
        await ConfigurationService.getByKeys(configKeys).then((data) => {
            if (data && data.length > 0) {
                setMaxNumberOfFileToUpload(data[0].value);
            }
        });
    };

    const uploadDocuments = async (assemblyId, rfqAcceptedFiles, rfqResult) => {
        if (bomOrGerberFile.length > 0) {
            if (isUploadBomRequired) {
                let bomFile = bomOrGerberFile.find((x) => x.type === DATA_CONSTANT.FileType.BOM);
                if (bomFile != undefined) {
                    let acceptedBomFile = rfqAcceptedFiles.find((x) => x.name === bomFile.filename);
                    acceptedBomFile.filetype = DATA_CONSTANT.FileType.BOM;
                }
            }
            if (isGerberFileRequired) {
                let gerberFile = bomOrGerberFile.find((x) => x.type === DATA_CONSTANT.FileType.Gerber);
                if (gerberFile != undefined) {
                    let acceptedGerberFile = rfqAcceptedFiles.find((x) => x.name === gerberFile.filename);
                    acceptedGerberFile.filetype = DATA_CONSTANT.FileType.Gerber;
                }
            }
            let uploadDocResponse = await UploadDocumentService.uploadDocuments(assemblyId, rfqAcceptedFiles);
            if (uploadDocResponse.length == 0 && rfqResult != undefined)
                afterRFQCreated(rfqResult);
        }
    }
    return (
        isLoaded &&
        <>
            {!isRFQSubmitted &&
                <>
                    <Row>
                        <Col>
                        <h2>{selectedJobType.isBomAnalysis ? 'Quantities' : 'Quantities & Turn Times'}</h2>
                        </Col>
                    </Row>

                    <QuantityList
                        startValidation={validateChild}
                        onValidationReset={() => {
                            setValidateChild(false);
                        }}
                        handleQuantityData={selectQuantityData}
                        isBomAnalysis={selectedJobType?.isBomAnalysis}
                    />

                    <hr />
                    <Row>
                        <Col className="mb-5">
                            <div className="mt-5">
                                <h2>Attached Files</h2>
                            </div>
                            <div className="cm-global-suggestion-text">
                                Add your Gerbers, XYRS data and BOM. These files are required for
                                a quote. Download our preferred &nbsp;
                                <Button
                                    variant="link"
                                    className="link-text p-0 cm-global-suggestion-text"
                                    onClick={() =>
                                        fileDownload(
                                            DATA_CONSTANT.guidelinesFiles.BOM_format_guidelines
                                        )
                                    }
                                >
                                    BOM format guidelines
                                </Button>
                                <span className="suggestion-filetxt">&nbsp; and &nbsp;</span>
                                <Button
                                    variant="link"
                                    className="link-text p-0 cm-global-suggestion-text"
                                    onClick={() =>
                                        fileDownload(DATA_CONSTANT.guidelinesFiles.BOM_template)
                                    }
                                >
                                    template.
                                </Button>
                            </div>
                            <div className="cm-global-suggestion-text">
                                BOM file type must be .xlsx, .xls, or .csv (comma separated.)
                            </div>
                            <div className="cm-global-suggestion-text">
                                Don't have files ready yet? Email
                                <a href="mailto:sales@aapcb.com" className="suggestion-linktxt">
                                    sales@aapcb.com
                                </a>
                                to request a quote.
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={9} sm={12} xs={12}>
                            <DocumentUploader
                                ref={childRef}
                                displayFiles={false}
                                alowedFileTypes={allowedFileTypes}
                                handleAcceptedFiles={selectAcceptedFiles}
                                handleRejectedFiles={selectRejectedFiles}
                                handleTooManyFilesError={tooManyFilesError}
                                maxNumberOfFileToUpload={maxNumberOfFileToUpload}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-5">
                        <Col md={9} sm={12} xs={12}>
                            <Container fluid>
                                {acceptedFiles.length > 0 && (
                                    <Row className="pt-3">
                                        <Col md={1} sm={12} xs={12}></Col>
                                        <Col md={6} sm={12} xs={12}>
                                            <h5>Uploaded files</h5>
                                        </Col>
                                        <Col md={4} sm={12} xs={12}>
                                            {isFileTypeDropDownVisible && <h5>Files type</h5>}
                                        </Col>
                                    </Row>
                                )}
                                {acceptedFiles &&
                                    acceptedFiles.map((file, index) => (
                                        <Row key={index} className="pt-3">
                                            <Col md={1} sm={12} xs={12}>
                                                <RemoveButton
                                                    handleClick={() => {
                                                        if (isUploadBomRequired || isGerberFileRequired) {
                                                            revokeFileObject(file.name);
                                                        }
                                                        childRef.current.handleRemoveFile(file.name);
                                                    }}
                                                />
                                            </Col>
                                            <Col md={6} sm={12} xs={12} className="pt-1 file-name-text">
                                                {file.name}
                                            </Col>
                                            <Col md={4} sm={12} xs={12}>
                                                {isFileTypeDropDownVisible &&
                                                    (selectedJobType?.isPcbRequired
                                                        ? CommonService.isValidGerberFile(file.name)
                                                        : CommonService.isValidBOMFile(file.name)) && (
                                                        <FormControl
                                                            as="select"
                                                            value={getFileType(file)}
                                                            onChange={(event) =>
                                                                handleFileTypeChanged(file.name, event)
                                                            }
                                                        >
                                                            <option>Select</option>
                                                            {isUploadBomRequired &&
                                                                <option className={CommonService.isValidBOMFile(file.name) ? "" : `option-disabled`}>
                                                                    {DATA_CONSTANT.FileType.BOM}
                                                                </option>}
                                                            {isGerberFileRequired && (
                                                                <option>{DATA_CONSTANT.FileType.Gerber}</option>
                                                            )}
                                                        </FormControl>
                                                    )}
                                            </Col>
                                        </Row>
                                    ))}
                                {rejectedFiles.length > 0 && !isTooManyFilesError && (
                                    <Row className="pt-5">
                                        <Col md={10}>
                                            <h4>Rejected files</h4>
                                        </Col>
                                        <Col md={2}>
                                            <RemoveButton
                                                handleClick={() => childRef.current.handleRemoveAllFile()}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {rejectedFiles &&
                                    !isTooManyFilesError &&
                                    rejectedFiles.map(({ file, errors }, index) => (
                                        <Row key={index}>
                                            <Col
                                                md={12}
                                                lg={12}
                                                sm={12}
                                                xs={12}
                                                className="file-name-text"
                                            >
                                                <li key={file.path}>
                                                    {file.path} - {file.size} bytes
                                                    <ul>
                                                        {errors.map((e) => (
                                                            <li key={e.code}>{e.message}</li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            </Col>
                                        </Row>
                                    ))}
                                {isTooManyFilesError && (
                                    <h5 className="error-txt mt-3">{`${MESSAGE_CONSTANT.tooManyFiles} ${maxNumberOfFileToUpload}`}</h5>
                                )}
                            </Container>
                        </Col>
                    </Row>
                    <Row className="mb-3 mt-3">
                        <Col md={{ span: 4, offset: 2 }} sm={12} xs={12}>
                            <CheckListBuilder
                                JobTypeName={selectedJobType}
                                ScreenName="RFQ"
                                onChangeCheckListBuilder={onChangeCheckListBuilderData}
                            />
                        </Col>
                    </Row>
                    <Container fluid>
                        <FormatBom />
                    </Container>
                    <hr />
                    <Row>
                        <Container fluid className="pl-2">
                            <Row>
                                <Col md={6}>
                                    <div className="mt-5 mb-3">
                                        <h2> Contact Info</h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <h5>
                                        Please let us know where we should send the quote and how to
                                        get in touch if we have any questions.
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Container fluid className="contact-info-wrapper">
                                    <Col md={6} className="mt-3">
                                        <Row>
                                            <Col xl={12} className="cm-input-container">
                                                <Row>
                                                    <Col md={10}>
                                                        <label>First Name</label>
                                                        <span className="error-txt">*</span>
                                                        <FormControl
                                                            type="text"
                                                            name="firstName"
                                                            className="form-control"
                                                            value={contactInfo.firstName}
                                                            maxLength="50"
                                                            disabled={isDisableContactInfo.firstName}
                                                            onChange={(event) =>
                                                                handleContactInfoChanged(event, true)
                                                            }
                                                            ref={register({
                                                                required:
                                                                    MESSAGE_CONSTANT.contactInfo.firstName.required,
                                                                maxLength: {
                                                                    value: 50,
                                                                    message: MESSAGE_CONSTANT.contactInfo.firstName.maxLength,
                                                                },
                                                                validate: value => XRegExp(DATA_CONSTANT.patterns.name).test(value) == false ? MESSAGE_CONSTANT.contactInfo.firstName.invalid : null,
                                                            })}
                                                        />
                                                        <span className="error-txt">
                                                            {errors["firstName"] && errors["firstName"].message}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} className="cm-input-container">
                                                <Row>
                                                    <Col md={10}>
                                                        <label>Last Name</label>
                                                        <span className="error-txt">*</span>
                                                        <FormControl
                                                            type="text"
                                                            name="lastName"
                                                            className="form-control"
                                                            value={contactInfo.lastName}
                                                            maxLength="50"
                                                            disabled={isDisableContactInfo.lastName}
                                                            onChange={(event) =>
                                                                handleContactInfoChanged(event, true)
                                                            }
                                                            ref={register({
                                                                required:
                                                                    MESSAGE_CONSTANT.contactInfo.lastName.required,
                                                                maxLength: {
                                                                    value: 50,
                                                                    message: MESSAGE_CONSTANT.contactInfo.lastName.maxLength,
                                                                },
                                                                validate: value => XRegExp(DATA_CONSTANT.patterns.name).test(value) == false ? MESSAGE_CONSTANT.contactInfo.lastName.invalid : null,
                                                            })}
                                                        />
                                                        <span className="error-txt">
                                                            {errors["lastName"] && errors["lastName"].message}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} className="cm-input-container">
                                                <Row>
                                                    <Col md={10}>
                                                        <label>Email</label>
                                                        <span className="error-txt">*</span>
                                                        <FormControl
                                                            type="text"
                                                            name="email"
                                                            className="form-control"
                                                            value={contactInfo.email}
                                                            maxLength="50"
                                                            disabled={isDisableContactInfo.email}
                                                            onChange={(event) =>
                                                                handleContactInfoChanged(event, true)
                                                            }
                                                            ref={register({
                                                                required:
                                                                    MESSAGE_CONSTANT.contactInfo.email.required,
                                                                pattern: {
                                                                    value: DATA_CONSTANT.patterns.email,
                                                                    message:
                                                                        MESSAGE_CONSTANT.contactInfo.email.invalid,
                                                                },
                                                            })}
                                                        />
                                                        <span className="error-txt">
                                                            {errors["email"] && errors["email"].message}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl={12} className="cm-input-container">
                                                <Row>
                                                    <Col md={10}>
                                                        <label>Phone</label>
                                                        <span className="error-txt">*</span>
                                                        <FormControl
                                                            type="text"
                                                            name="phone"
                                                            className="form-control"
                                                            value={contactInfo.phone}
                                                            maxLength="25"
                                                            disabled={isDisableContactInfo.phone}
                                                            onChange={(event) =>
                                                                handleContactInfoChanged(event, true)
                                                            }
                                                            ref={register({
                                                                required:
                                                                    MESSAGE_CONSTANT.contactInfo.phone.required,
                                                            })}
                                                        />
                                                        <span className="error-txt">
                                                            {errors["phone"] && errors["phone"].message}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl={12} className="cm-input-container">
                                                <Row>
                                                    <Col md={10}>
                                                        <label>Company Name</label>
                                                        <FormControl
                                                            name="company"
                                                            type="text"
                                                            className="form-control"
                                                            value={contactInfo.company}
                                                            maxLength="50"
                                                            disabled={isDisableContactInfo.company}
                                                            onChange={(event) =>
                                                                handleContactInfoChanged(event, true)
                                                            }
                                                            ref={register({
                                                                pattern: {
                                                                    value: DATA_CONSTANT.patterns.name,
                                                                    message:
                                                                        MESSAGE_CONSTANT.contactInfo.company.invalid,
                                                                },
                                                            })}
                                                        />
                                                        <span className="error-txt">
                                                            {errors["company"] && errors["company"].message}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl={12} className="cm-input-container">
                                                <Row>
                                                    <Col md={10}>
                                                        <label>State</label>
                                                        <span className="error-txt">*</span>
                                                        <FormControl
                                                            name="state"
                                                            type="text"
                                                            className="form-control"
                                                            value={contactInfo.state}
                                                            maxLength="30"
                                                            disabled={isDisableContactInfo.state}
                                                            onChange={(event) =>
                                                                handleContactInfoChanged(event, true)
                                                            }
                                                            ref={register({
                                                                required:
                                                                    MESSAGE_CONSTANT.contactInfo.state.required,
                                                            })}
                                                        />
                                                        <span className="error-txt">
                                                            {errors["state"] && errors["state"].message}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Container>
                            </Row>
                        </Container>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {isUploadBomRequired && bomFileValidation.isDisplayBOM && (
                                <div className="mt-5 error-txt">{bomFileValidation.message}</div>
                            )}
                            {isGerberFileRequired && gerberFileValidation.isDisplayGerber && (
                                <div className="mt-3 error-txt">
                                    {gerberFileValidation.message}
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center my-5">
                            <PrimaryOrangeButton
                                name={isUploadBomRequired ? "Map BOM" : "Next"}
                                handleClick={() => StartChildValidation()}
                            />
                        </Col>
                    </Row>
                </>
            }
            {
                (isRFQSubmitted && (needToAddManualPCBLine || isUploadBomRequired)) && <ImportBOMModal show={isRFQSubmitted && isUploadBomRequired} backdrop="static" keyboard={false} data={bomDataInfo} selectedJobTypeInfo={selectedJobType} needToAddManualPCBLine={needToAddManualPCBLine} onHide={(val) => redirectToNextScreen(val)} />
            }
        </>

    );
};
const mapStateToProps = (state) => ({
    selectedJobType: state.JobTypes?.selectedJobType,
    selectedBomFile: state.BomFile?.bomFile,
    completedSteps: state.CompletedSteps ? state.CompletedSteps : [],
    assemblyId: state.RFQDetails?.rfqDetails?.assemblyId,
    salesPersonId: state.DefaultConfigs?.defaultConfigs?.salesPersonId,
});

const mapDispatchToProps = (dispatch) => ({
    onDefaultConfigsLoad: (defaultConfigs) =>
        dispatch(setDefaultConfigs(defaultConfigs)),

    onRFQCreated: (rfqDetails) => {
        dispatch(setRFQDetails(rfqDetails));
        dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.RFQ));
    },
    onBOMCreated: (val) => {
        dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.BOM));
        dispatch(setBom(val));
    },
    onBOMFileSelected: (fileData) => dispatch(setBomFile(fileData)),
    onSetExternalUserId: (userId) => dispatch(setExternalUserId(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UploadBomPage);
