import React, { useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { RemoveButton } from "../Common/InputButton/InputButton";
import { MESSAGE_CONSTANT } from "../../constants/MessageConstant";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { max } from 'underscore';
import { PrimaryOrangeButton } from "../../components/Common/InputButton/InputButton";
import Swal from 'sweetalert2';
import { InputTextBox, InfoToolTip } from "../../components/ControlComponents/Input/index";
import './MissingPCB.css';

const MissingPCB = ({ bomData, needToAddManualPCBLine, handleBomSubmit }) => {
    const { register, errors, trigger, clearErrors } = useForm();
    const [missingPcbList, setMissingPcbList] = useState([{ pcbId: 0, partNumber: "", description: "", qty: 1, isExistingLine: false }]);

    const addPcb = () => {
        let maxPcbId = max(missingPcbList, function (o) { return o.pcbId; }).pcbId;
        setMissingPcbList([
            ...missingPcbList,
            {
                pcbId: maxPcbId + 1,
                qty: 1,
                partNumber: "",
                description: "",
                isExistingLine: false
            },
        ]);
    }

    const removePcb = (pcbIndex, event) => {
        if (missingPcbList.length > 1) {
            let updatedMissingPcb = missingPcbList.filter(
                (pcb) => pcb.pcbId !== pcbIndex
            );
            setMissingPcbList(updatedMissingPcb);
        }
        let name = event.target.name;
        clearErrors([name]);
    }

    const handleChanged = (index, event) => {
        let copiedPcbList = [...missingPcbList];
        let value = event.target.value;
        let name = event.target.name;
        trigger(name);
        let propertyName = name.split("_")[0];
        copiedPcbList[index][propertyName] = value;
        setMissingPcbList(copiedPcbList);
    }

    const checkPcbValidation = (index, event) => {
        let value = event.target.value?.trim();
        let name = event.target.name;
        let propertyName = name.split("_")[0];
        let copiedPcbList = [...missingPcbList];
        let isExistInPcbList = copiedPcbList.filter(x => x.partNumber?.toLowerCase().trim() === value?.toLowerCase());

        if (value && isExistInPcbList.length > 1) {
            Swal.fire({
                text: MESSAGE_CONSTANT.missingPcb.uniquePartNumber,
                icon: 'info'
            })
            copiedPcbList[index].partNumber = "";
            setMissingPcbList(copiedPcbList);
            return
        }

        if (propertyName == "partNumber") {
            let bomLineItem = bomData.bomData.find(x => x.MfgrsPartNo?.toLowerCase().trim() === value?.toLowerCase());
            let isMarkedAsPcb = copiedPcbList[index].isExistingLine;
            // if alrealy marked as PCB from existing line(s) then no need to show confirmation.
            if (bomLineItem && !isMarkedAsPcb) {
                Swal.fire({
                    text: MESSAGE_CONSTANT.missingPcb.pcbConfirmation.formatGeneric(bomLineItem.MfgrsPartNo),
                    confirmButtonColor: DATA_CONSTANT.OrangeColor,
                    cancelButtonColor: DATA_CONSTANT.BlueColor,
                    confirmButtonText: DATA_CONSTANT.missingPcbConfirmButtonText,
                    showCancelButton: true,
                    cancelButtonText: DATA_CONSTANT.missingPcbCancelButtonText,
                    icon: 'question',
                    reverseButtons: true,
                    width: 700,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        copiedPcbList[index].isExistingLine = true;
                        copiedPcbList[index].description = bomLineItem.Description;
                        copiedPcbList[index].qty = bomLineItem.Qty;
                    } else {
                        copiedPcbList[index].partNumber = "";
                    }
                    setMissingPcbList(copiedPcbList);
                })
            }
            if (!bomLineItem && isMarkedAsPcb) {
                copiedPcbList[index].isExistingLine = false;
                copiedPcbList[index].description = "";
                copiedPcbList[index].qty = 1;
                setMissingPcbList(copiedPcbList);
            }
        }
    }

    const updateBOM = () => {
        trigger().then((result) => {
            if (result) {
                handleBomSubmit(missingPcbList, bomData.partClass)
            }
        })
    }

    return (
        <Container fluid>
            {
                needToAddManualPCBLine ?
                    (
                        <Row className="mb-4">
                            <Col>
                                <h2>Add PCB Line(s)</h2>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Row>
                                <Col>
                                    <h2>Missing PCB</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="my-3">
                                    <h3> {DATA_CONSTANT.missingPcbHeaderText}</h3>
                                </Col>
                            </Row>
                        </>
                    )
            }

            <Row>
                <Col md={3} className="pb-0">
                    <label>Part Number</label>
                    <span className="error-txt">*</span>
                </Col>
                <Col md={5} className="pb-0">
                    <label>Description</label>
                </Col>
                <Col md={3} className="pb-0">
                    <label>Quantity</label>
                    <span className="error-txt">*</span>
                </Col>
            </Row>
            {
                missingPcbList.length > 0 &&
                missingPcbList.map((pcb, pcbIndex) => (
                    <Row className="pr-0 mt-3" key={pcbIndex}>
                        <Col md={3}>
                            <InputTextBox
                                name={`partNumber_${pcb.pcbId}`}
                                type="text"
                                className="form-control"
                                value={pcb.partNumber}
                                onChange={(event) => handleChanged(pcbIndex, event)}
                                ref={register({
                                    required: MESSAGE_CONSTANT.validation.required
                                        .toString()
                                        .formatGeneric("Part Number"),
                                    maxLength: {
                                        value: 100,
                                        message: MESSAGE_CONSTANT.validation.maxValue
                                            .toString()
                                            .formatGeneric("Part Number", "100")
                                    },
                                    validate: (value) =>
                                        value?.trim().toString().length === 0
                                            ? MESSAGE_CONSTANT.validation.invalid
                                                .toString()
                                                .formatGeneric("Part Number")
                                            : null,
                                })}
                                errorMessage={errors}
                                onBlur={(event) => checkPcbValidation(pcbIndex, event)}
                            />
                        </Col>
                        <Col md={5}>
                            <InputTextBox
                                name={`description_${pcb.pcbId}`}
                                type="text"
                                className="form-control"
                                value={pcb.description}
                                onChange={(event) => handleChanged(pcbIndex, event)}
                                ref={register({
                                    maxLength: {
                                        value: 500,
                                        message: MESSAGE_CONSTANT.validation.maxValue
                                            .toString()
                                            .formatGeneric("Description", "500")
                                    }
                                })}
                                errorMessage={errors}
                            />
                        </Col>
                        <Col md={3}>
                            <Row>
                                <Col md={10} className="cm-missingpcb-qty">
                                    <InputTextBox
                                        name={`qty_${pcb.pcbId}`}
                                        type="number"
                                        className="form-control"
                                        value={pcb.qty}
                                        onChange={(event) => handleChanged(pcbIndex, event)}
                                        disabled={pcb.isExistingLine}
                                        ref={register({
                                            required: MESSAGE_CONSTANT.quantity.required,
                                            pattern: {
                                                value: DATA_CONSTANT.patterns.quantity,
                                                message: MESSAGE_CONSTANT.quantity.invalid,
                                            },
                                        })}
                                        errorMessage={errors}
                                    />
                                </Col>
                                <Col md={2}>
                                    {pcb.isExistingLine &&
                                        <InfoToolTip
                                            className=""
                                            helpText={DATA_CONSTANT.missingPcbHelpText}
                                            helpPlacement="top"
                                        />
                                    }
                                </Col>
                            </Row>
                        </Col>

                        <Col md={1} className="p-md-0 mt-1 text-center">
                            <RemoveButton
                                handleClick={(event) => removePcb(pcb.pcbId, event)}
                            />
                        </Col>
                    </Row>
                ))
            }
            <Row>
                <Col md={3}>
                    <div className="link-btn" onClick={() => addPcb()}>Add Another PCB Line </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 6, offset: 4 }} className="my-5">
                    <PrimaryOrangeButton
                        name={needToAddManualPCBLine ? "Add PCB(s)" : "Update BOM"}
                        handleClick={() => updateBOM()}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default MissingPCB;