import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';

export const LaborService = {

    getLaborQuestions: (refAssemblyId,assemblyId) => {
        const url = `${URL_CONSTANT.Labor.GetLaborQuestions}`;
        const params = { refAssemblyId: refAssemblyId, assemblyId: assemblyId};
        return CommonHttpService.httpGet(url, params);
    },
    submitLaborAnswers: (laborAnswers) => {
        const url = URL_CONSTANT.Labor.SubmitLaborAnswers;
        return CommonHttpService.httpPost(url, laborAnswers);
    }
}