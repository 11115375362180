import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PrimaryOrangeButton } from '../../components/Common/InputButton/InputButton';
import { DATA_CONSTANT } from '../../constants/DataConstant';
import { clearStore } from '../../redux/actions/ClearStoreActions';
import { setCompletedSteps } from '../../redux/actions/CompletedStepActions';
import { clearJobType } from '../../redux/actions/ClearJobType';
import HeaderWithMenu from '../../components/Header/HeaderWithMenu';
import { CommonService } from '../../services/CommonService';
import { DynamicLabelKey } from '../../constants/DynamicLabelKeyConstant';
import { setRFQDetails } from '../../redux/actions/RFQDetailActions';
import { trackPromise } from 'react-promise-tracker';
import { ReportService } from '../../services/ReportService';
import { ConfigurationService } from '../../services/ConfigurationService';

const BomAnalysisComplete = ({ onBomAnalysisComplete, assemblyId, onClearRFQDetails }) => {
    const history = useHistory();
    const getAQuoteButtonDetail = CommonService.getDynamicLabelDetail(DynamicLabelKey.GetAQuoteButton);

    useEffect(() => {
        onBomAnalysisComplete(assemblyId);
        document.getElementById("getaquote").innerHTML = getAQuoteButtonDetail.description;

    }, []
    );

    const handleStartAQuote = () => {
        onClearRFQDetails();

        trackPromise(redirectToQuoteUrl());
    }

    const redirectToQuoteUrl= async () => {
        let configKeys = [DATA_CONSTANT.ConfigurationKey.PortCQUrl];
        let configurations = await ConfigurationService.getByKeys(configKeys);
        if (configurations && configurations.length > 0) {
            window.location.href = configurations[0].value;
        }
    }

    const handleDownloadBOM = () => {
        if (assemblyId) {
            var objReportRequest = {
                AssemblyId: assemblyId
            }
            trackPromise(ReportService.getCostedBOMReport(objReportRequest))
        }
    }

    return (
        <div className="body-wrapper">
            <HeaderWithMenu />
            <div id="main-wrapper">
                <Container fluid>
                    <Row className="text-center ba-complete">
                        <Col lg={{ span: 8, offset: 2 }} md={12} sm={12} xs={12}>
                            <h2><b>THANKS FOR USING OUR BOM ANALYZER</b></h2>
                            <Row className="mt-5">
                                <Col>
                                    <h5>
                                        Download your BOM here.</h5>
                                    <div className="mt-3">
                                        <PrimaryOrangeButton name="DOWNLOAD MY BOM" handleClick={handleDownloadBOM} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col>
                                    <h5>
                                        If you are ready to start your quote, click below or email files to  <a href="mailto:sales@aapcb.com">sales@aapcb.com</a>.</h5>
                                    <div className="mt-3">
                                        <PrimaryOrangeButton name="START A QUOTE" handleClick={handleStartAQuote} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4 text-left">
                                <Col>
                                    <div className="marketing-section p-10" id="getaquote"></div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};


const mapStateToProps = state => ({
    assemblyId: state.RFQDetails?.rfqDetails?.assemblyId
});

const mapDispatchToProps = dispatch => ({
    onBomAnalysisComplete: (assemblyId) => {
        dispatch(clearStore());
        dispatch(clearJobType({}));
        dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.Completed));
        dispatch(setRFQDetails({ assemblyId: assemblyId }));
    },
    onClearRFQDetails: () => {
        dispatch(setRFQDetails({}));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(BomAnalysisComplete)