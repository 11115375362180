export const MESSAGE_CONSTANT = {
    quantity: {
        required: "Quantity is required.",
        invalid: "Please insert valid Quantity.",
    },
    turnTime: {
        required: "Total Turn Time is required.",
        invalid: "Please insert valid Total Turn Time.",
    },
    contactInfo: {
        name: {
            required: "Name is required.",
            invalid: "Please insert valid Name.",
            maxLength: "value can not be greater than 50 characters.",
        },
        firstName: {
            required: "First Name is required.",
            invalid: "Please insert valid First Name.",
            maxLength: "Value can not be greater than 50 characters.",
        },
        lastName: {
            required: "Last Name is required.",
            invalid: "Please insert valid Last Name.",
            maxLength: "Value can not be greater than 50 characters.",
        },
        email: {
            required: "Email is required.",
            invalid: "Please insert valid Email.",
        },
        company: {
            invalid: "Please insert valid Company name.",
        },
        phone: {
            required: "Phone is required.",
            invalid: "Please insert valid Phone.",
        },
        state: {
            required: "State is required.",
        },
    },
    labor: {
        laborQuestion: {
            required: "Field is required.",
            invalid: "Please enter a valid value.",
            maxValue: "Value must be less than 5 digits.",
        },
        laborNotConfigured: "Build requirements is not configured.",
        onlyReadonlyQuestions:
            "Looks like all of your labor activities are already configured, you can move to the next page.",
    },
    documntUploader: {
        isFileUpload: {
            required: "Please add your Gerbers, XYRS data and BOM.",
        },
    },
    complete: {
        quoteCompleteMessage:
            "Thank you for providing your details. At this time, We can not provide online pricing for bare boards. We will contact you shortly.",
    },
    bomFile: {
        required: "Please upload csv/xls/xlsx type file and mark it as BOM.",
    },
    gerberFile: {
        required: `Please upload the gerber file. If you have already uploaded it then please indicate it by selecting the "Gerber" in the dropdown.`,
    },
    tooManyFiles: "Too many files. Maximum number of files is",
    reviewBOM: {
        requiredMethodOfSupply: "Please select Method Of Supply for LineItems ",
    },
    CheckListBuilder: {
        maxValue: "Value cannot contain more than {0} characters.",
        required: "{0} Field is required.",
        invalid: "Please insert valid {0}.",
        minValue: "Value must be greater than {0} digits.",
    },
    NREService: {
        required: "{0} Field is required.",
    },
    validation: {
        maxValue: "{0} cannot contain more than {1} characters.",
        required: "{0} is required.",
        invalid: "Please insert valid {0}.",
        minValue: "Value must be greater than {0} digits.",
    },
    accountNumber: {
        invalid: "Invalid account number.",
        required: "Account Number is required",
    },
    Payment: {
        invalidAmount: "Amount should be greater than 0.",
    },
    ReviewBOM: {
        bomCleanMessage: "BOM is Clean",
        sourcingHelpNote: "The following line(s) require sourcing help.[Line #: MPN] {0}."
    },
    required: "is required",
    missingPcb: {
        uniquePartNumber: "Part number should have unique value.",
        pcbConfirmation: "{0} exists on the BOM and must be unique. Do you want to mark it as a PCB or modify the entered part number?",
    },
    alternates: {
        duplicate: "Duplicate part number found : {0}.",
        lineItemsForAddAlternates: "Alternate needs to be added for lineitem(s) {0}."
    }
};
