import { CLEAR_STORE, BOMID ,CLEAR_BOM} from '../../constants/ActionConstants';

const InitialState = {};

export const BomIds = (state = InitialState, action) => {
    const { type, data } = action;

    switch (type) {
        case BOMID:
            return { ...state, BomId: data.BomId };
        case CLEAR_STORE:
            return InitialState;
        case CLEAR_BOM:
            return InitialState;
        default:
            return state;
    }
}