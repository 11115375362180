import React from 'react';
import './JobType.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Icon from '../Common/Icon/Icon';
import { FaRegCheckCircle } from 'react-icons/fa';

const JobType = ({ type, isSelected, onJobTypeSelected }) => {

    const jobTypeClass = classNames('job-type', { 'selected': isSelected });

    const handleJobTypeSelected = () => {
        onJobTypeSelected();
    }

    return (
        <>
            <Row>
                <Col onClick={handleJobTypeSelected} className={jobTypeClass} >
                    <div className="selected-jobType-icon">
                        {isSelected && <FaRegCheckCircle />}
                    </div>
                    <Icon name={type.iconClass} displayName={type.displayName} />
                    <div className="mt-3"><h5>{type.displayName}</h5></div>
                </Col>
            </Row>
        </>
    )
};

JobType.propTypes = {
    type: PropTypes.shape({
        id: PropTypes.number.isRequired,
        displayName: PropTypes.string.isRequired
    }),
    isSelected: PropTypes.bool.isRequired,
    onJobTypeSelected: PropTypes.func
};
export default JobType;