import { RESET_COMPLETED_STEPS, SET_COMPLETED_STEPS } from '../../constants/ActionConstants';

const InitialState = [];
export const CompletedSteps = (state = InitialState, action) => {
    const { type, data } = action;

    switch (type) {
        case SET_COMPLETED_STEPS:
            const stateData = [...state];

            if (stateData.indexOf(data.stepName) < 0) {
                stateData.push(data.stepName);
            }
            return stateData;
        case RESET_COMPLETED_STEPS:
            return InitialState;
        default:
            return state;
    }
}