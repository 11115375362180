import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HeaderWithMenu from '../../components/Header/HeaderWithMenu';
import { Container, Row, Col } from 'react-bootstrap';
import { PrimaryOrangeButton } from '../../components/Common/InputButton/InputButton';
import MarketingContent from '../../components/MarketingContent/MarketingContent';
import { connect } from 'react-redux';
import { JobTypeService } from '../../services/JobTypeService';
import { trackPromise } from 'react-promise-tracker';
import { selectJobType } from '../../redux/actions/JobTypeActions';
import { DATA_CONSTANT } from '../../constants/DataConstant';
import { resetCompletedSteps } from '../../redux/actions/CompletedStepActions';
import { clearBom } from '../../redux/actions/SetBomIdAction';
import { ClearPricingRunningStatus } from '../../redux/actions/PricingRunnigStatusActions';
import { clearIsCustomQuoteRequires } from "../../redux/actions/SetCustomQuoteRequire";
import { CommonService } from '../../services/CommonService';
import { DynamicLabelKey } from '../../constants/DynamicLabelKeyConstant';
import { setRFQDetails } from '../../redux/actions/RFQDetailActions';
import { clearJobType } from '../../redux/actions/ClearJobType';

const QuoteProcessSelectionPage = ({ clearStore, setJobType }) => {
    const history = useHistory();
    const getAQuoteButtonDetail = CommonService.getDynamicLabelDetail(DynamicLabelKey.GetAQuoteButton);
    const bomAnalysisButtonDetail = CommonService.getDynamicLabelDetail(DynamicLabelKey.BomAnalysisButton);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

        let isBomAnalysis = process.env.REACT_APP_BOM_ANALYSIS_ENABLED;
        if (isBomAnalysis && isBomAnalysis == 'true') {
            handleBomAnalysisClicked();
        } else {
            setIsLoaded(true);
        }

    }, [])

    useEffect(() => {

        if (isLoaded) {
            document.getElementById("getaquote").innerHTML = getAQuoteButtonDetail.description;
            document.getElementById("bomanalysis").innerHTML = bomAnalysisButtonDetail.description;
        }

    }, [isLoaded])

    const getJobTypes = async () => {
        await JobTypeService.getBOMAnalysisJobTypeList().then((data) => {
            if (data && data.jobTypes && data.jobTypeSelection) {

                let jobTypeSelection = data.jobTypeSelection[0];
                let jobType = data.jobTypes[0];

                setJobType({
                    cqJobType: jobTypeSelection.quoteCQJobTypeName,
                    isvalid: jobTypeSelection.isValid,
                    isBomRequired: jobTypeSelection.isBomRequired,
                    isPcbRequired: jobTypeSelection.isPcbRequired,
                    isAssemblyRequired: jobTypeSelection.isAssemblyRequired,
                    isComponentsRequired: jobTypeSelection.isComponentsRequired,
                    isBomAnalysis: jobType.isBomAnalysis
                });

                history.push(DATA_CONSTANT.routes.rfq);
            }
        })
    }

    const handleGetQuoteClicked = () => {
        clearStore();
        history.push(DATA_CONSTANT.routes.jobType);
    }

    const handleBomAnalysisClicked = () => {
        clearStore();
        trackPromise(
            getJobTypes()
        );
    }

    return (
        <div className="body-wrapper">
            <HeaderWithMenu />
            <div id="main-wrapper">
                <Container fluid>
                    {isLoaded &&
                        <Row>
                            <Col lg={{ span: 8, offset: 2 }} md={12} sm={12} xs={12}>
                                <h2><b>HOW CAN WE HELP?</b></h2>
                                <Row className="pt-3">
                                    <Col className="text-center quote-process-selection">
                                        <h5>
                                            <span className="orange-color">Start a Quote Now</span> or use our <span className="orange-color">BOM Analyzer</span> to check part stock levels as you build!
                                            <br />
                                            As always, email <a href="mailto:sales@aapcb.com">sales@aapcb.com</a> with ITAR regulated files.</h5>
                                    </Col>
                                </Row>
                                <Row className="mar-top-40">
                                    <Col md={6} sm={6} xs={6}>
                                        <div className="text-center m-r-25">
                                            <PrimaryOrangeButton name={getAQuoteButtonDetail.label} handleClick={handleGetQuoteClicked} />
                                        </div>
                                        <div className="marketing-section p-10 m-r-25" id="getaquote"></div>
                                    </Col>
                                    <Col md={6} sm={6} xs={6}>
                                        <div className="text-center m-l-25">
                                            <PrimaryOrangeButton name={bomAnalysisButtonDetail.label} handleClick={handleBomAnalysisClicked} />
                                        </div>
                                        <div className="marketing-section p-10 m-l-25" id="bomanalysis"></div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
        </div>
    );
};


const mapDispatchToProps = dispatch => ({
    clearStore: () => {
        dispatch(clearJobType({}));
        dispatch(resetCompletedSteps());
        dispatch(clearBom());
        dispatch(ClearPricingRunningStatus());
        dispatch(clearIsCustomQuoteRequires({ IsCustomQuoteRequires: false }));
        dispatch(setRFQDetails({}));
    },
    setJobType: jobType => dispatch(selectJobType(jobType))
});

export default connect(null, mapDispatchToProps)(QuoteProcessSelectionPage)