import { SET_RFQ_DETAILS, SET_EXTERNAL_USERID } from '../../constants/ActionConstants';

export const setRFQDetails = (rfqDetails) => ({
    type: SET_RFQ_DETAILS,
    data: { rfqDetails: rfqDetails }
});

export const setExternalUserId = (externalUserId) => ({
    type: SET_EXTERNAL_USERID,
    data: { externalUserId: externalUserId }
});