import React from "react";
import { Row, Col } from "react-bootstrap";
import {
    PrimaryOrangeButton,
    AddButton,
    RemoveButton,
    PrimaryBlueButton,
} from "../../components/Common/InputButton/InputButton";
import { InputTextBox } from "../../components/ControlComponents/Input/index";

const Promocode = ({
    isAddPromoCode,
    isShowPromoCodeDetail,
    isDisableForPromoCode,
    orderdetails,
    promoCodeError,
    handelChange,
    addPromocode,
    applyPromoCode,
    cancelPromoCode,
    removePromoCode,
}) => {
    return (
        <React.Fragment>
            <Row>
                {!isShowPromoCodeDetail && (
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <AddButton handleClick={addPromocode} /> Add Promo Code
                    </Col>
                )}
            </Row>
            <Row>
                {isAddPromoCode && (
                    <>
                        <Col xl={12} lg={12} md={12} sm={12} className="mt-3 mb-3">
                            <InputTextBox
                                id={"promoCode"}
                                name={"promoCode"}
                                disabled={isDisableForPromoCode.promoCodeTextbox}
                                value={orderdetails.paymentDetails.promoCode || ""}
                                onChange={handelChange}
                            ></InputTextBox>
                            {promoCodeError.isDisplay && (
                                <span className="error-txt">{promoCodeError.error}</span>
                            )}
                        </Col>
                        <Col className="my-2 cm-btn-addPromoCode">
                            <PrimaryBlueButton name="Cancel" handleClick={cancelPromoCode} />
              &nbsp;&nbsp;
              <PrimaryOrangeButton
                                name="Apply"
                                disabled={isDisableForPromoCode.applyPromoCodeButton}
                                handleClick={applyPromoCode}
                            />
                        </Col>
                    </>
                )}
            </Row>
            {isShowPromoCodeDetail && (
                <Row className="cm-promoCodeDetail">
                    <Col xl={11} lg={11} md={11} sm={11}>
                        <p>
                            Promo Code{" "}
                            <span className="cm-promocodeDetail-promoCode">
                                {orderdetails.paymentDetails.promoCode}
                            </span>{" "}
              is applied.
            </p>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1}>
                        <RemoveButton handleClick={() => removePromoCode()} />
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};
export default Promocode;
