import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import "./Checkout.css";
import { PrimaryOrangeButton } from "../../components/Common/InputButton/InputButton";
import { InputTextBox, InputCheck, InputTextArea, InputSelect } from "../../components/ControlComponents/Input/index";
import { OrderService } from "../../services/OrderService";
import { QuotePriceService } from "../../services/QuotePriceService";
import { trackPromise } from "react-promise-tracker";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { MESSAGE_CONSTANT } from "../../constants/MessageConstant";
import { setCompletedSteps } from "../../redux/actions/CompletedStepActions";
import { useForm } from "react-hook-form";
import XRegExp from "xregexp/xregexp-all";
import PayTracePaymentModal from "../../components/PayTracePayment/PayTracePaymentModal";
import Promocode from "../../components/Checkout/Promocode";
import { LoadingSpiner } from "../../components/Common/LoadingSpiner";
import { LoadingWrapper } from "../../components/Common/LoadingWrapper";
import { Areas } from "../../constants/Area";
import { CommonService } from "../../services/CommonService";
import OrderSummaryView from "../../components/OrderSummaryView/OrderSummaryView";
import { useHistory } from "react-router-dom";

const Checkout = ({ RFQDetails, onSaveOrder }) => {

    const history = useHistory();

    let deliveryServiceList = [
        { Text: "FedEX", Value: "FedEX" },
        { Text: "UPS", Value: "UPS" },
    ];

    let isValidShippingAccountNumber = false;

    const loaderElement = useRef(null);

    const { register, errors, trigger } = useForm();

    const [orderdetails, setOrderDetails] = useState({
        isBillingAddress: false,
        isUseshippingAccount: false,
        assemblyId: 0,
        quantityId: 0,
        turnTimeId: 0,
        name: "",
        phone: "",
        email: "",
        company: "",
        orderDate: "",
        shippingAddress1: "",
        shippingAddress2: "",
        shippingZip: "",
        shippingCity: "",
        shippingState: "",
        shippingCountry: "",
        shippingMethod: "",
        deliveryService: "",
        shippingAccountNumber: "",
        billingAddress1: "",
        billingAddress2: "",
        billingZip: "",
        billingCity: "",
        billingCountry: "",
        billingState: "",
        paymentDetails: {
            discount: 0,
            shippingCharge: 0,
            promoCode: "",
            userEmailId: "",
            subTotalPrice: 0,
            paymentPrice: 0,
            tax: 0
        },
    });

    const [orderSummary, setOrderSummary] = useState({});

    const [isDataLoaded, setDataLoaded] = useState(false);

    const [stripeModalShow, setStripeMoalShow] = useState(false);

    const handleClose = () => setStripeMoalShow(false);

    const [isStripeModalLoaded, setStripeModalLoaded] = useState(false);

    const [amountValidation, setAmountValidation] = useState('');


    const [paymentDetails, setPaymentDetails] = useState({
        discount: 0,
        shippingCharge: 0,
        subTotalPrice: 0,
        tax: 0
    });

    const [isAddPromoCode, setIsAddPromoCode] = useState(false);

    const [promoCodeError, setPromoCodeError] = useState({
        isDisplay: false,
        error: "",
    });

    const [isDisableForPromoCode, setIsDisableForPromoCode] = useState({
        promoCodeTextbox: false,
        applyPromoCodeButton: true,
    });

    const [isShowPromoCodeDetail, setIsShowPromoCodeDetail] = useState(false);

    const [shippingMethods, setShippingMethods] = useState([
        { shippingMethodName: "", shippingCharge: "" },
    ]);

    const [CustomerShippingAccount, setCustomerShippingAccount] = useState({ isVisible: false, isRequired: false });

    const [errorMessageForCustomerShippingAccount, SetErrorMessageForCustomerShippingAccount] = useState({ isDisplay: false, errorMessageForDeliveryService: "", errorMessageForShippingAccountNumber: "" });

    const [isSubmit, setIsSubmit] = useState(false);

    const [taxError, setTaxError] = useState({ isDisplay: false, message: "", isProcessing: false });

    const [isPromoCodeSectionVisible, setIsPromoCodeSectionVisible] = useState(false);

    useEffect(() => {
        if (stripeModalShow && paymentDetails) {
            setStripeModalLoaded(true);
        }
    }, [stripeModalShow, paymentDetails]);

    useEffect(() => {

        if (!RFQDetails?.assemblyId) {
            history.push("/");
            return;
        }

        trackPromise(
            (async () => {
                let assemblyId = RFQDetails.assemblyId;
                let order = await getOrder(assemblyId);
                let selectedPrice = await getSelectedPrice(assemblyId);
                let shippingMethods = await getShippingMethods();
                if (shippingMethods) {
                    setShippingMethods(shippingMethods.shippingMethods);
                }
                if (order) {
                    //Reset Promocode & Shipping details at edit time
                    if (order.paymentDetails != "") {
                        order.paymentDetails.discount = 0;
                        order.paymentDetails.shippingCharge = 0;
                        order.paymentDetails.promoCode = "";
                        order.shippingMethod = "";
                        order.deliveryService = "";
                        order.shippingAccountNumber = "";
                    }
                    setOrderDetails({ ...orderdetails, ...order });
                }

                if (selectedPrice) {
                    setOrderSummary(selectedPrice);
                    if (order == null) {
                        setOrderDetails({
                            ...orderdetails,
                            ...RFQDetails?.customerInfo,
                            ...selectedPrice,
                        });
                    } else {
                        setOrderDetails({ ...order, ...selectedPrice });
                    }
                }
                setDataLoaded(true);

                if (order.paymentDetails.promoCode) {
                    setIsShowPromoCodeDetail(true);
                }
            })()
        );
    }, []);

    useEffect(() => {
        let loaderArea = '';
        if (!taxError.isDisplay && orderdetails.shippingMethod != "") {
            loaderArea = orderdetails.isUseshippingAccount ? Areas.usMyShippingAccount : Areas.shippingMethod
            calculateTax(loaderArea);
        }
    }, [orderdetails.paymentDetails.shippingCharge])

    useEffect(() => {
        if (isSubmit && orderdetails.isUseshippingAccount) {
            if (orderdetails.deliveryService === "" && orderdetails.shippingAccountNumber === "") {
                SetErrorMessageForCustomerShippingAccount({
                    isDisplay: true,
                    errorMessageForDeliveryService: MESSAGE_CONSTANT.validation.required.toString().formatGeneric("Delivery Service"),
                    errorMessageForShippingAccountNumber: MESSAGE_CONSTANT.validation.required.toString().formatGeneric("Shipping Account Number")
                });
                isValidShippingAccountNumber = false;
            }
            else if (orderdetails.deliveryService === "") {
                SetErrorMessageForCustomerShippingAccount({
                    isDisplay: true,
                    errorMessageForDeliveryService: MESSAGE_CONSTANT.validation.required.toString().formatGeneric("Delivery Service"),
                    errorMessageForShippingAccountNumber: ""
                });
                isValidShippingAccountNumber = false;
            }
            else if (orderdetails.shippingAccountNumber === "") {
                SetErrorMessageForCustomerShippingAccount({
                    isDisplay: true,
                    errorMessageForDeliveryService: "",
                    errorMessageForShippingAccountNumber: MESSAGE_CONSTANT.validation.required.toString().formatGeneric("Shipping Account Number")
                });
                isValidShippingAccountNumber = false;
            }
            else {
                checkValidShippingAccountNumber(orderdetails.deliveryService, orderdetails.shippingAccountNumber);
            }
        }
        else {
            SetErrorMessageForCustomerShippingAccount({
                isDisplay: false,
                errorMessageForDeliveryService: "",
                errorMessageForShippingAccountNumber: ""
            });
            isValidShippingAccountNumber = false;
        }
    }, [orderdetails.deliveryService, orderdetails.shippingAccountNumber, isSubmit])

    useEffect(() => {
        orderdetails.deliveryService = "";
        orderdetails.shippingAccountNumber = "";
        orderdetails.paymentDetails.shippingCharge = 0;

        if (orderdetails.isUseshippingAccount) {
            setCustomerShippingAccount({ isVisible: true, isRequired: true })
        }
        else {
            if (orderdetails.shippingMethod && !orderdetails.isUseshippingAccount) {
                orderdetails.paymentDetails.shippingCharge = getShippingChargeFromShippingMethod(orderdetails.shippingMethod);
            }
            setCustomerShippingAccount({ isVisible: false, isRequired: false })
            setIsSubmit(false);
        }
        setOrderDetails(orderdetails);

    }, [orderdetails.isUseshippingAccount])

    const handelChange = (event, isValidated) => {

        let { name, value, checked } = event.target;

        if (isValidated) {
            trigger(event.target.name);
        }

        if (name === "promoCode") {
            let orderDetail = { ...orderdetails };
            if (value != "") {
                orderDetail.paymentDetails.promoCode = value;
                let paymentDetail = CommonService.deepShallowCopy(orderDetail);
                setOrderDetails(paymentDetail);
                setIsDisableForPromoCode({
                    promoCodeTextbox: false,
                    applyPromoCodeButton: false,
                });
            } else {
                orderDetail.paymentDetails.promoCode = value;
                setIsDisableForPromoCode({
                    promoCodeTextbox: false,
                    applyPromoCodeButton: true,
                });
            }
            setPromoCodeError({ isDisplay: false, error: "" });
        }
        else if (name === "shippingAccountNumber") {
            let orderDetail = { ...orderdetails };
            if (isSubmit) {
                checkValidShippingAccountNumber(orderDetail.deliveryService, value);
            }
        }

        else if (name === "shippingMethod") {
            let orderDetail = { ...orderdetails };
            orderDetail.paymentDetails.shippingCharge = orderDetail.isUseshippingAccount ? 0 : getShippingChargeFromShippingMethod(value);
            let paymentDetail = CommonService.deepShallowCopy(orderDetail);
            setOrderDetails(paymentDetail);
        }
        else if (name === "shippingCountry") {
            let isValidCountryForTax = DATA_CONSTANT.CountriesForTaxCalculation.includes(value.trim().toUpperCase());
            if (!isValidCountryForTax) {
                let order = { ...orderdetails };
                order.paymentDetails.tax = 0;
                setOrderDetails(order);
                if (taxError.isDisplay) {
                    setTaxError({
                        isDisplay: false,
                        message: ""
                    });
                }
                calculateTotal();
            }
        }

        else if (name == "isBillingAddress" && checked) {
            orderdetails.billingAddress1 = "";
            orderdetails.billingAddress2 = "";
            orderdetails.billingZip = "";
            orderdetails.billingCity = "";
            orderdetails.billingState = "";
            orderdetails.billingCountry = "";
        }
        setOrderDetails({
            ...orderdetails,
            [name]: event.target.type == "checkbox" ? checked : value,
        });
    };

    const handleBlur = () => {
        calculateTax(Areas.shippingAddress);
    }

    const getOrder = (assemblyId) => {
        return OrderService.GetOrder(assemblyId);
    };

    const getSelectedPrice = (assemblyId) => {
        return QuotePriceService.getSelectedPrice(assemblyId);
    };

    const getShippingMethods = () => {
        return OrderService.GetShippingMethods();
    };

    const calculateTotal = () => {
        return (
            orderSummary.totalPrice +
            orderdetails.paymentDetails.tax +
            orderdetails.paymentDetails.shippingCharge -
            orderdetails.paymentDetails.discount
        ).toFixed(2);
    };

    const calculateTax = (loaderArea) => {
        if ((orderdetails.shippingAddress1 != "" || orderdetails.shippingAddress2 != "") && orderdetails.shippingZip != "" && orderdetails.shippingCity != "" && orderdetails.shippingState != "" && orderdetails.shippingCountry != "" && DATA_CONSTANT.CountriesForTaxCalculation.includes(orderdetails.shippingCountry.trim().toUpperCase()) && orderdetails.shippingCountry.toUpperCase() != "OTHERS") {
            let taxCalculationRequestObject = {
                AssemblyId: RFQDetails.assemblyId,
                QuantityId: orderdetails.quantityId,
                TurnTimeId: orderdetails.turnTimeId,
                ShippingCharge: orderdetails.paymentDetails.shippingCharge,
                AddressLine1: orderdetails.shippingAddress1,
                AddressLine2: orderdetails.shippingAddress2,
                City: orderdetails.shippingCity,
                State: orderdetails.shippingState,
                ZipCode: orderdetails.shippingZip,
                Country: orderdetails.shippingCountry
            }
            trackPromise(calculateTaxService(taxCalculationRequestObject), loaderArea)
        }
    }

    const calculateTaxService = async (taxCalculationRequestObject) => {
        setTaxError({
            ...taxError,
            ['isProcessing']: true,
        });
        await OrderService.CalculateTax(taxCalculationRequestObject)
            .then((data) => {
                if (data && data.taxCalculationResponse) {
                    setTaxError({
                        isDisplay: data.taxCalculationResponse.isError,
                        message: data.taxCalculationResponse.isError ? data.taxCalculationResponse.errorMessage : "",
                        isProcessing: false
                    });

                    setOrderDetails({
                        ...orderdetails,
                        paymentDetails: {
                            ...orderdetails.paymentDetails,
                            tax: data.taxCalculationResponse.isError ? 0 : data.taxCalculationResponse.totalTax
                        },
                    });
                }
            })
    }

    const placeOrder = () => {
        let netPayableAmount = calculateTotal();

        if (promoCodeError.isDisplay) {
            return;
        }
        if (isNaN(netPayableAmount) || netPayableAmount <= 0) {
            setAmountValidation(MESSAGE_CONSTANT.Payment.invalidAmount)
            return;
        }
        if (taxError.isDisplay) {
            return;
        }

        trigger().then(function (result) {

            if (CustomerShippingAccount.isRequired) {
                setIsSubmit(true);
                if (orderdetails.deliveryService != "" && orderdetails.shippingAccountNumber != "") {
                    checkValidShippingAccountNumber(orderdetails.deliveryService, orderdetails.shippingAccountNumber);
                }
                if (!isValidShippingAccountNumber) {
                    return;
                }
            }

            let isValid = result;
            if (isValid) {
                let paymentOrder = {};
                if (!orderdetails.isBillingAddress) {
                    let billingObject = {
                        billingAddress1: orderdetails.shippingAddress1,
                        billingAddress2: orderdetails.shippingAddress2,
                        billingZip: orderdetails.shippingZip,
                        billingCity: orderdetails.shippingCity,
                        billingState: orderdetails.shippingState,
                        billingCountry: orderdetails.shippingCountry,
                        isBillingAddress: false,
                    };
                    paymentOrder.order = { ...orderdetails, ...billingObject };
                } else {
                    paymentOrder.order = { ...orderdetails };
                }
                paymentOrder.order.paymentDetails.userEmailId = paymentOrder.order.email;
                paymentOrder.order.paymentDetails.subTotalPrice = orderSummary.totalPrice;
                paymentOrder.order.paymentDetails.tax = orderdetails.paymentDetails.tax;
                paymentOrder.order.paymentDetails.paymentPrice = calculateTotal();
                trackPromise(
                    OrderService.SaveOrder(paymentOrder).then((data) => {
                        if (data.paymentId > 0 && data.orderId > 0) {
                            let details = {};
                            details.orderId = data.orderId;
                            details.paymentId = data.paymentId;
                            details.userEmailId = paymentOrder.order.email;
                            details.userName = paymentOrder.order.name;
                            details.price = orderSummary.totalPrice;
                            details.discount = orderdetails.paymentDetails.discount
                            details.shippingCharge = orderdetails.paymentDetails.shippingCharge;
                            details.tax = orderdetails.paymentDetails.tax;
                            onSaveOrder();
                            let isValidCountryForTax = DATA_CONSTANT.CountriesForTaxCalculation.includes(orderdetails.shippingCountry.trim().toUpperCase());
                            if (!isValidCountryForTax) {
                                history.push(DATA_CONSTANT.routes.customQuote);
                            }
                            else {
                                setPaymentDetails(details);
                                setStripeMoalShow(true);
                            }
                        }
                    })
                );
            }
        });
    };

    const addPromocode = () => {
        let isVisiblePromocode = orderdetails.paymentDetails.discount === 0;
        setIsDisableForPromoCode({
            promoCodeTextbox: !isVisiblePromocode,
            applyPromoCodeButton: isVisiblePromocode,
        });
        setIsAddPromoCode(true);
        setPromoCodeError({ isDisplay: false, error: "" });
    };

    const applyPromoCode = () => {
        orderdetails.paymentDetails.promoCode = orderdetails.paymentDetails.promoCode.toString().trim();
        if (promoCodeError.isDisplay) {
            setIsDisableForPromoCode({
                promoCodeTextbox: true,
                applyPromoCodeButton: true,
            });
            return;
        }
        let promoCode = orderdetails.paymentDetails.promoCode.toUpperCase();
        trackPromise(applyPromoCodeService(promoCode), Areas.promoCode);
    };

    const applyPromoCodeService = async (promoCode) => {
        await OrderService.GetDiscountFromPrmocode(promoCode).then((data) => {
            if (data.errorMessage === "") {
                setOrderDetails({
                    ...orderdetails,
                    paymentDetails: {
                        ...orderdetails.paymentDetails,
                        discount: data.discount,
                    },
                });
                setIsDisableForPromoCode({
                    promoCodeTextbox: true,
                    applyPromoCodeButton: true,
                });
                setIsShowPromoCodeDetail(true);
                setIsAddPromoCode(false);
            } else {
                setPromoCodeError({ isDisplay: true, error: data.errorMessage });
                setIsDisableForPromoCode({
                    promoCodeTextbox: false,
                    applyPromoCodeButton: true,
                });
            }
            calculateTotal();
        });
    };

    const cancelPromoCode = () => {
        resetPromoCodeDetails();
        setIsAddPromoCode(false);
        setIsShowPromoCodeDetail(false);
        if (promoCodeError.isDisplay)
            setPromoCodeError({ isDisplay: false, error: "" });
    };

    const removePromoCode = () => {
        resetPromoCodeDetails();
        setIsShowPromoCodeDetail(false);
    };

    const resetPromoCodeDetails = () => {
        if (orderdetails.shippingMethod != "") {
            setOrderDetails({
                ...orderdetails,
                paymentDetails: {
                    discount: 0,
                    shippingCharge: orderdetails.paymentDetails.shippingCharge,
                    promoCode: "",
                    tax: orderdetails.paymentDetails.tax
                },
            });
        } else {
            setOrderDetails({
                ...orderdetails,
                paymentDetails: {
                    discount: 0,
                    shippingCharge: 0,
                    promoCode: "",
                    tax: orderdetails.paymentDetails.tax
                },
            });
        }
    };
    const checkValidShippingAccountNumber = (
        deliveryService,
        shippingAccountNumber
    ) => {
        switch (deliveryService.toLowerCase()) {
            case "fedex":
                if (XRegExp(DATA_CONSTANT.patterns.AccountNumberValidation_FedEx).test(shippingAccountNumber) === false) {
                    SetErrorMessageForCustomerShippingAccount({
                        isDisplay: true,
                        errorMessageForDeliveryService: "",
                        errorMessageForShippingAccountNumber: MESSAGE_CONSTANT.accountNumber.invalid.toString().formatGeneric("FedEx"),
                    });
                }
                else {
                    SetErrorMessageForCustomerShippingAccount({
                        isDisplay: false,
                        errorMessageForDeliveryService: "",
                        errorMessageForShippingAccountNumber: ""
                    });
                    isValidShippingAccountNumber = true;
                }
                break;
            case "ups":
                if (XRegExp(DATA_CONSTANT.patterns.AccountNumberValidation_UPS).test(shippingAccountNumber) === false) {
                    SetErrorMessageForCustomerShippingAccount({
                        isDisplay: true,
                        errorMessageForDeliveryService: "",
                        errorMessageForShippingAccountNumber: MESSAGE_CONSTANT.accountNumber.invalid.toString().formatGeneric("UPS"),
                    });
                }
                else {
                    SetErrorMessageForCustomerShippingAccount({
                        isDisplay: false,
                        errorMessage: "",
                    });
                    isValidShippingAccountNumber = true;
                }
                break;
        }
    };

    const getShippingChargeFromShippingMethod = (shippingMethod) => {
        let shippingCharge = 0;
        if (shippingMethod != "") {
            let shippingDetailByShippingCharge = shippingMethods.find(
                (x) =>
                    x.shippingMethodName.toLowerCase() === shippingMethod.toLowerCase()
            );
            shippingCharge = shippingDetailByShippingCharge?.shippingCharge;
        }
        return shippingCharge;
    };
    return (
        isDataLoaded && (
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Billing & Shipping</h2>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={12} md={12} lg={6} xl={6} >
                        <Row>
                            <Col sm={12} md={6} lg={6} xl={6} className="cm-input-container">
                                <InputTextBox
                                    label={"Name"}
                                    id={"name"}
                                    name={"name"}
                                    value={orderdetails.name}
                                    isRequired={true}
                                    ref={register({
                                        required: MESSAGE_CONSTANT.contactInfo.name.required,
                                        maxLength: {
                                            value: 50,
                                            message: MESSAGE_CONSTANT.contactInfo.name.maxLength,
                                        },
                                        validate: (value) =>
                                            XRegExp(DATA_CONSTANT.patterns.name).test(value) === false
                                                ? MESSAGE_CONSTANT.contactInfo.firstName.invalid
                                                : null,
                                    })}
                                    errorMessage={errors}
                                    onChange={(event) => handelChange(event, true)}
                                ></InputTextBox>
                            </Col>
                            <Col sm={12} md={6} lg={6} xl={6} className="cm-input-container">
                                <InputTextBox
                                    label={"Phone"}
                                    id={"phone"}
                                    name={"phone"}
                                    value={orderdetails.phone}
                                    maxLength={25}
                                    onChange={(event) => handelChange(event, false)}
                                ></InputTextBox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                <InputTextBox
                                    label={"Email"}
                                    id={"email"}
                                    name={"email"}
                                    value={orderdetails.email}
                                    isRequired={true}
                                    ref={register({
                                        required: MESSAGE_CONSTANT.contactInfo.email.required,
                                        pattern: {
                                            value: DATA_CONSTANT.patterns.email,
                                            message: MESSAGE_CONSTANT.contactInfo.email.invalid,
                                        },
                                    })}
                                    errorMessage={errors}
                                    onChange={(event) => handelChange(event, true)}
                                ></InputTextBox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                <InputTextBox
                                    label={"Company"}
                                    id={"company"}
                                    name={"company"}
                                    value={orderdetails.company}
                                    ref={register({
                                        pattern: {
                                            value: DATA_CONSTANT.patterns.name,
                                            message: MESSAGE_CONSTANT.contactInfo.company.invalid,
                                        },
                                    })}
                                    errorMessage={errors}
                                    onChange={(event) => handelChange(event, true)}
                                ></InputTextBox>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <h3>Shipping Address</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {taxError.isDisplay && (
                                    <Alert variant="danger mt-3">{taxError.message}</Alert>
                                )}
                            </Col>
                        </Row>
                        <LoadingWrapper loaderRef={loaderElement}>
                            <LoadingSpiner
                                area={Areas.shippingAddress}
                                parentContainer={loaderElement}
                            />
                            <Row className="mt-3">
                                <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                    <InputTextArea
                                        id={"shippingAddress1"}
                                        label={"Street Address Line1"}
                                        name={"shippingAddress1"}
                                        isRequired={true}
                                        value={orderdetails.shippingAddress1}
                                        ref={register({
                                            required: MESSAGE_CONSTANT.validation.required
                                                .toString()
                                                .formatGeneric("Street Address Line1"),
                                            maxLength: {
                                                value: 100,
                                                message: MESSAGE_CONSTANT.validation.maxValue
                                                    .toString()
                                                    .formatGeneric("Street Address Line1", "100"),
                                            },
                                            validate: (value) =>
                                                value?.trim().toString().length === 0
                                                    ? MESSAGE_CONSTANT.validation.invalid
                                                        .toString()
                                                        .formatGeneric("Street Address Line1")
                                                    : null,
                                        })}
                                        errorMessage={errors}
                                        onChange={(event) => handelChange(event, true)}
                                        onBlur={(event) => handleBlur(event)}
                                    ></InputTextArea>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                    <InputTextArea
                                        id={"shippingAddress2"}
                                        label={"Street Address Line2"}
                                        name={"shippingAddress2"}
                                        value={orderdetails.shippingAddress2}
                                        ref={register({
                                            maxLength: {
                                                value: 100,
                                                message: MESSAGE_CONSTANT.validation.maxValue
                                                    .toString()
                                                    .formatGeneric("Street Address Line2", "100"),
                                            },
                                        })}
                                        errorMessage={errors}
                                        onChange={(event) => handelChange(event, true)}
                                        onBlur={(event) => handleBlur(event)}
                                    ></InputTextArea>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                    <InputTextBox
                                        id={"shippingZip"}
                                        label={"Zip/Postal Code"}
                                        name={"shippingZip"}
                                        value={orderdetails.shippingZip}
                                        isRequired={true}
                                        ref={register({
                                            required: MESSAGE_CONSTANT.validation.required
                                                .toString()
                                                .formatGeneric("Zip/Postal Code"),
                                            maxLength: {
                                                value: 25,
                                                message: MESSAGE_CONSTANT.validation.maxValue
                                                    .toString()
                                                    .formatGeneric("Zip/Postal Code", "25"),
                                            },
                                            validate: (value) =>
                                                value?.trim().toString().length === 0
                                                    ? MESSAGE_CONSTANT.validation.invalid
                                                        .toString()
                                                        .formatGeneric("Zip/Postal Code")
                                                    : null,
                                        })}
                                        errorMessage={errors}
                                        onChange={(event) => handelChange(event, true)}
                                        onBlur={(event) => handleBlur(event)}
                                    ></InputTextBox>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                    <InputTextBox
                                        id={"shippingCity"}
                                        label={"City"}
                                        name={"shippingCity"}
                                        value={orderdetails.shippingCity}
                                        isRequired={true}
                                        ref={register({
                                            required: MESSAGE_CONSTANT.validation.required
                                                .toString()
                                                .formatGeneric("City"),
                                            maxLength: {
                                                value: 50,
                                                message: MESSAGE_CONSTANT.validation.maxValue
                                                    .toString()
                                                    .formatGeneric("City", "50"),
                                            },
                                            validate: (value) =>
                                                value?.trim().toString().length === 0
                                                    ? MESSAGE_CONSTANT.validation.invalid
                                                        .toString()
                                                        .formatGeneric("City")
                                                    : null,
                                        })}
                                        errorMessage={errors}
                                        onChange={(event) => handelChange(event, true)}
                                        onBlur={(event) => handleBlur(event)}
                                    ></InputTextBox>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                    <InputTextBox
                                        id={"shippingState"}
                                        label={"State"}
                                        name={"shippingState"}
                                        value={orderdetails.shippingState}
                                        isRequired={true}
                                        ref={register({
                                            required: MESSAGE_CONSTANT.validation.required
                                                .toString()
                                                .formatGeneric("State"),
                                            maxLength: {
                                                value: 50,
                                                message: MESSAGE_CONSTANT.validation.maxValue
                                                    .toString()
                                                    .formatGeneric("State", "50"),
                                            },
                                            validate: (value) =>
                                                value?.trim().toString().length === 0
                                                    ? MESSAGE_CONSTANT.validation.invalid
                                                        .toString()
                                                        .formatGeneric("State")
                                                    : null,
                                        })}
                                        errorMessage={errors}
                                        onChange={(event) => handelChange(event, true)}
                                        onBlur={(event) => handleBlur(event)}
                                    ></InputTextBox>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                    <InputTextBox
                                        id={"shippingCountry"}
                                        name={"shippingCountry"}
                                        label={"Country"}
                                        isRequired={true}
                                        value={orderdetails.shippingCountry}
                                        ref={register({
                                            required: MESSAGE_CONSTANT.validation.required
                                                .toString()
                                                .formatGeneric("Country"),
                                            validate: (value) =>
                                                value?.trim().toString().length === 0
                                                    ? MESSAGE_CONSTANT.validation.invalid
                                                        .toString()
                                                        .formatGeneric("Country")
                                                    : null,
                                        })}
                                        errorMessage={errors}
                                        onChange={(event) => handelChange(event, true)}
                                        onBlur={(event) => handleBlur(event)}
                                    />
                                </Col>
                            </Row>
                        </LoadingWrapper>
                        <hr />
                        <Row>
                            <Col className="cm-checkbox-label">
                                <InputCheck
                                    id={'isBillingAddress'}
                                    name={'isBillingAddress'}
                                    value={'Use separate Billing Address'}
                                    type={'checkbox'}
                                    onChange={(event) => handelChange(event, false)}
                                />
                            </Col>
                        </Row>
                        {orderdetails.isBillingAddress && (
                            <>
                                <Row className="mt-3">
                                    <Col>
                                        <h3>Billing Address</h3>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                        <InputTextArea
                                            id={"billingAddress1"}
                                            label={"Street Address Line1"}
                                            name={"billingAddress1"}
                                            value={orderdetails.billingAddress1}
                                            isRequired={true}
                                            ref={register({
                                                required: MESSAGE_CONSTANT.validation.required
                                                    .toString()
                                                    .formatGeneric("Street Address Line1"),
                                                maxLength: {
                                                    value: 100,
                                                    message: MESSAGE_CONSTANT.validation.maxValue
                                                        .toString()
                                                        .formatGeneric("Street Address Line1", "100"),
                                                },
                                                validate: (value) =>
                                                    value?.trim().toString().length === 0
                                                        ? MESSAGE_CONSTANT.validation.invalid
                                                            .toString()
                                                            .formatGeneric("Street Address Line1")
                                                        : null,
                                            })}
                                            errorMessage={errors}
                                            onChange={(event) => handelChange(event, true)}
                                        ></InputTextArea>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                        <InputTextArea
                                            id={"billingAddress2"}
                                            label={"Street Address Line2"}
                                            name={"billingAddress2"}
                                            value={orderdetails.billingAddress2}
                                            ref={register({
                                                maxLength: {
                                                    value: 100,
                                                    message: MESSAGE_CONSTANT.validation.maxValue
                                                        .toString()
                                                        .formatGeneric("Street Address Line2", "100"),
                                                },
                                            })}
                                            errorMessage={errors}
                                            onChange={(event) => handelChange(event, true)}
                                        ></InputTextArea>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                        <InputTextBox
                                            id={"billingZip"}
                                            label={"Zip/Postal Code"}
                                            name={"billingZip"}
                                            value={orderdetails.billingZip}
                                            isRequired={true}
                                            ref={register({
                                                required: MESSAGE_CONSTANT.validation.required
                                                    .toString()
                                                    .formatGeneric("Zip/Postal Code"),
                                                maxLength: {
                                                    value: 25,
                                                    message: MESSAGE_CONSTANT.validation.maxValue
                                                        .toString()
                                                        .formatGeneric("Zip/Postal Code", "25"),
                                                },
                                                validate: (value) =>
                                                    value?.trim().toString().length === 0
                                                        ? MESSAGE_CONSTANT.validation.invalid
                                                            .toString()
                                                            .formatGeneric("Zip/Postal Code")
                                                        : null,
                                            })}
                                            errorMessage={errors}
                                            onChange={(event) => handelChange(event, true)}
                                        ></InputTextBox>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                        <InputTextBox
                                            id={"billingCity"}
                                            label={"City"}
                                            name={"billingCity"}
                                            value={orderdetails.billingCity}
                                            isRequired={true}
                                            ref={register({
                                                required: MESSAGE_CONSTANT.validation.required
                                                    .toString()
                                                    .formatGeneric("City"),
                                                maxLength: {
                                                    value: 50,
                                                    message: MESSAGE_CONSTANT.validation.maxValue
                                                        .toString()
                                                        .formatGeneric("City", "50"),
                                                },
                                                validate: (value) =>
                                                    value?.trim().toString().length === 0
                                                        ? MESSAGE_CONSTANT.validation.invalid
                                                            .toString()
                                                            .formatGeneric("City")
                                                        : null,
                                            })}
                                            errorMessage={errors}
                                            onChange={(event) => handelChange(event, true)}
                                        ></InputTextBox>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                        <InputTextBox
                                            id={"billingState"}
                                            label={"State"}
                                            name={"billingState"}
                                            value={orderdetails.billingState}
                                            isRequired={true}
                                            ref={register({
                                                required: MESSAGE_CONSTANT.validation.required
                                                    .toString()
                                                    .formatGeneric("State"),
                                                maxLength: {
                                                    value: 50,
                                                    message: MESSAGE_CONSTANT.validation.maxValue
                                                        .toString()
                                                        .formatGeneric("State", "50"),
                                                },
                                                validate: (value) =>
                                                    value?.trim().toString().length === 0
                                                        ? MESSAGE_CONSTANT.validation.invalid
                                                            .toString()
                                                            .formatGeneric("State")
                                                        : null,
                                            })}
                                            errorMessage={errors}
                                            onChange={(event) => handelChange(event, true)}
                                        ></InputTextBox>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                        <InputTextBox
                                            id={"billingCountry"}
                                            name={"billingCountry"}
                                            label={"Country"}
                                            isRequired={true}
                                            value={orderdetails.billingCountry}
                                            ref={register({
                                                required: MESSAGE_CONSTANT.validation.required
                                                    .toString()
                                                    .formatGeneric("Country"),
                                                validate: (value) =>
                                                    value?.trim().toString().length == 0
                                                        ? MESSAGE_CONSTANT.validation.invalid
                                                            .toString()
                                                            .formatGeneric("Country")
                                                        : null,
                                            })}
                                            errorMessage={errors}
                                            onChange={(event) => handelChange(event, true)}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                        <hr />
                        {isPromoCodeSectionVisible &&
                            <>
                                <LoadingWrapper loaderRef={loaderElement}>
                                    <LoadingSpiner
                                        area={Areas.promoCode}
                                        parentContainer={loaderElement}
                                    />
                                    <Promocode
                                        isAddPromoCode={isAddPromoCode}
                                        isShowPromoCodeDetail={isShowPromoCodeDetail}
                                        isDisableForPromoCode={isDisableForPromoCode}
                                        orderdetails={orderdetails}
                                        promoCodeError={promoCodeError}
                                        handelChange={handelChange}
                                        addPromocode={addPromocode}
                                        applyPromoCode={applyPromoCode}
                                        cancelPromoCode={cancelPromoCode}
                                        removePromoCode={removePromoCode}
                                    />
                                </LoadingWrapper>
                                <hr />
                            </>
                        }
                        <Row>
                            <Col>
                                <h3>Shipping Method</h3>
                            </Col>
                        </Row>
                        <LoadingWrapper loaderRef={loaderElement}>
                            <LoadingSpiner
                                area={Areas.shippingMethod}
                                parentContainer={loaderElement}
                            />
                            <Row className="mt-3">
                                <Col xl={12} lg={12} md={12} sm={12} className="cm-input-container">
                                    <InputSelect
                                        initialValue={"Select"}
                                        name={"shippingMethod"}
                                        label={"Choose Shipping Method"}
                                        isRequired={true}
                                        list={shippingMethods}
                                        listValue={"shippingMethodName"}
                                        value={orderdetails.shippingMethod}
                                        ref={register({
                                            required: MESSAGE_CONSTANT.validation.required
                                                .toString()
                                                .formatGeneric("Shipping Method"),
                                        })}
                                        errorMessage={errors}
                                        onChange={(event) => handelChange(event, true)}
                                    />
                                </Col>
                            </Row>
                        </LoadingWrapper>
                        <LoadingWrapper loaderRef={loaderElement}>
                            <LoadingSpiner
                                area={Areas.usMyShippingAccount}
                                parentContainer={loaderElement}
                            />
                            <Row>
                                <Col className="cm-checkbox-label cm-input-container">
                                    <InputCheck
                                        id={'isUseshippingAccount'}
                                        name={'isUseshippingAccount'}
                                        value={'Use My Shipping Account'}
                                        type={'checkbox'}
                                        onChange={(event) => handelChange(event, false)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {orderdetails.isUseshippingAccount && (
                                    <>
                                        <Col xl={5} lg={5} md={12} sm={12} className="cm-input-container">
                                            <InputSelect
                                                initialValue={"Select"}
                                                name={"deliveryService"}
                                                label={"Delivery Service"}
                                                isRequired={CustomerShippingAccount.isRequired}
                                                list={deliveryServiceList}
                                                listValue={"Text"}
                                                value={orderdetails.deliveryService}
                                                errorMessage={errors}
                                                onChange={(event) => handelChange(event, false)}
                                            />
                                            {errorMessageForCustomerShippingAccount.isDisplay && (
                                                <span className="mt-1 error-txt">
                                                    {errorMessageForCustomerShippingAccount.errorMessageForDeliveryService}
                                                </span>
                                            )}
                                        </Col>
                                        <Col xl={7} lg={7} md={12} sm={12} className="cm-input-container">
                                            <InputTextBox
                                                label={"Shipping Account Number"}
                                                name={"shippingAccountNumber"}
                                                value={orderdetails.shippingAccountNumber}
                                                isRequired={CustomerShippingAccount.isRequired}
                                                errorMessage={errors}
                                                onChange={(event) => handelChange(event, false)}
                                            ></InputTextBox>
                                            {errorMessageForCustomerShippingAccount.isDisplay && (
                                                <span className="mt-1 error-txt">
                                                    {errorMessageForCustomerShippingAccount.errorMessageForShippingAccountNumber}
                                                </span>
                                            )}
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </LoadingWrapper>
                    </Col>
                    {isDataLoaded &&
                        <OrderSummaryView orderSummary={orderSummary} orderdetails={orderdetails} isPromoCodeSectionVisible={isPromoCodeSectionVisible}></OrderSummaryView>
                    }
                </Row>
                <Row>
                    <Col className="text-center my-5">
                        {
                            amountValidation != '' &&
                            <span className="mt-5 error-txt">
                                {amountValidation}
                            </span>
                        }
                        <PrimaryOrangeButton
                            name="Confirm Order"
                            disabled={taxError.isProcessing}
                            handleClick={() => {
                                placeOrder();
                            }}
                        />
                    </Col>
                </Row>

                {
                    isStripeModalLoaded &&
                    <PayTracePaymentModal show={stripeModalShow} handleClose={handleClose} paymentData={paymentDetails} ></PayTracePaymentModal>
                }
            </Container >
        ))
}

const mapStateToProps = (state) => ({
    RFQDetails: state.RFQDetails?.rfqDetails,
});

const mapDispatchToProps = (dispatch) => ({
    onSaveOrder: () => {
        dispatch(
            setCompletedSteps(DATA_CONSTANT.stepNames.CheckoutBillingShipping)
        );
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
