import RfqProcessLayout from '../layouts/RfqProcessLayout';
import EmailActivationPendingPage from '../pages/EmailActivationPending/EmailActivationPending';
import RedirectToLoginPage from '../pages/RedirectToLogin/RedirectToLoginPage';
import ReviewBOMPage from '../pages/BOM/ReviewBOMPage';
import GetQuote from '../pages/GetQuote/GetQuote';
import AssemblyDetail from '../pages/AssemblyDetail/AssemblyDetail';
import Pcb from '../pages/PCB/Pcb';
import Checkout from '../pages/Checkout/Checkout';
import LaborPage from '../pages/Labor/LaborPage';
import QuoteComplete from '../pages/Complete/QuoteComplete';
import ImportBOM from '../pages/BOM/ImportBOM';
import CallbackPage from '../pages/Callback/CallbackPage';
import SilentRenewalCallback from '../pages/Callback/SilentRenewalCallback';
import Home from '../pages/Home/Home';
import QuoteProcessSelectionPage from '../pages/QuoteProcessSelection/QuoteProcessSelectionPage';
import UploadBomPage from '../pages/RFQ/UploadRFQPage';
import ErrorPage from '../pages/Error/Error';
import CustomQuote from '../pages/CustomQuote/CustomQuote';
import ScrubMyBomProcessPage from '../pages/BOM/ScrubMyBomProcessPage';
import BomAnalysisComplete from '../pages/Complete/BomAnalysisComplete';

export const RouteConstant = {
    routeList: [
        { name: 'quoteProcessSelection', path: '/', exact: true, component: QuoteProcessSelectionPage, layout: null, loginRequired: false, title: 'Home' },
        { name: 'jobType', path: '/jobtype', exact: true, component: Home, layout: null, loginRequired: false, title: 'Job Type Selection' },
        { name: 'callback', path: '/callback.html', exact: true, component: CallbackPage, layout: null, loginRequired: false },
        { name: 'silentRenew', path: '/silent_renew.html', exact: true, component: SilentRenewalCallback, layout: null, loginRequired: false },
        { name: 'RedirectToLogin', path: '/RedirectToLogin', exact: true, component: RedirectToLoginPage, layout: null, loginRequired: false },
        { name: 'error', path: '/error', exact: true, component: ErrorPage, layout: null, loginRequired: false, title:'Error' },
        { name: 'emailActivationPending', path: '/emailActivationPending', exact: true, component: EmailActivationPendingPage, layout: null, loginRequired: true },

        { name: 'rfq', path: '/rfq', exact: true, component: UploadBomPage, layout: RfqProcessLayout, loginRequired: false, title: 'Upload Files' },
        { name: 'importbom', path: '/importbom', exact: true, component: ImportBOM, layout: RfqProcessLayout, loginRequired: false, title: 'Validate BOM' },
        { name: 'labor', path: '/labor', exact: true, component: LaborPage, layout: RfqProcessLayout, loginRequired: false, title: ' Assembly Details' },
        { name: 'pcb', path: '/pcb', exact: true, component: Pcb, layout: RfqProcessLayout, loginRequired: false, title: 'PCB' },
        { name: 'assemblydetail', path: '/assemblydetail', component: AssemblyDetail, exact: true, layout: RfqProcessLayout, loginRequired: false, title: 'Optional Services' },
        { name: 'reviewbom', path: '/reviewbom', exact: true, component: ReviewBOMPage, layout: RfqProcessLayout, loginRequired: false, title: 'Part Availability' },
        { name: 'complete', path: '/complete', exact: true, component: QuoteComplete, layout: RfqProcessLayout, loginRequired: false, title: 'Complete' },
        { name: 'getquote', path: '/getquote', exact: true, component: GetQuote, layout: RfqProcessLayout, loginRequired: true, title: 'Get Quote' },
        { name: 'checkout', path: '/checkout', exact: true, component: Checkout, layout: RfqProcessLayout, loginRequired: true, title: 'Checkout' },
        { name: 'customquote', path: '/customquote', exact: true, component: CustomQuote, layout: RfqProcessLayout, loginRequired: false, title: 'Custom Quote' },
        { name: 'scrubmybom', path: '/scrubmybom', exact: true, component: ScrubMyBomProcessPage, layout: RfqProcessLayout, loginRequired: false, title: 'Scrub My BOM' },
        { name: 'bomanalysiscomplete', path: '/bomanalysiscomplete', exact: true, component: BomAnalysisComplete, layout: null, loginRequired: false, title: 'Bom Analysis Complete' },
    ]
}