import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PrimaryOrangeButton } from '../../components/Common/InputButton/InputButton';
import { DATA_CONSTANT } from '../../constants/DataConstant';
import { MESSAGE_CONSTANT } from '../../constants/MessageConstant';
import { clearStore } from '../../redux/actions/ClearStoreActions';
import { setCompletedSteps } from '../../redux/actions/CompletedStepActions';
import { clearJobType } from '../../redux/actions/ClearJobType';

const QuoteComplete = ({ onQuoteComplete, selectedJobType }) => {
    const history = useHistory();

    useEffect(() => {
        onQuoteComplete(selectedJobType);
    }, []
    );

    const handleStartQuoteClicked = () => {
        history.push('/');
    };

    return (
        <Row>
            <Col md={{ span: 10 }} sm={12} xs={12}>
                <Row className="mb-4" sm={12} xs={12}>
                    <Col className="text-center">
                        <h5>Thank you for your quote request. We will get started on it immediately. If you have any questions in the meantime, please contact
                                <a href="mailto:sales@aapcb.com" className="email-link">sales@aapcb.com</a>.</h5>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center" >
                        <PrimaryOrangeButton name="Start New Quote" handleClick={() => handleStartQuoteClicked()} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};


const mapStateToProps = state => ({
    selectedJobType: state.JobTypes?.selectedJobType
});

const mapDispatchToProps = dispatch => ({
    onQuoteComplete: (selectedJobType) => {
        dispatch(clearStore());
        dispatch(clearJobType({ isAssemblyRequired: selectedJobType?.isAssemblyRequired, isBomRequired: selectedJobType?.isBomRequired, isPcbRequired: selectedJobType?.isPcbRequired, isComponentsRequired: selectedJobType?.isComponentsRequired }));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(QuoteComplete)