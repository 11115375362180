import 'antd/dist/antd.dark.css';
import "bootstrap/dist/css/bootstrap.css";
import "./fonts/Verlag-Light.ttf";
import "@fortawesome/fontawesome-free/css/all.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import { Provider as ReduxProvider } from "react-redux";
import { configureStore } from "./redux/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { OidcProvider } from "redux-oidc";
import UserManager from "./constants/UserManager";
import withClearCache from './ClearCache';
import { SignalrProvider } from './services/SignalrService';

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const store = configureStore();
let persistor = persistStore(store);

const AppComponentWithClearCache = withClearCache(App);
SignalrProvider.initializeConnection();
ReactDOM.render(
    <ReduxProvider store={store}>
        <OidcProvider store={store} userManager={UserManager}>
            <PersistGate persistor={persistor}>
                <BrowserRouter basename={baseUrl}>
                    <AppComponentWithClearCache />
                </BrowserRouter>
            </PersistGate>
        </OidcProvider>
    </ReduxProvider>,
    rootElement
);

unregister();
