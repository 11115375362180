export const ErrorPage_CONSTANT = {
    internalServer: {
        statusCode: 500,
        title: "An error occurred while processing your request.",
        message:
            "We're sorry for the inconvenience. Email us at -sales@aapcb.com- and tell us what happened.",
    },
    pageNotFound: {
        statusCode: 404,
        title: "404",
        message:
            "The resource you're looking for does not exist or has been removed.",
    },
    accessDenied: {
        statusCode: 401,
        title: "Unauthorized",
        message:
            "You're not allowed to access this resource.",
    },
};
