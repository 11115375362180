import { SET_DISABLED_STEPS, RESET_DISABLED_STEPS } from '../../constants/ActionConstants';

const InitialState = [];
export const DisabledSteps = (state = InitialState, action) => {
  const { type, data } = action;

  switch (type) {
    case SET_DISABLED_STEPS:
      const stateData = [...state];

      if (stateData.indexOf(data.stepName) < 0) {
        stateData.push(data.stepName);
      }
      return stateData;
    case RESET_DISABLED_STEPS:
      return InitialState;
    default:
      return state;
  }
};
