import React, { useEffect } from "react";
import "./ImportBOM.css";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { BOMService } from "../../services/BOMService";
import { trackPromise } from "react-promise-tracker";
import { URL_CONSTANT } from "../../constants/UrlConstant";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { CommonService } from "../../services/CommonService";
import { setCompletedSteps } from '../../redux/actions/CompletedStepActions';
import { setBom } from '../../redux/actions/SetBomIdAction';

const ImportBOM = (props) => {
    const history = useHistory();

    const appId = process.env.REACT_APP_IMPORT_WIDGET_APP_ID;

    useEffect(() => {
        if (!props.assemblyId) {
            history.push("/");
            return;
        }

        trackPromise(getBOMFile());
    }, []);

    const getBOMFile = async () => {
        await CommonService.createBomFileFromBlobUrl(
            props.selectedBomFile.src,
            props.selectedBomFile.name
        )
            .then((data) => {
                if (data) {
                    openImportWidget(data)
                }
            })
    };

    const openImportWidget = (file) => {
        let extraDataInJson = { AssemblyId: props.assemblyId };

        if (window.addEventListener) {
            window.addEventListener("message", afterImportFromWidget, false);
        } else {
            window.attachEvent("onmessage", afterImportFromWidget);
        }

        let widgetDataForImport = {
            appID: appId,
            opentype: "iframe",
            file: file,
            extraDataInJson: JSON.stringify(extraDataInJson),
            customCSS: URL_CONSTANT.CustomImportWidgetCSSUrl,
        };

        window.CQWidget("open", widgetDataForImport);
    };

    const afterImportFromWidget = (obj) => {
        if (obj.data && obj.data.importSuccessful && obj.data.data) {
            var createBOMData = {
                AssemblyId: props.assemblyId,
                BOMLineItems: obj.data.data,
                userId: getUserId(),
                isBomAnalysis: props.selectedJobType?.isBomAnalysis
            };

            trackPromise(createBOM(createBOMData));
        }
    };

    const createBOM = async (bomData) => {

        await BOMService.createBOM(bomData).then((data) => {
            if (data != null) {
                props.onSetBomId(data);
            }
            props.onStepCompleted();

            if (window.removeEventListener) {
                window.removeEventListener("message", afterImportFromWidget, false);
            }

            let nextStepIndex = DATA_CONSTANT.rfqSteps.findIndex(x => x.stepName === DATA_CONSTANT.stepNames.BOM) + 1;
            history.push(DATA_CONSTANT.rfqSteps[nextStepIndex].routeName);
        })
    };

    const getUserId = () => {
        let userId = props.salesPersonId;
        return userId;
    };

    return (
        <Container>
            <Row>
                <Col>
                    <div id="widgetReplaceId"></div>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = state => ({
    selectedJobType: state.JobTypes?.selectedJobType,
    assemblyId: state.RFQDetails?.rfqDetails?.assemblyId,
    selectedBomFile: state.BomFile?.bomFile,
    salesPersonId: state.DefaultConfigs.defaultConfigs.salesPersonId,
    bomId: state.BomIds?.BomId
});

const mapDispatchToProps = dispatch => ({

    onStepCompleted: () => {
        dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.BOM));
        // dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.Completed));
    },

    onSetBomId: (val) => {
        dispatch(setBom(val));
    }

});

export default connect(mapStateToProps, mapDispatchToProps)(ImportBOM);
