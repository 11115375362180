import { SET_PRICING_RUNNING_STATUS, CLEAR_PRICING_RUNNING_STATUS } from '../../constants/ActionConstants';

export const SetPricingRunningStatus = (pricingRunningStatus) => ({
    type: SET_PRICING_RUNNING_STATUS,
    data: { pricingRunningStatus: pricingRunningStatus }
});

export const ClearPricingRunningStatus = () => ({
    type: CLEAR_PRICING_RUNNING_STATUS,
    data: {}
});