import React, { useEffect, useState } from "react";
import "./global.style.css";
import Routes from "./Routes";
import { LoadingSpiner } from "./components/Common/LoadingSpiner";
import { URL_CONSTANT } from "./constants/UrlConstant";
import { CommonHttpService } from "./services/CommonHttpService";
import { DATA_CONSTANT } from "./constants/DataConstant";
import Extension from './prototype/Extensions';
import { OIDCService } from "./services/OIDCService";
import usePageViews from '../src/CustomHooks/GoogleAnalytics/UsePageView'
import { DynamicLabelService } from "./services/DynamicLabelService";

const App = () => {
    const [isUserLoaded, setIsUserLoaded] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    Extension();

    const pageViews = usePageViews();

    useEffect(() => {
        getConfiguration();
        checkUserLoggedIn();
        getDynamicLabels();
    }, []);

    const getConfiguration = () => {
        const configKeys = [];
        let HubSpotId = DATA_CONSTANT.ConfigurationKey.HubSpotAPPId;
        let TagId = DATA_CONSTANT.ConfigurationKey.GoogleAnalyticsTagId;
        configKeys.push(HubSpotId);
        configKeys.push(TagId);
        CommonHttpService.httpPost(URL_CONSTANT.Configuration.GetConfigurations, configKeys)
            .then((data) => {
                if (data && (data.length > 0)) {
                    data.map((val, ind) => {
                        if (val.key == HubSpotId) {
                            const d = document;
                            const s = d.createElement("script");
                            s.src = "https://js.hs-scripts.com/" + val.value + ".js";
                            s.async = true;
                            d.getElementsByTagName("body")[0].appendChild(s);
                        } else if (val.key == TagId) {
                            const doc = document;
                            const managerscript = doc.createElement("script");
                            managerscript.src = "https://www.googletagmanager.com/gtag/js?id=" + val.value;
                            managerscript.async = true;
                            doc.getElementsByTagName("head")[0].appendChild(managerscript);

                            const gtag = doc.createElement("script");
                            gtag.innerHTML = `window.dataLayer = window.dataLayer || [];
		                        function gtag(){dataLayer.push(arguments);}
			                        gtag('js', new Date());
			                        gtag('config', '${val.value}', {
				                        'linker': {
					                        'domains': ['aapcb.com', 'portal.aapcb.com']
				                        }
		                        });`;
                            doc.getElementsByTagName("head")[0].appendChild(gtag);
                        }
                    });
                }
            })
    };

    const checkUserLoggedIn = () => {
        OIDCService.loadUser().then(function (user) {
            setIsUserLoaded(true);
        }).catch(function () { setIsUserLoaded(true); });
    };

    const getDynamicLabels = () => {
        DynamicLabelService.GetDynamicLabels().then((data) => {
            if (data) {
                DATA_CONSTANT.dynamicLabels = data.dynamicLabels;
                setIsLoaded(true);
            }
        });
    }

    return (
        <>
            {
                isUserLoaded && isLoaded ? (
                    <Routes />
                ) : null
            }
            <LoadingSpiner />
        </>
    );
};

export default App;
