import { RESET_COMPLETED_STEPS, SET_COMPLETED_STEPS, SET_DISABLED_STEPS } from '../../constants/ActionConstants';

export const setCompletedSteps = (stepName) => ({
  type: SET_COMPLETED_STEPS,
  data: { stepName: stepName }
});

export const resetCompletedSteps = () => ({
  type: RESET_COMPLETED_STEPS,
  data: {}
});
