import React, { useState } from 'react'
import DataGrid from '../../components/Common/AntDataGrid/DataGrid';
import { FaCircle } from "react-icons/fa";
import './BOMLineItem.css';
import { Sorter } from '../../components/Common/AntDataGrid/DataGridUtils/sorter';
import Dropdown from '../../components/Common/AntDataGrid/DataGridUtils/EditableComponent/Dropdown';
import { FcPlus } from "react-icons/fc";
import { ReviewBOMConstant } from '../../constants/ReviewBOMConstants';

const BOMLineItem = ({ bomLineItem, handleChanged, onAddAlternateClick, methodOfSupplyList }) => {
    const data = bomLineItem;
    let addAlternate = ReviewBOMConstant.MethodOfSupply.AddAlternate.toUpperCase().trim();
    let antdTableScroll = data.length > 0 ? { y: 350, x: 1300 } : { x: 1300 };

    const [pageSize, setPageSize] = useState(100);

    const checkIssuedLineItem = (data) => {
        return data.isIssue;
    }

    const columns = [
        {
            dataIndex: 'lineItemId',
            title: '',
            width: 50,
            fixed: 'left',
            render: (lineItemId, data) => {
                return (
                    <>
                        {data.methodOfSupply.toUpperCase().trim() == addAlternate && <span className="btnAddAlternate" title={ReviewBOMConstant.Title.AddAlternate}>
                                <FcPlus size={12} onClick={() => onAddAlternateClick(lineItemId)} /></span>}                        
                    </>
                );
            },
        },
        {
            dataIndex: 'lineItem',
            title: 'Line',
            width: 90,
            fixed: 'left',
            sorter: {
                compare: Sorter.NUMSTRING,
            },
            render: (lineItem, data) => {
                if (checkIssuedLineItem(data)) {
                    return (
                        <>
                            <span className="issueBOMLineItem" title={data.issueAndAlert}><FaCircle size={8} />&nbsp;{lineItem}</span>
                        </>
                    );
                }
                else {
                    return `${lineItem}`;
                }
            },
            customFilter: {}
        },
        {
            dataIndex: 'selectedManufacturer',
            title: 'Manufacturer',
            width: 150,
            render: (selectedManufacturer, data) => {
                let mfgrList = ([...new Set(data.bomManufacturer.split("|"))]);
                let mfgrs = mfgrList.filter(x => x != "").join(", ");
                return (
                    <>
                        {data.selectedPrice == null ? (<span className="textOverflow" title={mfgrs}>{mfgrs}</span>) :
                            (mfgrList.some(x => data.selectedManufacturer.toUpperCase().trim() != x.toUpperCase().trim()) ?
                                (<><span className="textOverflow" title={`Quoted: ${selectedManufacturer}`}>{selectedManufacturer}</span><span className="textOverflow cm-requested-lineitem" title={`Requested: ${mfgrs}`}>{mfgrs}</span></>) : (<span className="textOverflow" title={selectedManufacturer}>{selectedManufacturer}</span>))
                        }
                    </>
                )
            },
            sorter: {
                compare: Sorter.DEFAULT,
            },
            customFilter: {}
        },
        {
            dataIndex: 'selectedMPN',
            title: 'MPN',
            width: 150,
            render: (selectedMPN, data) => {
                let mpnList = ([...new Set(data.manufacturerPN.split("|"))]);
                let mpns = mpnList.filter(x => x != "").join(", ");
                return (
                    <>
                        {data.selectedPrice == null ? (<span className="textOverflow" title={mpns}>{mpns}</span>) :
                            (mpnList.some(x => x.toUpperCase().trim() != selectedMPN.toUpperCase().trim())) ? (<><span className="textOverflow" title={`Quoted: ${selectedMPN}`}>{selectedMPN}</span><span className="textOverflow cm-requested-lineitem" title={`Requested: ${mpns}`}>{mpns}</span></>) : (<span className="textOverflow" title={selectedMPN}>{selectedMPN}</span>)
                        }
                    </>
                )
            },
            sorter: {
                compare: Sorter.DEFAULT,
            },
            customFilter: {}
        },
        {
            dataIndex: 'bomDescription',
            title: 'Description',
            width: 220,
            render: (description) => {
                return (
                    <>
                        <span className="textOverflow" title={description}>{description}</span>
                    </>
                )
            },
            sorter: {
                compare: Sorter.DEFAULT,
            },
            customFilter: {}

        },
        {
            dataIndex: 'referenceDesignator',
            title: 'Reference Designator',
            key: 'referenceDesignator',
            width: 150,
            render: (referenceDesignator) => {
                return (
                    <>
                        <span className="textOverflow" title={referenceDesignator}>{referenceDesignator}</span>
                    </>
                )
            },
            sorter: {
                compare: Sorter.DEFAULT,
            },
            customFilter: {}
        },
        {
            dataIndex: 'totalQty',
            title: 'Total Quantity',
            width: 120,
            sorter: {
                compare: Sorter.DEFAULT,
            },
            customFilter: {}
        },
        {
            dataIndex: 'methodOfSupply',
            title: 'Method Of Supply',
            width: 300,
            sorter: {
                compare: Sorter.DEFAULT,
            },
            render: (methodOfSupply, data) => {
                return (
                    <>
                        <Dropdown data={data.availableOptions} defaultValue={methodOfSupply == null ? "0" : methodOfSupply} onChanged={handleChanged} name={`bomlineitem_${data.lineItemId}_${data.isIssue}_${data.quantityId}`} />
                    </>
                )
            },
            filters: methodOfSupplyList,
            onFilter: ((value, record) => {
                return (
                    record.methodOfSupply != null ? record.methodOfSupply.includes(value) : ''
                )
            })
        },
        {
            dataIndex: 'issueAndAlert',
            title: 'Issue/Alert',
            width: 150,
            render: (issueAndAlert) => {
                return (
                    <>
                        <span className="textOverflow" title={issueAndAlert}>{issueAndAlert}</span>
                    </>
                )
            },
            sorter: {
                compare: Sorter.DEFAULT,
            },
            customFilter: {}
        },

    ];

    return (
        <>

            <DataGrid
                rowKey={data => data.purchaseId} //unique row Id
                columns={columns}
                dataSource={data}
                className="anttable-Row anttable-body"
                pagination={{
                    pageSize: pageSize,
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                    onChange: (page, pageSize) => {
                        setPageSize(pageSize);
                    }
                }
                }
                scroll={antdTableScroll} // set Horizontal and vertial scroll 
            >
            </DataGrid>
        </>
    )
}
export default BOMLineItem;
