import React from 'react';
import { FormControl } from 'react-bootstrap';

const Dropdown = ({ data, defaultValue, onChanged, name }) => {
    return (
        <>
            <FormControl
                as="select"
                name={name}
                value={defaultValue}
                onChange={onChanged(data)}>
                {
                    data.map((data, key) => {
                        return <option key={key} value={data.name}>{data.displayText}</option>;
                    })
                }
            </FormControl>
        </>
    )
}

export default Dropdown;