import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-currency-format';

const CurrencyFormat = ({
    value,
    displayType,
    ...props
}) => {

    return (
        <>
            <NumberFormat
                thousandSeparator={true}
                prefix="$ "
                value={value}
                displayType={displayType}
                {...props}
            />
        </>
    )
}

CurrencyFormat.defaultProps = {
    displayType: "text" // Used to display passed text
}

CurrencyFormat.propTypes = {
    displayType: PropTypes.oneOf(['text', 'input', '']),
    value: PropTypes.any.isRequired
}

export default CurrencyFormat
