import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import PropTypes from "prop-types";
import "./InfoToolTip.css";

const InfoToolTip = ({
    helpText, // Displays information tooltip
    helpPlacement, // Placement for tooltip
    className, //Classname for tooltip
    ...props
}) => {
    const style = {
        color: "#1A71D2",
        marginLeft: "0.5rem",
        cursor: "pointer",
    };
    return (
        <>
            {/* <p>{helpText}</p> */}
            <OverlayTrigger
                //trigger="click" //-- By default it works for hover and focus. To manage click event we can pass trigger
                placement={helpPlacement || "right"}
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="overlay-InputCheck">{helpText}</Tooltip>}
                {...props}
            >
                <i style={style} className={className}>
                    <FaInfoCircle />
                </i>
            </OverlayTrigger>
        </>
    );
};

InfoToolTip.defaultProps = {
    type: "text",
    className: "",
    //placement: "right",
};

InfoToolTip.propTypes = {
    helpText: PropTypes.string.isRequired,
    placement: PropTypes.oneOf(["right", "left", "top", "bottom"]),
};

export default InfoToolTip;
