export const ReviewBOMConstant = {
    MethodOfSupply: {
        AdvancedAssemblyWillSupply: "AdvancedAssemblyWillSupply",
        AcceptAlternative: "AcceptAlternative",
        CustomerWillSupply: "CustomerWillSupply",
        DoNotPopulate: "DoNotPopulate",
        GetSourcingHelp: "GetSourcingHelp",
        AddAlternate: "AddAlternate",
    },
    Title: {
        AddAlternate: "Add Alternate"
    }
}