import React from 'react';
import { Accordion, Card, Col, Row, Table } from 'react-bootstrap';
import './Faq.css';
import FaqToggle from '../../components/FormatBom/FaqToggle';

const Faq = (props) => {
    const isTableFormat = props.isTableFormat;
    return (
        <Row>
            <Col className="my-2">
                <Accordion>
                    <Card>
                        <Card.Header>
                            <FaqToggle className="cursor-pointer" eventKey={props.eventKey}>
                                <h5 className="LeadPara mb-0">{props.title}</h5>
                            </FaqToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={props.eventKey}>
                            <Card.Body>
                                {
                                    isTableFormat ?
                                        (
                                            <Table striped bordered hover responsive cellPadding="0" cellSpacing="0">
                                                <thead>
                                                    <tr>
                                                        <th>Mfg Part #</th>
                                                        <th>Description Value Pkg</th>
                                                        <th>Ref. Des.</th>
                                                        <th>Qty</th>
                                                        <th>DNI</th>
                                                        <th>Notes</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.description.map((data, index) =>
                                                            <tr key={index}>
                                                                <td className="bom_col_width">{data.mfgPart}</td>
                                                                <td className="bom_col_width">{data.descriptionvaluepkg}</td>
                                                                <td className="bom_col_width">{data.referencedescription}</td>
                                                                <td>{data.qty}</td>
                                                                <td>{data.dni}</td>
                                                                <td className="bom_col_width">{data.notes}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        ) :
                                        (
                                            <ul>
                                                {
                                                    props.description.map((data, index) => {
                                                        if (data.title == undefined) {
                                                            return (<li key={index}>{data.description}</li>)
                                                        }
                                                        else {
                                                            return (<li key={index}> <span className="bestpracticetitle_desc">{data.title}&nbsp;:&nbsp;</span>{data.description}</li>)
                                                        }
                                                    })
                                                }
                                            </ul>
                                        )
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
        </Row>
    )
};

export default Faq;