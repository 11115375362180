import React from "react";
import HeaderWithMenu from '../components/Header/HeaderWithMenu';
import './RfqProcessLayout.css'
import { Container, Row, Col } from "react-bootstrap";
import LeftRfqMenu from "../components/Navigation/LeftRfqMenu";
import { connect } from "react-redux";

const RfqProcessLayout = (props) => {
  return (
    <div className="body-wrapper">
      <HeaderWithMenu />
      <div id="main-wrapper">
        <Container fluid>
          <Row>
            <Col md={2} className="mb-4 pl-0" >
                <LeftRfqMenu completedSteps={props.completedSteps} disabledSteps={props.disabledSteps} selectedJobType={props.selectedJobType } isCustomQuoteRequires={props.customQuoteRequires.IsCustomQuoteRequires} />
            </Col>
            <Col lg={10}>
                {props.children}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
};

const mapStateToProps = state => ({
  completedSteps: state.CompletedSteps ? state.CompletedSteps : [],
  selectedJobType: state.JobTypes?.selectedJobType,
  customQuoteRequires: state.CustomQuote,
  disabledSteps: state.DisabledSteps ? state.DisabledSteps : [],
});


export default connect(mapStateToProps)(RfqProcessLayout);