import React from "react";
import { CallbackComponent } from "redux-oidc";
import UserManager from "../../constants/UserManager";
import { SessionStorageService } from "../../services/SessionStorageService";
import { OIDCService } from "../../services/OIDCService";
import { history } from "../../History";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { connect } from "react-redux";
import { RequestFormService } from "../../services/RequestFormService";
import { setExternalUserId } from "../../redux/actions/RFQDetailActions";

const CallbackPage = ({ rfqDetails, onSetExternalUserId }) => {

    return (
        <CallbackComponent
            userManager={UserManager}
            successCallback={
                async (user) => {
                    if (user && user.state) {

                        //Update Assemblywise ExternalUserId in QuoteCQ
                        if (rfqDetails?.assemblyId && !rfqDetails?.externalUserId) {
                            let userId = user.profile ? user.profile.sub : null

                            let fieldsToUpdate = [
                                {
                                    Name: DATA_CONSTANT.AssemblyFields.ExternalUserId,
                                    Value: userId
                                }
                            ];

                            let requestObject = {
                                AssemblyId: rfqDetails?.assemblyId,
                                FieldsToUpdate: fieldsToUpdate
                            };

                            await RequestFormService.UpdateAssemblyFieldValue(requestObject).then((data) => {
                                if (data) {
                                    onSetExternalUserId(userId);
                                }
                            });
                        }

                        let userState = user.state;
                        if (userState.uri?.includes('RedirectToLogin') ||
                            (userState.uri?.includes('emailActivationPending') && user.profile.EmailConfirmed?.toLowerCase() == 'true')
                        ) {
                            const postLoginUrl = SessionStorageService.getPostLoginUrl() || '';
                            SessionStorageService.removePostLoginUrl();
                            OIDCService.setUser(user);
                            window.location.href = postLoginUrl;
                        }
                        else {
                            if (user.profile.EmailConfirmed?.toLowerCase() == 'true') {
                                SessionStorageService.removePostLoginUrl();
                            }
                            let redirectUri = JSON.parse(userState.uri);
                            window.location.href = redirectUri
                        }
                    } else {
                        window.location.href = "";
                    }
                }}
            errorCallback={error => {
                if (error.message == 'Unauthorized') {
                    history.push(DATA_CONSTANT.routes.error, 401);
                }
            }}>
            <div></div>
        </CallbackComponent>
    );
};

const mapStateToProps = (state) => ({
    rfqDetails: state.RFQDetails?.rfqDetails,
});

const mapDispatchToProps = (dispatch) => ({
    onSetExternalUserId: (userId) =>
        dispatch(setExternalUserId(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage);
