import { CommonHttpService } from './CommonHttpService';
import { URL_CONSTANT } from '../constants/UrlConstant';

export const CheckListBuilderService = {
    getCheckListBuilder: (jobTypeName, screenName) =>{
        //const url = `${URL_CONSTANT.CheckListBuilderUrl}?JobType=${JobTypeName}&ScreenName=${ScreenName}`;
        //return CommonHttpService.httpGet(url);
        const url = `${URL_CONSTANT.CheckListBuilderUrl}`;
        const params = { jobType: jobTypeName, screenName: screenName };
        return CommonHttpService.httpGet(url, params);
    },
    submitCheckListBuilder:(data) =>{
        const url = `${URL_CONSTANT.SubmitCheckListBuilder}`;
        return CommonHttpService.httpPost(url, data);
    }
};
