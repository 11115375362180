import React, { memo, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { trackPromise } from "react-promise-tracker";
import { PrimaryGreyButton, PrimaryOrangeButton } from '../../components/Common/InputButton/InputButton';
import { MESSAGE_CONSTANT } from '../../constants/MessageConstant';
import { DATA_CONSTANT } from '../../constants/DataConstant';
import { ReviewBOMConstant } from '../../constants/ReviewBOMConstants';
import { PricingService } from '../../services/PricingService';
import BOMLineItem from './BOMLineItem';
import BOMSummary from './BOMSummary';
import RequestedQuantity from './RequestedQuantity';
import './ReviewBOM.css';
import AddAlternates from './AddAlternates';
import Swal from 'sweetalert2';
import { max, uniq, pluck } from "underscore";
import { ReportService } from "../../services/ReportService";

const ReviewBOM = ({ assemblyId, submitDataHandler, salesPersonId, rfqId, handleRerunPrice, selectedJobType }) => {

    let updateBomLineItem = [];
    let invalidMethodOfSupply = [];
    let isIssuesFilter = false;

    const [requestedQuantityData, setRequestedQuantityData] = useState({ issue: 0, requestedQuantity: 0 });

    const [requestedQuantityId, setRequestedQuantityId] = useState(0);

    const [bomLineItem, setbomLineItem] = useState({});

    const [filterBOMLineItem, setFilterBOMLineItem] = useState({});

    const [isFilter, setIsFilter] = useState(false);

    const [isLoaded, setIsLoaded] = useState(false);

    const [isQuantityDataLoaded, setIsQuantityDataLoaded] = useState(false);

    const [bomSummaryData, setBOMSummaryData] = useState({});

    const [methodOfSupplyValidation, setMethodOfSupplyValidation] = useState({ message: "", isDisplay: false });

    const [isActive, setIsActive] = useState({ allparts: true, issueOnly: false });

    const [bomCleanMessage, setBOMCleanMessage] = useState({ isDisplay: false, description: '' });

    const [methodOfSupplyList, setMethodOfSupplyList] = useState([]);

    const [addAlternateModalShow, setAddAlternateModalShow] = useState(false);

    const [alternatePartsLineItem, setAlternatePartsLineItem] = useState([{ lineItem: 0, lineItemId: 0, isAlternateAdded: false, partNumberList: [{ partNumberId: 0, manufacturer: '', mpn: '', description: '', referenceDesignator: '', isAlternateAlreadyExist: false, isAdd: false, isEdit: false, isDelete: false, isExistingLineItem: false }] }]);

    const [lineItemforAddAlternate, setLineItemforAddAlternate] = useState({});


    useEffect(() => {
        getRequestedQuantity()
    }, [])

    useEffect(() => {
        if (isQuantityDataLoaded) {
            trackPromise(
                getPurchaseData()
            );
        }
    }, [isQuantityDataLoaded])

    useEffect(() => {
        updateBomLineItem = { ...bomLineItem };
    }, [bomLineItem]);

    useEffect(() => {
        if (isLoaded) {
            let invalidLineItemArray = validateMethodOfSupply();
            if (bomSummaryData.totalIssues === 0 && invalidLineItemArray.length === 0) {
                setBOMCleanMessage({
                    isDisplay: true,
                    description: MESSAGE_CONSTANT.ReviewBOM.bomCleanMessage
                })
            }
            else {
                setBOMCleanMessage({
                    isDisplay: false,
                    description: ''
                })
            }
        }
    }, [bomSummaryData.totalIssues, isLoaded, bomLineItem])

    const getRequestedQuantity = async () => {
        await PricingService.getRequestedQuantity(assemblyId).then((data) => {
            if (data) {
                setRequestedQuantityData(data);
                setRequestedQuantityId(data[0].quantityId);
                setIsQuantityDataLoaded(true);
            }
        })
    }

    const getPurchaseData = async () => {
        let requestObj = {
            AssemblyId: assemblyId,
            LineItemId: [],
            QuantityId: [],
            IncludeUnPricedLines: true,
            IsBomAnalysis: selectedJobType?.isBomAnalysis
        }
        await PricingService.getPurchaseData(requestObj).then((data) => {
            if (data) {
                let alternateLineItemList = [];

                let methodOfSupplyList = data.methodOfSupplyList.map((item) => {
                    return { text: item.displayText, value: item.name };
                });

                setbomLineItem(data.purchaseLines);
                setBOMSummaryData(data.bomSummary);
                setFilterBOMLineItem(data.purchaseLines);
                setMethodOfSupplyList(methodOfSupplyList);
                requestedQuantityByIssue(data.purchaseLines);

                if (data.bomSummary.totalIssues > 0) {
                    setIsActive({ allparts: false, issueOnly: true });
                    setIsFilter(true);
                    isIssuesFilter = true;
                }

                //----Create array for alternate parts line item----
                data.purchaseLines.map((lineItem) => {
                    let partNumberList = [];
                    let bomManufacturer = lineItem.bomManufacturer.split("|");
                    let manufacturerPN = lineItem.manufacturerPN.split("|");
                    if (bomManufacturer.length > 0 || manufacturerPN.length > 0) {
                        for (let i = 0, j = 0; i < bomManufacturer.length, j < manufacturerPN.length; i++, j++) {
                            let alternatePartNumber = {
                                partNumberId: i + 1,
                                manufacturer: !bomManufacturer[i] ? "" : bomManufacturer[i],
                                mpn: manufacturerPN[j],
                                description: lineItem.bomDescription,
                                referenceDesignator: lineItem.referenceDesignator,
                                lineItem: lineItem.lineItem,
                                isExistingLineItem: true,
                                isAdd: i != 0 ? true : false,
                                isEdit: i != 0 ? true : false,
                                isDelete: i != 0 ? true : false,
                                isAlternateAlreadyExist: true                   //for already exist alternate parts in lineitem
                            }
                            partNumberList.push(alternatePartNumber);
                        }
                    }
                    let alternateLineItem = {
                        lineItem: lineItem.lineItem,
                        lineItemId: lineItem.lineItemId,
                        isAlternateAdded: false,
                        partNumberList: partNumberList
                    }
                    alternateLineItemList.push(alternateLineItem);
                });
                //------------------------------------------------------------------

                filterBOMLineItemByRequestedQuantity(data.purchaseLines, requestedQuantityId, isIssuesFilter);
                alternateLineItemList = uniq(alternateLineItemList, 'lineItemId');
                setAlternatePartsLineItem(alternateLineItemList);
                setIsLoaded(true);
            }
        })
    }

    const requestedQuantityByIssue = (BOMLineItem) => {
        let requestedQuantityByIssue = [];
        requestedQuantityData.map((data) => {
            let requestedQuantity = {};
            let requestedQtyIssueCount = BOMLineItem.filter(q => q.isIssue == true && q.quantityId == data.quantityId).length;
            requestedQuantity.quantityId = data.quantityId;
            requestedQuantity.issue = requestedQtyIssueCount;
            requestedQuantity.requestedQuantity = data.requestQuantity;
            requestedQuantityByIssue.push(requestedQuantity);
        });
        setRequestedQuantityData(requestedQuantityByIssue);
    }

    const filterBOMLineItemByRequestedQuantity = (BOMLineItem, requestedQuantityId, isIssuesFilter) => {
        let BOMLineItemByRequestedQty = [];
        if (isIssuesFilter) {
            BOMLineItemByRequestedQty = BOMLineItem.filter(q => q.quantityId == requestedQuantityId && q.isIssue == true);
        }
        else {
            BOMLineItemByRequestedQty = BOMLineItem.filter(q => q.quantityId == requestedQuantityId);
        }
        setFilterBOMLineItem(BOMLineItemByRequestedQty);
        setIsFilter(true);
    }

    const validateMethodOfSupply = () => {
        bomLineItem.filter(b => b.methodOfSupply == "" || b.methodOfSupply == "Select" || b.methodOfSupply == "0").map((data, index) => {
            invalidMethodOfSupply.push(data.lineItem);
        });
        invalidMethodOfSupply = Array.from([...new Set(invalidMethodOfSupply)]);
        return invalidMethodOfSupply;

    }
    const handleRequestedQuantitychanged = (requestedQuantityId) => {
        setRequestedQuantityId(requestedQuantityId);
        isIssuesFilter = isActive.issueOnly == true ? true : false;
        filterBOMLineItemByRequestedQuantity(bomLineItem, requestedQuantityId, isIssuesFilter);
    }

    const handleChanged = (event) => {
        let customerWillSupply = ReviewBOMConstant.MethodOfSupply.CustomerWillSupply.toUpperCase().trim();
        let doNotPopulate = ReviewBOMConstant.MethodOfSupply.DoNotPopulate.toUpperCase().trim();
        let addAlternate = ReviewBOMConstant.MethodOfSupply.AddAlternate.toUpperCase().trim();

        let value = event.target.value;
        let lineItemId = parseInt(event.target.name.toString().split("_")[1]);
        let isIssue = Boolean(event.target.name.toString().split("_")[2]);
        let quantityId = parseInt(event.target.name.toString().split("_")[3]);

        updateBomLineItem = [...bomLineItem];

        //Lineitem wise change method of supply for only [1]customer Will Supply & [2]Do not supply & [3]Add Alternate
        let methodOfSupply = value.toUpperCase().trim();
        if (methodOfSupply === customerWillSupply || methodOfSupply === doNotPopulate || methodOfSupply === addAlternate) {
            updateBomLineItem.filter(b => b.lineItemId == lineItemId).map((data) => {
                if (data.isIssue && methodOfSupply !== addAlternate) {
                    updateRequestedQuantityWiseLineItemIssueCount(data.quantityId);
                }
                else if (!data.isIssue && methodOfSupply === addAlternate) {
                    resetRequestedQuantityWiseLineItemIssueCount(data.quantityId, !data.isIssue);
                }
                data.isIssue = (methodOfSupply === addAlternate) ? true : !isIssue;
                data.methodOfSupply = value;
                data.isExistMethodOfSupplySelections = false;
            });
        }
        else {
            //Requested Quantity wise change method of supply for [1]Advanced Will Supply & [2]Accept alternative [3] Get sourcing Help
            updateBomLineItem.filter(b => b.lineItemId == lineItemId && b.quantityId === quantityId).map((data) => {
                if (data.isIssue) {
                    updateRequestedQuantityWiseLineItemIssueCount(data.quantityId);
                }
                data.isIssue = !isIssue;
                let lineItemindex = updateBomLineItem.findIndex(b => b.lineItemId == lineItemId && b.quantityId === quantityId);
                updateBomLineItem[lineItemindex].methodOfSupply = value;
                updateBomLineItem[lineItemindex].isExistMethodOfSupplySelections = false;
            });
            //Reset Method of supply option to select for other quantities & reset issuecount
            updateBomLineItem.filter(b => b.quantityId != quantityId && b.lineItemId == lineItemId).map((data) => {
                if (data.methodOfSupply.toUpperCase().trim() === customerWillSupply || data.methodOfSupply.toUpperCase().trim() === doNotPopulate) {
                    data.methodOfSupply = "0";
                    data.isIssue = data.issueAndAlert && !data.isAlert ? true : false;
                    if (data.isIssue) {
                        resetRequestedQuantityWiseLineItemIssueCount(data.quantityId, data.isIssue);
                    }
                }
                else if (data.methodOfSupply.toUpperCase().trim() === addAlternate) {
                    data.methodOfSupply = "0";
                    let isAlreadyIssue = data.isIssue;
                    data.isIssue = data.issueAndAlert && !data.isAlert ? true : false;
                    if (!isAlreadyIssue && data.isIssue) {
                        resetRequestedQuantityWiseLineItemIssueCount(data.quantityId, data.isIssue);
                    }
                    else if (!data.isIssue && isAlreadyIssue) {
                        updateRequestedQuantityWiseLineItemIssueCount(data.quantityId);
                    }
                }
            })
        }

        setRequestedQuantityData(requestedQuantityData);

        bomSummaryData.totalIssues = updateBomLineItem.filter(q => q.isIssue == true).length;

        setBOMSummaryData(bomSummaryData);

        setbomLineItem([...updateBomLineItem]);

        if (isActive.issueOnly) {
            setRequestedQuantityId(requestedQuantityId);
            isIssuesFilter = isActive.issueOnly ? true : false;
            filterBOMLineItemByRequestedQuantity(bomLineItem, requestedQuantityId, isIssuesFilter);
        }

        //Manage Validation messgae for method of supply
        if (methodOfSupplyValidation.isDisplay || bomCleanMessage.isDisplay) {
            invalidMethodOfSupply = validateMethodOfSupply();
            if (invalidMethodOfSupply.length != 0) {
                setMethodOfSupplyValidation({ message: `${MESSAGE_CONSTANT.reviewBOM.requiredMethodOfSupply}${invalidMethodOfSupply.join(',')}.`, isDisplay: true });
                return;
            }
            else {
                setMethodOfSupplyValidation({ message: "", isDisplay: false });
            }
        }
    }

    const resetRequestedQuantityWiseLineItemIssueCount = (quantityId) => {
        let requestedQuantityWiseIssueCount;
        let index = requestedQuantityData.findIndex(x => x.quantityId === quantityId);
        requestedQuantityWiseIssueCount = requestedQuantityData.filter(x => x.quantityId === quantityId)[0].issue + 1
        requestedQuantityData[index].issue = requestedQuantityWiseIssueCount > 0 ? requestedQuantityWiseIssueCount : 0;
    }

    const updateRequestedQuantityWiseLineItemIssueCount = (quantityId) => {
        let requestedQuantityWiseIssueCount;
        let index = requestedQuantityData.findIndex(x => x.quantityId === quantityId);
        requestedQuantityWiseIssueCount = requestedQuantityData.filter(x => x.quantityId === quantityId)[0].issue - 1;
        requestedQuantityData[index].issue = requestedQuantityWiseIssueCount > 0 ? requestedQuantityWiseIssueCount : 0;
    }

    const filterData = (type) => {
        if (type == 'Issue') {
            setIsFilter(true);
            setIsActive({ allparts: false, issueOnly: true })
            isIssuesFilter = true;
            filterBOMLineItemByRequestedQuantity(bomLineItem, requestedQuantityId, isIssuesFilter);
        }
        else {
            setIsActive({ allparts: true, issueOnly: false })
            setIsFilter(false);
            isIssuesFilter = false;
            filterBOMLineItemByRequestedQuantity(bomLineItem, requestedQuantityId, isIssuesFilter);
        }
    }

    const handleLineItemClick = (lineItemId) => {
        let lineItem = alternatePartsLineItem.find(x => x.lineItemId == lineItemId);

        //---Manage add,edit & delete functionality for repriced lineitem--------
        let maxPartNumberId = max(lineItem.partNumberList.filter(x => x.isAlternateAlreadyExist), function (o) { return o.partNumberId; }).partNumberId;
        lineItem.partNumberList.filter(x => x.isAlternateAlreadyExist).map((data, index) => {
            data.isAdd = (data.partNumberId == maxPartNumberId && index != 0) ? true : false;
            data.isDelete = false;
            data.isEdit = false;
        });
        setLineItemforAddAlternate(lineItem);
        setAddAlternateModalShow(true);
    }

    const handleClose = () => setAddAlternateModalShow(false);

    //Append added alternate mfgrs & mpns in requested mfgrs & mpns respectively.
    const handleAlternates = (partNumberList, lineItem) => {
        bomLineItem.filter(x => x.lineItem == lineItem).map((data) => {
            data.bomManufacturer = partNumberList.map((lineItem) => { return lineItem.manufacturer; }).join("|");
            data.manufacturerPN = partNumberList.map((lineItem) => { return lineItem.mpn; }).join("|");
            return data;
        });
        alternatePartsLineItem.filter(x => x.lineItem == lineItem).map((lineItem) => {
            lineItem.partNumberList = partNumberList;
            if (lineItem.partNumberList.some(x => !x.isAlternateAlreadyExist)) {
                lineItem.isAlternateAdded = true;
            }
            else {
                lineItem.isAlternateAdded = false;
            }
        });
        setAlternatePartsLineItem(alternatePartsLineItem);
        isIssuesFilter = isActive.issueOnly == true ? true : false;
        filterBOMLineItemByRequestedQuantity(bomLineItem, requestedQuantityId, isIssuesFilter);
    }

    const getMethodOfSupplySelectionsModel = (bomLineItem) => {
        let methodOfSupplySelectionsModel = [];
        bomLineItem.filter(x => x.isExistMethodOfSupplySelections == false).map((data) => {
            let methodOfSupplySelections = {};
            methodOfSupplySelections.AssemblyId = assemblyId;
            methodOfSupplySelections.LineItemId = data.lineItemId;
            methodOfSupplySelections.QuantityId = data.quantityId;
            methodOfSupplySelections.MethodOfSupply = data.methodOfSupply;
            methodOfSupplySelectionsModel.push(methodOfSupplySelections);
        });
        return methodOfSupplySelectionsModel;
    }

    const getBOMLineItemsModel = (bomLineItem) => {
        let uniqueBOMLineItem = [];
        let BOMLinItemsModel = [];
        let advancedAssemblyWillSupply = ReviewBOMConstant.MethodOfSupply.AdvancedAssemblyWillSupply.toUpperCase().trim();
        let acceptAlternative = ReviewBOMConstant.MethodOfSupply.AcceptAlternative.toUpperCase().trim();
        let customerWillSupply = ReviewBOMConstant.MethodOfSupply.CustomerWillSupply.toUpperCase().trim();
        let doNotPopulate = ReviewBOMConstant.MethodOfSupply.DoNotPopulate.toUpperCase().trim();
        let getSourcingHelp = ReviewBOMConstant.MethodOfSupply.GetSourcingHelp.toUpperCase().trim();

        uniqueBOMLineItem = Array.from([...new Set(bomLineItem.map(b => b.lineItemId))]).map(lineItemId => {
            return {
                LineItemId: lineItemId,
                IsInstall: bomLineItem.find(b => b.lineItemId == lineItemId).isInstall,
                IsPurchase: bomLineItem.find(b => b.lineItemId == lineItemId).isPurchase,
                methodOfSupply: bomLineItem.find(b => b.lineItemId == lineItemId).methodOfSupply
            }
        });

        uniqueBOMLineItem.map((data) => {
            let BOMLineItems = {};
            if (data.methodOfSupply.toUpperCase().trim() == advancedAssemblyWillSupply) {
                BOMLineItems.LineItemId = data.LineItemId;
                BOMLineItems.IsInstall = true;
                BOMLineItems.IsPurchase = true;
            }
            else if (data.methodOfSupply.toUpperCase().trim() == acceptAlternative) {
                BOMLineItems.LineItemId = data.LineItemId;
                BOMLineItems.IsInstall = true;
                BOMLineItems.IsPurchase = true;
            }
            else if (data.methodOfSupply.toUpperCase().trim() == customerWillSupply) {
                BOMLineItems.LineItemId = data.LineItemId;
                BOMLineItems.IsInstall = true;
                BOMLineItems.IsPurchase = false;
            }
            else if (data.methodOfSupply.toUpperCase().trim() == doNotPopulate) {
                BOMLineItems.LineItemId = data.LineItemId;
                BOMLineItems.IsInstall = false;
                BOMLineItems.IsPurchase = false;
            }
            else if (data.methodOfSupply.toUpperCase().trim() == getSourcingHelp) {
                BOMLineItems.LineItemId = data.LineItemId;
                BOMLineItems.IsInstall = data.IsInstall;
                BOMLineItems.IsPurchase = data.IsPurchase;
            }
            BOMLinItemsModel.push(BOMLineItems);
        })
        return BOMLinItemsModel;
    }

    const submitData = (bomLineItem) => {
        let updateMethodOfSupplyModel = {};
        let methodOfSupplySelectionsModel = [];

        invalidMethodOfSupply = validateMethodOfSupply();

        if (invalidMethodOfSupply.length != 0) {
            setMethodOfSupplyValidation({ message: `${MESSAGE_CONSTANT.reviewBOM.requiredMethodOfSupply}${invalidMethodOfSupply.join(',')}.`, isDisplay: true });
            return;
        }

        if (bomLineItem.length > 0) {
            methodOfSupplySelectionsModel = getMethodOfSupplySelectionsModel(bomLineItem);
        }

        else {
            setMethodOfSupplyValidation({ message: "", isDisplay: false });
        }
        let getUpdatedBOMLineItems = getBOMLineItemsModel(bomLineItem);

        let lineItemNoForGetSourcingHelp = Array.from([...new Set(bomLineItem.filter(x => x.methodOfSupply.toUpperCase().trim() === ReviewBOMConstant.MethodOfSupply.GetSourcingHelp.toUpperCase().trim()).map((data) => {
            return `${data.lineItem}: ${data.manufacturerPN.split("|").join(",")}`;
        }))])
        let sourcingHelpNote = MESSAGE_CONSTANT.ReviewBOM.sourcingHelpNote.toString().formatGeneric(lineItemNoForGetSourcingHelp.join(", "));

        updateMethodOfSupplyModel.AssemblyId = assemblyId;
        updateMethodOfSupplyModel.SourcingHelpNote = sourcingHelpNote;
        updateMethodOfSupplyModel.BOMLineItems = getUpdatedBOMLineItems;
        updateMethodOfSupplyModel.UserId = salesPersonId;
        updateMethodOfSupplyModel.IsCustomQuote = lineItemNoForGetSourcingHelp.length > 0;
        updateMethodOfSupplyModel.RFQId = rfqId;

        trackPromise(
            (async () => {
                if (methodOfSupplySelectionsModel.length > 0) {
                    await PricingService.upsertMethodOfSupplySelections(methodOfSupplySelectionsModel);
                }
                await PricingService.updateMethodOfSupply(updateMethodOfSupplyModel).then((data) => {
                    let isCustomQuoteTrigger = lineItemNoForGetSourcingHelp.length > 0;
                    submitDataHandler(true, isCustomQuoteTrigger);
                })
            })()
        );
    }

    const rerunPrice = (bomLineItem) => {

        let addedAlternateLineItemsModel = [];
        let methodOfSupplySelectionsModel = [];
        let addAlternatePartsRequestModel = {};

        let addAlternate = ReviewBOMConstant.MethodOfSupply.AddAlternate.toUpperCase().trim();
        invalidMethodOfSupply = validateMethodOfSupply();

        if (invalidMethodOfSupply.length != 0) {
            setMethodOfSupplyValidation({ message: `${MESSAGE_CONSTANT.reviewBOM.requiredMethodOfSupply}${invalidMethodOfSupply.join(',')}.`, isDisplay: true });
            return;
        }

        let addAlternateLineItemList = pluck(bomLineItem.filter(x => x.methodOfSupply.toUpperCase().trim() == addAlternate), 'lineItem');
        let lineItemsForAddAlternates = pluck(alternatePartsLineItem.filter(x => addAlternateLineItemList.includes(x.lineItem) && !x.isAlternateAdded), 'lineItem');
        if (lineItemsForAddAlternates.length > 0) {
            Swal.fire({
                text: MESSAGE_CONSTANT.alternates.lineItemsForAddAlternates.toString().formatGeneric(lineItemsForAddAlternates.join(',')),
                icon: 'warning'
            });
            return;
        }

        alternatePartsLineItem.filter(x => x.isAlternateAdded).map((data) => {
            let alternatePartMfgrList = data.partNumberList.filter(x => !x.isAlternateAlreadyExist).map((data) => { return data.manufacturer });
            let alternatePartMpnList = data.partNumberList.filter(x => !x.isAlternateAlreadyExist).map((data) => { return data.mpn });
            for (let i = 0, j = 0; i < alternatePartMfgrList.length, j < alternatePartMpnList.length; i++, j++) {
                let alternatePart = {
                    LineItemId: data.lineItemId,
                    Manufacturer: alternatePartMfgrList[i],
                    ManufacturerPN: alternatePartMpnList[j],
                    IsApprove: true
                }
                addedAlternateLineItemsModel.push(alternatePart);
            }
        });

        addAlternatePartsRequestModel.AssemblyId = assemblyId;
        addAlternatePartsRequestModel.UserId = salesPersonId;
        addAlternatePartsRequestModel.AlternateParts = addedAlternateLineItemsModel;

        if (bomLineItem.length > 0) {
            methodOfSupplySelectionsModel = getMethodOfSupplySelectionsModel(bomLineItem);
        }

        let refreshPricingRequestModel = {
            AssemblyId: assemblyId,
            UserId: salesPersonId,
            PricingRequest: {
                ConsolidatedLineItemIds: alternatePartsLineItem.filter(x => x.isAlternateAdded).map((data) => { return data.lineItemId; })
            }
        };

        trackPromise(
            (async () => {
                if (methodOfSupplySelectionsModel.length > 0) {
                    await PricingService.upsertMethodOfSupplySelections(methodOfSupplySelectionsModel);
                }
                if (addAlternatePartsRequestModel != null && addedAlternateLineItemsModel.length > 0) {
                    await PricingService.addAlternateParts(addAlternatePartsRequestModel);
                    await PricingService.refreshPricing(refreshPricingRequestModel).then(() => {
                        handleRerunPrice(true);
                    })
                }
            })()
        );
    }

    const downloadCostedBOMReport = () => {
        var objReportRequest = {
            AssemblyId: assemblyId
        }
        trackPromise(ReportService.getCostedBOMReport(objReportRequest))
    }

    return (
        isLoaded && <>
            <BOMSummary bomSummaryData={bomSummaryData} />

            <Row>
                <div className='col-lg-6 col-md-6 col-sm-6 mt-2 btn-downloadBOM'>
                    {selectedJobType?.isBomRequired &&
                        <PrimaryGreyButton
                            name="Download BOM"
                            handleClick={() => downloadCostedBOMReport()}
                            isActive
                        />
                    }
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 mt-2 filterreviewbombutton'>
                    <PrimaryGreyButton
                        name="All parts"
                        handleClick={() => filterData('All Parts')}
                        isActive={isActive.allparts}
                    />
                    &nbsp;&nbsp;

                    <PrimaryGreyButton
                        name="Issues Only"
                        handleClick={() => filterData('Issue')}
                        isActive={isActive.issueOnly}
                    />
                </div>
                &nbsp;&nbsp;
            </Row>

            <RequestedQuantity
                requestedQuantityData={requestedQuantityData}
                handleRequestedQuantitychanged={handleRequestedQuantitychanged} />

            <BOMLineItem bomLineItem={isFilter == true ? filterBOMLineItem : bomLineItem}
                requestedFilterQuantity={requestedQuantityId}
                methodOfSupplyList={methodOfSupplyList}
                handleChanged={() => handleChanged}
                onAddAlternateClick={handleLineItemClick}
            />

            <Row className="col-lg-12 col-md-12 col-sm-12 mt-1">
                {methodOfSupplyValidation.isDisplay && <p className="error-txt"> {methodOfSupplyValidation.message} </p>}
            </Row>

            <Row className="mt-3 mb-5 filterreviewbombutton">
                {bomCleanMessage.isDisplay && (<span className="col-sm-12 col-md-3 mb-3 mb-md-0 pr-0 pr-lg-3 cm-bom-clean">{bomCleanMessage.description}</span>)}
                {bomLineItem.some(x => x.methodOfSupply === ReviewBOMConstant.MethodOfSupply.AddAlternate) ?
                    <PrimaryOrangeButton
                        name={DATA_CONSTANT.ReviewBOM.RerunPricing}
                        handleClick={() => rerunPrice(bomLineItem)}
                    /> :
                    <PrimaryOrangeButton
                        name={selectedJobType?.isBomAnalysis ? "Done" : "Get Pricing"}
                        handleClick={() => submitData(bomLineItem)} />}
            </Row>

            <Row>
                {addAlternateModalShow &&
                    <AddAlternates
                        show={addAlternateModalShow}
                        lineItemsforAddAlternate={lineItemforAddAlternate}
                        handleClose={handleClose}
                        handleAlternates={handleAlternates}
                    ></AddAlternates>}
            </Row>
        </>
    )
}
export default memo(ReviewBOM);