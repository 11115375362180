import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import InfoToolTip from "../InfoToolTip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./InputDatePicker.css"

const InputDatePicker =
    ({ label,
        isRequired,
        helpText,
        className,
        helpPlacement,
        placeholderText,
        name,
        value,
        dateFormat,
        disableStartDate,//Pass date for to specify starting range to disable date{new Date(2021, 3, 1)}        
        disableEndDate,//Pass date for to specify end range to disable date {new Date(2021, 3, 20)}
        disablePastDate,//Pass date from which all past dates will be disabled {new Date(2021, 3, 10)}
        disableFutureDate,//Pass date from which all next dates will be disabled {new Date(2021, 3, 30)}
        disabled,
        excludeDates,//Pass any specific date to disable from datepicker calendar {new Date(2021, 3, 10)} 
        errorMessage,
        dayClassName,
        onChange,
        selected,
        ...props }) => {

        const [startDate, setStartDate] = useState(selected);
        const [isDateInvalid, setDateInvalid] = useState(false);

        const handleChange = (date) => {
            setStartDate(date);
            if (onChange) {
                onChange(date, name)
            }
        }
        useEffect(() => {
            if (isRequired && startDate) {
                setDateInvalid(false);
            } else {
                setDateInvalid(true);
            }
        }, [startDate])

        const [daterange, setDateRange] = useState([]);

        useEffect(() => {
            if (disableStartDate && disableEndDate) {
                const getDateRange = function (startDate, endDate) {
                    var dates = [],
                        currentDate = startDate,
                        addDays = function (days) {
                            var date = new Date(this.valueOf());
                            date.setDate(date.getDate() + days);
                            return date;
                        };
                    while (currentDate <= endDate) {
                        dates.push(currentDate);
                        currentDate = addDays.call(currentDate, 1);
                    }
                    return dates;
                };
                setDateRange(getDateRange(disableStartDate, disableEndDate));
            }
        }, []);

        return (
            <>
                <Row>
                    <Col lg={12} className="mb-2">
                        {label && label.trim() && (
                            <Row className="pr-0">
                                <Col>
                                    <label>{label}</label>
                                    {isRequired && <span className="error-txt">*</span>}

                                    {helpText && helpText.trim() && (
                                        <InfoToolTip
                                            className={className}
                                            helpText={helpText}
                                            helpPlacement={helpPlacement}
                                        />
                                    )}
                                </Col>
                            </Row>
                        )}
                        <Row className="pr-0">
                            <Col>
                                <DatePicker
                                    className="form-control"
                                    selected={startDate}
                                    value={startDate}
                                    placeholderText={placeholderText}
                                    onChange={(data) => handleChange(data,name)}
                                    name={name}
                                    dateFormat={dateFormat}
                                    minDate={disablePastDate}
                                    disabled={disabled}
                                    maxDate={disableFutureDate}
                                    dayClassName={dayClassName ? dayClassName : null}
                                    excludeDates={excludeDates ? excludeDates : daterange}
                                    customInput={<CustomInput />}
                                    {...props} />
                            </Col>
                        </Row>
                        {isDateInvalid && (<Row>
                            <Col>
                                <span className="error-txt">{errorMessage}</span>
                            </Col>
                        </Row> )}
                    </Col>
                </Row>
            </>
        );
    }
export default InputDatePicker;


const CustomInput = React.forwardRef(
    ({ value, placeholderText, onClick, }, ref) => (
        <div className="wrapper">
            <input
                className="form-control calendar-input"
                placeholder={placeholderText}
                value={value}
                type="text"
                ref={ref}
                onClick={onClick}
                readOnly
            />
            <button
                onClick={onClick}
                className="btn-calendar">
                <i className="far fa-calendar-alt" aria-hidden="true"></i>
            </button>
        </div>
    ),
);