import React from "react";
import PropTypes from "prop-types";
import { FormLabel, FormControl } from "react-bootstrap";
import InfoToolTip from "../InfoToolTip";
const InputSelect = React.forwardRef(
  (
    {
      id,
      name,
      value,
      className,
      onChange,
      isRequired, // Displays * for required field
      list, // List to bind drop down list
      listLabel, // Label property from list
      listValue, // Value property from list
      label,
      errorMessage,
      initialValue,
      helpText, // Displays information tooltip
      helpPlacement, // Placement for tooltip
      ...props
    },
    ref
  ) => {
    if (
      list.length === 0 ||
      (typeof list[0] == "object" &&
        !listValue &&
        !list[0].hasOwnProperty(listValue))
    ) {
      return <></>;
    }
    return (
      <>
        {label && (
          <>
            <FormLabel> {label} </FormLabel>
            {isRequired && <span className="error-txt">*</span>}
            {helpText && helpText.trim() && (
              <InfoToolTip
                className={className}
                helpText={helpText}
                helpPlacement={helpPlacement}
              />
            )}
          </>
        )}
        <FormControl
          as="select"
          id={id}
          name={name}
          value={value}
          className={className}
          onChange={onChange}
          ref={ref}
          {...props}
        >
          {initialValue ? <option value="">{initialValue}</option> : ""}
          {list &&
            list.length > 0 &&
            list.map((item) => {
              {
                listLabel =
                  listLabel && item.hasOwnProperty(listLabel)
                    ? listLabel
                    : listValue;
                return typeof item == "string" ? (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ) : (
                  <option key={item[listValue]} value={item[listValue]}>
                    {item[listLabel]}
                  </option>
                );
              }
            })}
        </FormControl>
        {errorMessage && errorMessage[name] && errorMessage[name].message && (
          <span className="error-txt">{errorMessage[name].message}</span>
        )}
      </>
    );
  }
);

InputSelect.defaultProps = {
  className: "",
};

InputSelect.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  list: PropTypes.array.isRequired,
  listLabel: PropTypes.string,
  listValue: PropTypes.string,
  helpText: PropTypes.string,
  placement: PropTypes.oneOf(["right", "left", "top", "bottom"]),
  className: PropTypes.string,
};

export default InputSelect;
