import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { PrimaryOrangeButton } from "../../components/Common/InputButton/InputButton";
import { trackPromise } from "react-promise-tracker";
import { CustomQuoteService } from "../../services/CustomQuoteService";
import { ConfigurationService } from "../../services/ConfigurationService";
import InputTextArea from "../../components/ControlComponents/Input/InputTextArea";
import InputTextBox from "../../components/ControlComponents/Input/InputTextBox";
import DocumentUploader from "../../components/Common/DocumentUploader";
import { RemoveButton } from "../../components/Common/InputButton/InputButton";
import { DATA_CONSTANT } from "../../constants/DataConstant";
import { MESSAGE_CONSTANT } from "../../constants/MessageConstant";
import { BiPhone } from "react-icons/bi";
import { clearIsCustomQuoteRequires } from "../../redux/actions/SetCustomQuoteRequire";
import { setCompletedSteps } from "../../redux/actions/CompletedStepActions";
import { clearStore } from '../../redux/actions/ClearStoreActions';
import { clearJobType } from '../../redux/actions/ClearJobType';
import "./CustomQuote.css";

const CustomQuote = ({ rfqDetails, defaultConfigs, onQuoteComplete, selectedJobType, onStepCompleted }) => {

    const allowedFileTypes = DATA_CONSTANT.allowedFileTypes;

    const history = useHistory();

    const [uploadedDocument, setUploadedDocument] = useState([]);

    const [customerInfo, setCustomerInfo] = useState({ note: '', email: '', firstName: '', lastName: '' });

    const [isLoad, setIsLoad] = useState(false);

    const [acceptedFiles, setAcceptedFiles] = useState([]);

    const [rejectedFiles, setRejectedFiles] = useState([]);

    const [isTooManyFilesError, setTooManyFileError] = useState(false);

    const [isDocumentUploaderShow, setIsDocumentUploaderShow] = useState(false);

    const [maxNumberOfFileToUpload, setMaxNumberOfFileToUpload] =
        useState(0);

    const { errors } = useForm();

    const childRef = useRef();

    useEffect(() => {
        if (!rfqDetails?.assemblyId) {
            history.push("/");
            return;
        }
        trackPromise(
            getAllUploadedDocument(rfqDetails?.assemblyId, defaultConfigs?.salesPersonId)
        );
    }, [])

    const getAllUploadedDocument = async (assemblyId, salesPersonId) => {
        let maxFile = await getmaxNumberOfFileToUpload();
        await CustomQuoteService.GetAllUploadedDocument(assemblyId, salesPersonId)
            .then((data) => {
                if (data) {
                    const allUploadedDocument = data[0].children;
                    setUploadedDocument(allUploadedDocument);
                    let uploadedFileCount = allUploadedDocument.length;
                    if (uploadedFileCount !== (+maxFile[0].value)) {
                        setMaxNumberOfFileToUpload(
                            (+maxFile[0].value) - uploadedFileCount
                        );
                        setIsDocumentUploaderShow(true);
                    }
                }
            })

        setCustomerInfo({ ...customerInfo, firstName: rfqDetails.customerInfo.firstName, lastName: rfqDetails.customerInfo.lastName, email: rfqDetails.customerInfo.email });
        setIsLoad(true);
    };

    const handleChanged = (event) => {
        let name = event.target.name;
        setCustomerInfo({
            ...customerInfo,
            [name]: event.target.value
        })
    }

    const selectAcceptedFiles = (acceptedFiles) => {
        setAcceptedFiles([...acceptedFiles]);
    };

    const selectRejectedFiles = (rejectedFiles) => {
        setRejectedFiles([...rejectedFiles]);
    };

    const tooManyFilesError = (isTooManyFilesError) => {
        setTooManyFileError(isTooManyFilesError);
    };

    const submitCustomQuote = async () => {
        const formData = new FormData();
        for (let i = 0; i < acceptedFiles.length; i++) {
            formData.append("rfqFiles", acceptedFiles[i], acceptedFiles[i].name.toString().toLowerCase());
        }
        let requestObject = {
            assemblyId: rfqDetails?.assemblyId,
            userId: defaultConfigs?.salesPersonId,
            assemblyName: rfqDetails?.assemblyName,
            PortCQCustomer: {
                CustomerFirstName: customerInfo.firstName,
                CustomerLastName: customerInfo.lastName,
                Email: customerInfo.email,
                Note: customerInfo.note
            },
        }
        formData.append("customQuoteRequestForm", JSON.stringify(requestObject));
        trackPromise(
            CustomQuoteService.SubmitCustomQuote(formData).then((data) => {
                onStepCompleted();
                onQuoteComplete(selectedJobType);
                window.location.href = DATA_CONSTANT.thankYouQuoteURL;
            })
        )
    }

    const getmaxNumberOfFileToUpload = async () => {
        let maxNumberOfFileTo =
            DATA_CONSTANT.ConfigurationKey.MaxNoOfUploadDocuments;
        let configKeys = [maxNumberOfFileTo];
        return ConfigurationService.getByKeys(configKeys);
    };

    return (
        isLoad && (
            <>
                <Row>
                    <Col md={10} sm={12} xs={12}>
                        <Row className="mb-4">
                            <Col>
                                <h2>Custom Quote</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <h5>At this time, we cannot provide online pricing for this quote. This is either because you have requested help with sourcing components or your project requirements fall outside what can be quoted online.
                                    The good news is that our quoting will get started immediately on a custom quote using the following files you provided:</h5>
                            </Col>
                        </Row>
                        <Row className="mb-4 mt-4">
                            <ul md={6} sm={10} xs={10} className="mb-1">
                                {
                                    isLoad && uploadedDocument.map((data, index) => {
                                        return (
                                            <React.Fragment key={index} >
                                                <li> {data.fileType ? (<><span className='cm-documentfiletype'>{data.fileType}</span> - <span>{data.name}</span ></>) : (<span>{data.name}</span >)}</li>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </ul>
                        </Row>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <p className="mb-0 mt-2">If you have additional files to help us provide the most accurate quote possible, please upload them here:</p>
                            </Col>
                        </Row>
                        <hr />
                        {isDocumentUploaderShow &&
                            <>
                                <Row className="mb-4">
                                    <Col xl={12} lg={12} md={12} sm={12}>
                                        <h3>Upload Additional Files</h3>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center">
                                    <Col md={9} sm={12} xs={12}>
                                        <DocumentUploader
                                            ref={childRef}
                                            displayFiles={false}
                                            alowedFileTypes={allowedFileTypes}
                                            handleAcceptedFiles={selectAcceptedFiles}
                                            handleRejectedFiles={selectRejectedFiles}
                                            handleTooManyFilesError={tooManyFilesError}
                                            maxNumberOfFileToUpload={maxNumberOfFileToUpload}
                                        />
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center mb-5">
                                    <Col md={9} sm={12} xs={12}>
                                        <Container fluid>
                                            {acceptedFiles.length > 0 && (
                                                <Row className="pt-3">
                                                    <Col md={1} sm={12} xs={12}></Col>
                                                    <Col md={6} sm={12} xs={12}>
                                                        <h5>Uploaded files</h5>
                                                    </Col>
                                                </Row>
                                            )}
                                            {acceptedFiles &&
                                                acceptedFiles.map((file, index) => (
                                                    <Row key={index} className="pt-3">
                                                        <Col md={1} sm={12} xs={12}>
                                                            <RemoveButton
                                                                handleClick={() => {
                                                                    childRef.current.handleRemoveFile(file.name);
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col md={6} sm={12} xs={12} className="pt-1 file-name-text">
                                                            {file.name}
                                                        </Col>
                                                    </Row>
                                                ))}
                                            {rejectedFiles.length > 0 && !isTooManyFilesError && (
                                                <Row className="pt-5">
                                                    <Col md={10}>
                                                        <h4>Rejected files</h4>
                                                    </Col>
                                                    <Col md={2}>
                                                        <RemoveButton
                                                            handleClick={() => childRef.current.handleRemoveAllFile()}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                            {rejectedFiles &&
                                                !isTooManyFilesError &&
                                                rejectedFiles.map(({ file, errors }, index) => (
                                                    <Row key={index}>
                                                        <Col
                                                            md={12}
                                                            lg={12}
                                                            sm={12}
                                                            xs={12}
                                                            className="file-name-text"
                                                        >
                                                            <li key={file.path}>
                                                                {file.path} - {file.size} bytes
                                                <ul>
                                                                    {errors.map((e) => (
                                                                        <li key={e.code}>{e.message}</li>
                                                                    ))}
                                                                </ul>
                                                            </li>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            {isTooManyFilesError && (
                                                <h5 className="error-txt mt-3">{`${MESSAGE_CONSTANT.tooManyFiles} ${maxNumberOfFileToUpload}`}</h5>
                                            )}
                                        </Container>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={12} sm={12} xs={12}>
                                        <p>Custom quotes are typically provided within 24 hours.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} sm={12} xs={12}>
                                        <p>We look forward to working with you soon.</p>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row className="mt-3" >
                            <Col md={6} sm={12} xs={12} className="cm-input-container">
                                <InputTextArea
                                    label="Note"
                                    name="note"
                                    rows={5}
                                    maxLength="2000"
                                    value={customerInfo.note}
                                    errorMessage={errors}
                                    onChange={(event) =>
                                        handleChanged(event)
                                    }
                                ></InputTextArea>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} sm={12} xs={12} className="cm-input-container">
                                <InputTextBox
                                    label="Email"
                                    name="email"
                                    value={customerInfo.email}
                                    errorMessage={errors}
                                    onChange={(event) =>
                                        handleChanged(event)
                                    }
                                    disabled={true}
                                ></InputTextBox>
                            </Col>
                        </Row>
                        <Row className="text-center my-5">
                            <Col md={11} sm={12} xs={12} className="text-center">
                                <PrimaryOrangeButton
                                    name="Send"
                                    handleClick={submitCustomQuote}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col md={11} sm={12} xs={12} >
                                <p>Questions ? Contact us below.</p>
                                <BiPhone fontSize={38} />&nbsp;&nbsp;<span>+1-602-279-1455</span>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            </>)
    );
};
const mapStateToProps = state => ({
    rfqDetails: state.RFQDetails?.rfqDetails,
    defaultConfigs: state.DefaultConfigs?.defaultConfigs,
    completedSteps: state.CompletedSteps ? state.CompletedSteps : [],
    selectedJobType: state.JobTypes?.selectedJobType
});

const mapDispatchToProps = dispatch => ({
    onStepCompleted: () => {
        dispatch(setCompletedSteps(DATA_CONSTANT.stepNames.Completed));
    },
    onQuoteComplete: (selectedJobType) => {
        dispatch(clearStore());
        dispatch(clearJobType({ isAssemblyRequired: selectedJobType?.isAssemblyRequired, isBomRequired: selectedJobType?.isBomRequired, isPcbRequired: selectedJobType?.isPcbRequired, isComponentsRequired: selectedJobType?.isComponentsRequired }));
        dispatch(clearIsCustomQuoteRequires({ IsCustomQuoteRequires: false }));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomQuote);
